import { useAppContext } from "../../context/AppContext";

const TokenFailedMoal = () => {
  const { setModal } = useAppContext();
  return (
    <div style={style.container}>
      <div>
        <img
          alt="payment success"
          style={style.success_img}
          src="/img/payment/x-circle.png"
        />
      </div>
      <p style={style.title}>Transaction unsuccessful</p>
      <p style={style.info}>
        Uh oh! There was an error processing your transaction. Please try again
        later or contact us for assistance at{" "}
        <a style={style.support} href="mailto:support@bksbackstage.io">
          support@bksbackstage.io
        </a>
      </p>
      <button onClick={() => setModal({ open: false })} style={style.mint_btn}>
        CLOSE
      </button>
    </div>
  );
};

const style = {
  container: {
    width: "423px",
    maxWidth: "95%",
    textAlign: "center",
    background:
      "radial-gradient(70% 50% at 50% -10%, rgba(255, 0, 0, 0.25) 0%, rgba(255, 0, 0, 0) 100%)",
    backgroundColor: "rgb(10 26 12)",
    color: "white",
    padding: "40px 24px 23px 24px",
    borderRadius: "10px",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  success_img: {
    width: "68px",
    height: "68px",
  },
  title: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    margin: "22px auto auto auto",
  },
  support: {
    color: "rgba(255, 255, 255, 0.66)",
    textDecoration: "underline",
  },
  info: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.03em",
    color: "rgba(255, 255, 255, 0.66)",
    maxWidth: 321,
    margin: "10px auto 0 auto",
  },
  mint_btn: {
    border: "1px solid rgba(255, 255, 255, 0.33)",
    borderRadius: "4px",
    width: "100%",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.15em",
    padding: "14px 0",
    color: "#FFFFFF",
    marginTop: "30px",
  },
};

export default TokenFailedMoal;
