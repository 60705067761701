import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { deleteArticleById, getAllArtiles } from "../../../api/article";
import { IArticle } from "../../../utils/interface";

import Pagination from "../../../components/pagination";
import BlogItem from "../../../components/blog_item";
import styles from "./index.module.css";

const PageBlog = () => {
  const [articles, setArticles] = useState<IArticle[]>([] as IArticle[]);
  // const [currentTag, setCurrentTag] = useState("all");
  const {
    setLoading,
    scrollItem,
    pageNumber,
    setPageNumber,
    addToast,
  } = useAppContext();
  const [pageIndex, setPageIndex] = useState(0);
  const [itemIndex, setItemIndex] = useState(pageNumber);
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 450px)");

  useEffect(() => {
    (scrollItem.current as HTMLDivElement).scrollIntoView({
      behavior: "smooth",
    });
  }, [itemIndex, pageIndex, scrollItem]);

  const leftClick = () => {
    if (itemIndex > 0) {
      setItemIndex(itemIndex - 1);
    }
  };

  const rightClick = () => {
    if ((itemIndex + 1) * 9 < articles.length) {
      setItemIndex(itemIndex + 1);
      setPageIndex(0);
    }
  };

  useEffect(() => {
    setPageNumber(itemIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIndex]);

  useEffect(() => {
    setLoading(true);
    getAllArtiles()
      .then((res) => {
        setLoading(false);
        setArticles(res.articles);
      })
      .catch((error) => {
        setLoading(false);
        addToast("Network error occured", {
          appearance: "error",
          autoDismiss: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteArticle = (articleId: string) => {
    deleteArticleById(articleId)
      .then((res) => {
        const updatedArticles = [...articles].filter(
          (article: IArticle) => article.id !== articleId
        );
        setArticles(updatedArticles);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CArticles = () => {
    return articles.map(
      (article: IArticle, i) =>
        i >= (pageIndex + itemIndex) * 9 &&
        i < (pageIndex + itemIndex + 1) * 9 && (
          <BlogItem
            key={article.id}
            article={article}
            handleDeleteArticle={handleDeleteArticle}
          />
        )
    );
  };

  return (
    <div className={styles.blog__container}>
      <div className="explorer__top">
        <p className="explorer__top-title">{t("news")}</p>
      </div>

      <div className="tab-content" style={{ padding: isMobile ? 25 : 0 }}>
        <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
          <div className="row row--grid">{CArticles()}</div>
        </div>
      </div>
      <Pagination
        items={articles}
        setItemIndex={setItemIndex}
        pageIndex={pageIndex}
        itemIndex={itemIndex}
        leftClick={leftClick}
        rightClick={rightClick}
        count={9}
      />
      <div className="bottom_block"></div>
    </div>
  );
};

export default PageBlog;
