import { withStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';

const StyledDlg = withStyles({
    root: {
      '& .MuiPaper-root': {
        backgroundColor: 'transparent',
        maxWidth: 'none !important'
      },
    },
  })(Dialog);

const CustomModalComponent = ({children, open, onClose}) => {
    return (
        <StyledDlg 
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // TransitionProps={{ onEntering: handleEntering }}
        >
            {children}
        </StyledDlg>
    )
}

export default CustomModalComponent;