import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RichTextEditor from "react-rte";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { createMessage } from "../../../api/message";
import { validateEmail } from "../../../utils";

const PageMessageCreate = () => {
  const { userInfo } = useUserContext();
  const { setLoading, addToast } = useAppContext();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    receiver: "",
    link: "",
    content: RichTextEditor.createEmptyValue(),
  });

  const [validations, setValidations] = useState({
    receiver: "",
    link: "",
    content: "",
  });

  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [background, setBackground] = useState("");
  useEffect(() => {
    if (
      userInfo?.user?.user_type !== "ADMIN" &&
      userInfo?.user?.user_type !== "TURKISH ADMIN"
    ) {
      navigate("/");
    }
    if (userInfo && userInfo.user.background) {
      const backgroundUrl = `${process.env.REACT_APP_ASSET_BASE_URL}/${userInfo.user.background}`;
      setBackground(backgroundUrl);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const checkvalidations = () => {
    if (values.receiver === "") {
      setValidations({ receiver: "has-empty", link: "", content: "" });
      return false;
    } else if (!validateEmail(values.receiver)) {
      setValidations({ receiver: "has-danger", link: "", content: "" });
      return false;
    } else if (values.link === "") {
      setValidations({ receiver: "", link: "has-empty", content: "" });
      return false;
    } else {
      setValidations({ receiver: "", link: "", content: "" });
    }

    return true;
  };

  const handleChange = (prop: any, value: any) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const handleCreate = () => {
    if (!checkvalidations()) return;

    setLoading(true);

    createMessage({ ...values, content: values.content.toString("html") })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          addToast("Created Email", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate("/");
        } else {
          addToast("failed", { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("An error occured", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <div className="message__container">
      <h2 className="cr__email--title">{t("create email")}</h2>
      <div className="cr__email--formContainer">
        <div className="create__item_container">
          <div className="create__item">
            <p className="cr__email--subtitle">{t("receiver")}</p>
            <div className="sign__group cr__email-50">
              <input
                id="receiver"
                type="text"
                name="receiver"
                className="sign__input"
                placeholder="e. g. 'john@gmail.com'"
                value={values.receiver}
                onChange={(e) => handleChange("receiver", e.target.value)}
              />
              {validations.receiver === "has-empty" && (
                <span className="text-error">{t("receiver required")}*</span>
              )}
              {validations.receiver === "has-danger" && (
                <span className="text-error">
                  {t("receiver should be email")}
                </span>
              )}
            </div>
          </div>

          <div className="create__item">
            <p className="cr__email--subtitle">{t("item details")}</p>
            <div className="sign__group cr__email-50">
              <input
                id="itemname"
                type="text"
                name="itemname"
                className="sign__input"
                placeholder="e. g. 'Crypto Heart'"
                value={values.link}
                onChange={(e) => handleChange("link", e.target.value)}
              />

              {validations.link === "has-empty" ? (
                <span className="text-error">{t("link required")}*</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="sign__group">
          <p className="cr__email--subtitle">{t("content")}</p>
          <div className="text-editor-wrapper">
            <RichTextEditor
              value={values.content}
              onChange={(e) => handleChange("content", e)}
              rootStyle={{
                background: "transparent",
                border: "1px solid rgba(255, 255, 255, 0.33)",
                borderRadius: 4,
                overflow: "hidden",
              }}
              toolbarStyle={{
                background: "white",
                padding: 8,
                paddingBottom: 0,
                margin: 0,
              }}
              editorStyle={{ color: "#fff", overflow: "hidden", height: 180 }}
            />
          </div>

          {validations.content === "has-empty" && (
            <span className="text-error">{t("content required")}*</span>
          )}
        </div>
      </div>
      <button
        type="button"
        className="sign__btn create__email_btn"
        onClick={handleCreate}
      >
        {t("create email")}
      </button>
    </div>
  );
};

export default PageMessageCreate;
