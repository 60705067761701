import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";

import { useAppContext } from "../../../context/AppContext";
import Loading from "../../../components/Loading";
import { findQRticket } from "../../../api/ticket";
import { myNFTABI } from "../../../utils/nft_contract";

const CheckTicket = () => {
  const { event_name } = useParams();
  const { serial_text } = useParams();
  const { wallet } = useParams();
  const { nft_address } = useParams();
  const { token_id } = useParams();
  const { key } = useParams();
  const { addToast } = useAppContext();
  const [isTicket, setIsTicket] = useState(false);
  const [pass, setPass] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [tokenOwner, setTokenOwner] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [ticket, setTicket] = useState<any>(null);
  const data = {
    event_name,
    serial_text,
    key,
  };

  const checkValidation = async (data: any) => {
    if (wallet?.toLocaleLowerCase() === tokenOwner.toLocaleLowerCase()) {
      await findQRticket(data)
        .then((res) => {
          if (res !== "failed") {
            console.log(res);
            setTicket(res);
            setIsTicket(true);
          } else {
            setIsTicket(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setIsTicket(false);
      setLoading(false);
    }
  };

  const checkPassword = () => {
    if (pass === "check_ticket_admin!@#" || pass === "vivere") {
      setIsAdmin(true);
      localStorage.setItem("IsAdmin", Date.now().toString());
      setLoading(true);
      checkValidation(data).catch(console.error);
    } else {
      setIsAdmin(false);
      addToast("Wrong password", { appearance: "error", autoDismiss: true });
    }
  };

  const getOwnerAddress = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://bsc-dataseed.binance.org"
    );
    const contract = new ethers.Contract(
      nft_address as string,
      myNFTABI,
      provider
    );
    const owner = await contract.ownerOf(token_id);
    setTokenOwner(owner);
    if (localStorage.getItem("IsAdmin")) {
      const duration = moment.duration(
        moment(Date.now()).diff(
          new Date(Number(localStorage.getItem("IsAdmin")!!))
        )
      );
      const hours = duration.asHours();
      if (hours <= 12) {
        setLoading(true);
        await findQRticket(data)
          .then((res) => {
            if (
              res !== "failed" &&
              owner.toLocaleLowerCase() === wallet?.toLocaleLowerCase()
            ) {
              setTicket(res);
              setIsTicket(true);
            } else {
              setIsTicket(false);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
        setIsAdmin(true);
      }
    }
  };

  useEffect(() => {
    getOwnerAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="full__container">
      <div
        className="sign__content"
        style={{ padding: isMobile ? 0 : "25px 0 0" }}
      >
        <form className="sign__form">
          <p
            className="sign__main-title"
            style={{ marginTop: isMobile ? "10px" : "50px" }}
          >
            {t("password")}
          </p>

          <div className="sign__group">
            <input
              type="password"
              className="sign__input"
              placeholder={t("password")}
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </div>
          <button className="sign__btn" type="button" onClick={checkPassword}>
            {t("check ticket")}
          </button>
        </form>
      </div>

      {isAdmin && !loading && (
        <div
          className="check__ticket_container"
          style={{
            background: isTicket ? "green" : "black",
          }}
        >
          <div className="check__ticket_block">
            {isTicket ? (
              <>
                <p className="check__ticket_font">
                  {event_name?.replaceAll("_", " ")}
                </p>
                <p className="check__ticket_font">{serial_text}</p>
                <p className="check__ticket_font">
                  {ticket.seat
                    ? JSON.parse(ticket.seat).seat_block +
                      ", Row: " +
                      JSON.parse(ticket.seat).x +
                      ", Column: " +
                      JSON.parse(ticket.seat).y
                    : null}
                </p>
              </>
            ) : (
              <p className="check__ticket_font">This ticket is not valid.</p>
            )}
          </div>
          {isTicket && (
            <div className="approve__img">
              <img style={{ width: 250 }} src="/img/approve.png" alt="data" />
            </div>
          )}
          <Link to="/" className="check__ticket_font">
            <p className="back__home_text">Back Home</p>
          </Link>
        </div>
      )}

      <Loading open={loading} />
    </div>
  );
};

export default CheckTicket;
