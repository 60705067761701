import { useState } from "react";
import { useAppContext } from "../../../../context/AppContext";
import { isMobile } from "react-device-detect";
import ORCH1 from "../orchestra/1";
import ORCH2 from "../orchestra/2";
import ORCH3 from "../orchestra/3";
import ORCH4 from "../orchestra/4";
import ORCH5 from "../orchestra/5";
import ORCH6 from "../orchestra/6";
import ORCH7 from "../orchestra/7";
import ORCH8 from "../orchestra/8";
// import TER1 from "../terrace/1";
// import TER2 from "../terrace/2";
// import TER3 from "../terrace/3";
// import TER4 from "../terrace/4";
// import TER5 from "../terrace/5";
// import TER6 from "../terrace/6";
// import TER7 from "../terrace/7";
// import TER8 from "../terrace/8";
// import TER9 from "../terrace/9";
// import TER10 from "../terrace/10";
// import TER11 from "../terrace/11";
// import TER12 from "../terrace/12";
import styles from "./index.module.css";

const LisnerSeatMap = ({ eventCard }) => {
  const { setModal } = useAppContext();
  const [maxWidth, setMaxWidth] = useState(isMobile ? 400 : 700);
  const zoomOut = () => {
    if (maxWidth < 1000) {
      setMaxWidth(maxWidth + 30);
    }
  };
  const zoomIn = () => {
    if (maxWidth > 400) {
      setMaxWidth(maxWidth - 30);
    }
  };

  return (
    <div className="container">
      <div className={styles.zoom_out}>
        <div onClick={() => zoomOut()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_out.svg"
            alt="zoom_out"
          />
        </div>
      </div>
      <div className={styles.zoom_in}>
        <div onClick={() => zoomIn()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_in.svg"
            alt="zoom_in"
          />
        </div>
      </div>
      <p className="seating__area_txt" style={{ width: maxWidth }}>
        1. Please select the seating area first
      </p>
      <div className="ahoy__map_container" style={{ width: maxWidth }}>
        <div className={styles.stage}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src="/img/map/Lisner/stage.svg"
              alt="stage"
            />
          </div>
        </div>
        <div className={styles.orchestra_line}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src="/img/map/Lisner/orchestra_line.svg"
              alt="orchestra_line"
            />
          </div>
        </div>
        <div className={styles.orch_1}>
          <div
            onClick={() =>
              eventCard.id === "aa892bb0-8b29-4f55-afc4-2008be8db547" &&
              setModal({
                open: true,
                children: <ORCH1 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "aa892bb0-8b29-4f55-afc4-2008be8db547"
                  ? "/img/map/Lisner/orch_1_active.svg"
                  : "/img/map/Lisner/orch_1.svg"
              }
              alt="orch_1"
            />
          </div>
        </div>
        <div className={styles.orch_2}>
          <div
            onClick={() =>
              eventCard.id === "aa892bb0-8b29-4f55-afc4-2008be8db547" &&
              setModal({
                open: true,
                children: <ORCH2 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "aa892bb0-8b29-4f55-afc4-2008be8db547"
                  ? "/img/map/Lisner/orch_2_active.svg"
                  : "/img/map/Lisner/orch_2.svg"
              }
              alt="orch_2"
            />
          </div>
        </div>
        <div className={styles.orch_3}>
          <div
            onClick={() =>
              eventCard.id === "aa892bb0-8b29-4f55-afc4-2008be8db547" &&
              setModal({
                open: true,
                children: <ORCH3 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "aa892bb0-8b29-4f55-afc4-2008be8db547"
                  ? "/img/map/Lisner/orch_3_active.svg"
                  : "/img/map/Lisner/orch_3.svg"
              }
              alt="orch_3"
            />
          </div>
        </div>
        <div className={styles.orch_4}>
          <div
            onClick={() =>
              eventCard.id === "aa892bb0-8b29-4f55-afc4-2008be8db547" &&
              setModal({
                open: true,
                children: <ORCH4 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "aa892bb0-8b29-4f55-afc4-2008be8db547"
                  ? "/img/map/Lisner/orch_4_active.svg"
                  : "/img/map/Lisner/orch_4.svg"
              }
              alt="orch_4"
            />
          </div>
        </div>
        <div className={styles.orch_5}>
          <div
            onClick={() =>
              eventCard.id === "306fcdcd-fcb1-4e29-b377-4be1f106bcb2" &&
              setModal({
                open: true,
                children: <ORCH5 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "306fcdcd-fcb1-4e29-b377-4be1f106bcb2"
                  ? "/img/map/Lisner/orch_5_active.svg"
                  : "/img/map/Lisner/orch_5.svg"
              }
              alt="orch_5"
            />
          </div>
        </div>
        <div className={styles.orch_6}>
          <div
            onClick={() =>
              eventCard.id === "306fcdcd-fcb1-4e29-b377-4be1f106bcb2" &&
              setModal({
                open: true,
                children: <ORCH6 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "306fcdcd-fcb1-4e29-b377-4be1f106bcb2"
                  ? "/img/map/Lisner/orch_6_active.svg"
                  : "/img/map/Lisner/orch_6.svg"
              }
              alt="orch_6"
            />
          </div>
        </div>
        <div className={styles.orch_7}>
          <div
            onClick={() =>
              eventCard.id === "306fcdcd-fcb1-4e29-b377-4be1f106bcb2" &&
              setModal({
                open: true,
                children: <ORCH7 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "306fcdcd-fcb1-4e29-b377-4be1f106bcb2"
                  ? "/img/map/Lisner/orch_7_active.svg"
                  : "/img/map/Lisner/orch_7.svg"
              }
              alt="orch_7"
            />
          </div>
        </div>
        <div className={styles.orch_8}>
          <div
            onClick={() =>
              eventCard.id === "306fcdcd-fcb1-4e29-b377-4be1f106bcb2" &&
              setModal({
                open: true,
                children: <ORCH8 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "306fcdcd-fcb1-4e29-b377-4be1f106bcb2"
                  ? "/img/map/Lisner/orch_8_active.svg"
                  : "/img/map/Lisner/orch_8.svg"
              }
              alt="orch_8"
            />
          </div>
        </div>
        <div className={styles.terrace_line}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src="/img/map/Lisner/terrace_line.svg"
              alt="terrace_line"
            />
          </div>
        </div>
        <div className={styles.ter_1}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_1.svg"
              alt="ter_1"
            />
          </div>
        </div>
        <div className={styles.ter_2}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_2.svg"
              alt="ter_2"
            />
          </div>
        </div>
        <div className={styles.ter_3}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_3.svg"
              alt="ter_3"
            />
          </div>
        </div>
        <div className={styles.ter_4}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_4.svg"
              alt="ter_4"
            />
          </div>
        </div>
        <div className={styles.ter_5}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_5.svg"
              alt="ter_5"
            />
          </div>
        </div>
        <div className={styles.ter_6}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_6.svg"
              alt="ter_6"
            />
          </div>
        </div>
        <div className={styles.ter_7}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_6.svg"
              alt="ter_7"
            />
          </div>
        </div>
        <div className={styles.ter_8}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_8.svg"
              alt="ter_8"
            />
          </div>
        </div>
        <div className={styles.ter_9}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_9.svg"
              alt="ter_9"
            />
          </div>
        </div>
        <div className={styles.ter_10}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_10.svg"
              alt="ter_10"
            />
          </div>
        </div>
        <div className={styles.ter_11}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_10.svg"
              alt="ter_11"
            />
          </div>
        </div>
        <div className={styles.ter_12}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Lisner/ter_12.svg"
              alt="ter_12"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LisnerSeatMap;
