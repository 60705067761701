import Countdown from "react-countdown";

const CompletionList = ({size}) => (
  <p className={size === 'md'? "countdown__main" : "countdown__main-sm"}>
    EVENT STARTED
  </p>
);

const pad = (num, size = 2) => {
  const s = "000000000" + num;
  return s.substr(s.length - size);
};

const renderer = ({ days, hours, minutes, seconds, completed, size }) => {
  if (completed) {
    return <CompletionList size={size}/>;
  } else {
    return (
      <div>
        <p className={size === 'md'? "countdown__main" : "countdown__main-sm"}>
          {days} days {pad(hours)}h {pad(minutes)}m
        </p>
      </div>
    );
  }
};

const EventCountDown = ({ event, size = 'md' }) => {
  const d = new Date(event.date) - parseInt(event.time_zone) * 3600 * 1000;
  const e = new Date(event.stop_time) - parseInt(event.time_zone) * 3600 * 1000;
  return <Countdown date={event.stop_time ? e : d} renderer={(props) => renderer({...props, size})} />;
};

export default EventCountDown;
