import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";

import PhoneNumberInput from "../../PhoneNumberInput";
import { validateEmail } from "../../../utils";
// import { isValidPhoneNumber } from "react-phone-number-input";
import useConvertedFiat from "../../../hooks/useConvertedFiat";
// import useFiatSymbol from "../../../hooks/useFiatSymbol";
import { cashPayment } from "../../../api/credit";
import PaymentFailedMoal from "../payment_failed_modal";
import PaymentSuccessMoal from "../payment_success_modal";

import styles from "./index.module.css";

const FreeModal = ({ eventCard, amount, handleTicket }) => {
  console.log("free");
  const { userInfo } = useUserContext();
  const {
    setModal,
    setLoading,
    selectedSeats,
    discount,
    setDiscount,
    country,
    addToast,
    tablePackage,
  } = useAppContext();
  const cardForm = useRef(null);
  const { t } = useTranslation();
  const [values, setValues] = useState({
    name: "Backstage Sponsored",
    phone: "",
    email: "sup3rdev@gmail.com",
    doubleEmail: "sup3rdev@gmail.com",
  });
  const initialValidation = {
    name: true,
    phone: true,
    email: true,
    doubleEmail: true,
  };
  const [validations, setValidations] = useState(initialValidation);
  const convertedPrice = useConvertedFiat(
    eventCard.price,
    eventCard.currency,
    country
  );

  const handleChange = (prop, value) => {
    setValidations((prevState) => ({ ...prevState, [prop]: true }));
    setValues({ ...values, [prop]: value });
  };

  const checkValidation = () => {
    if (values.name === "") {
      setValidations({ ...initialValidation, name: false });
      return false;
    } else if (values.email === "" || !validateEmail(values.email)) {
      setValidations({ ...initialValidation, email: false });
      return false;
    } else if (values.email !== values.doubleEmail) {
      setValidations({ ...initialValidation, doubleEmail: false });
      return false;
    // } else if (values.phone === "" || !isValidPhoneNumber(values.phone)) {
    //   setValidations({ ...initialValidation, phone: false });
    //   return false;
    } else {
      setValidations({ ...initialValidation });
    }
    return true;
  };

  const handlePhone = (value) => {
    handleChange("phone", value);
  };

  const borderStyle = (validation) => {
    return !validation
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  const CURRENCY = {
    TR: "try",
    US: "usd",
    IN: "inr",
    GB: "gbp",
  };

  // const totalCurrencyPrice =
  //   (
  //     convertedPrice *
  //     (1 + (eventCard.tax_include ? 0 : eventCard.tax / 100)) *
  //     (tablePackage === 0 ? amount : tablePackage)
  //   ).toFixed(2) + useFiatSymbol(country);

  // const ticketCurrencyPrice =
  //   (
  //     convertedPrice *
  //     (1 + (eventCard.tax_include ? 0 : eventCard.tax / 100))
  //   ).toFixed(2) + useFiatSymbol(country);

  const emailData = {
    mobile: false,
    email: values.email,
    ticket_number: Number(amount),
    user_name: values.name,
    totalPrice: 'Sponsored',
    ticketPrice: 'Sponsored',
    collection_name: eventCard.collection.name,
    scan: eventCard.scan,
    ticket_type: eventCard.collection.category,
    item: eventCard,
    addons: JSON.parse(eventCard.addons),
    start_now:
      eventCard.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
    date: new Date(localStorage.getItem("service_date") || eventCard.date)
      .toString()
      .substring(0, 21),
    end_date: new Date(
      localStorage.getItem("service_date") || eventCard.end_date
    )
      .toString()
      .substring(0, 21),
  };
  const ticketData = {
    wallet_address: "",
    blockchain: "Binance Smart Chain",
    eventcard: eventCard.id,
    collection: eventCard.collection.id,
    price: 0,
    pay_order_id: "free invitation",
    count: tablePackage === 0 ? amount : 1,
    card_address: "",
    service_date: localStorage.getItem("service_date")
      ? new Date(localStorage.getItem("service_date"))
          .toString()
          .substring(0, 16)
      : null,
    seats: JSON.stringify(selectedSeats),
    discount: discount * 100,
    buyer_name: values.name,
    buyer_email: values.email,
    buyer_phone: values.phone,
  };

  const dataObject = {
    event: {
      id: eventCard?.id || "",
      name: eventCard?.name || "",
      price:
        parseInt(convertedPrice * 100) *
        (tablePackage === 0 ? amount : tablePackage),
    },
    currency: CURRENCY[country] || "eur",
    emailData,
    ticketData,
    buyerId: userInfo.user.id,
    other_website: localStorage.getItem("other_website"),
  };

  const submit = (e) => {
    e.preventDefault();
    if (!checkValidation()) return;
    setLoading(true);
    cashPayment({
      data: dataObject,
    }).then((res) => {
      if (res === "overload seats") {
        setLoading(false);
        setModal({
          open: false,
        });
        addToast(
          "Please choose your seats again because other users already bought the seats.",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      } else if (res) {
        setModal({
          open: true,
          children: <PaymentSuccessMoal />,
        });
        setLoading(false);
        setDiscount(0);
      } else {
        setModal({
          open: true,
          children: <PaymentFailedMoal />,
        });
        setLoading(false);
        setDiscount(0);
      }
    });
  };

  return (
    <form method="post" action="#" className={styles.container} ref={cardForm}>
      <div className={styles.top}>
        <div className={styles.back} onClick={handleTicket}>
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 13L1 7L7 1"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className={styles.back_text}>BACK</span>
        </div>
        <div
          className={styles.btn_close}
          onClick={() => setModal({ open: false })}
        >
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="#ffffff"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
          </svg>
        </div>
      </div>
      <div className={styles.title}>Input User Info</div>
      <div className={styles.wrapper}>
        <div className={styles.input_wrapper_full}>
          {t("full name")}
          <input
            value={values.name}
            onChange={(e) => handleChange("name", e.target.value)}
            style={borderStyle(validations.name)}
            placeholder={t("full name")}
          />
          {!validations.name && (
            <p className={styles.warning_text}>{t("name required")}*</p>
          )}
        </div>
        <div className={styles.input_wrapper_full}>
          {t("phone")}
          <PhoneNumberInput
            phoneNumber={values.phone}
            handlePhone={handlePhone}
            borderStyle={borderStyle(validations.phone)}
          />
          {!validations.phone && (
            <p className={styles.warning_text}>{t("input correct format")}*</p>
          )}
        </div>
        <div className={styles.input_wrapper_full}>
          {t("email")}
          <input
            value={values.email}
            onChange={(e) => handleChange("email", e.target.value)}
            style={borderStyle(validations.email)}
            placeholder={t("email")}
          />
          {validateEmail(values.email) && (
            <img
              src="/img/green-check-circle.png"
              className="signup__img_eye"
              alt="email success"
            />
          )}
          {!validations.email && (
            <p className={styles.warning_text}>{t("input correct format")}*</p>
          )}
        </div>
        <div className={styles.input_wrapper_full}>
          {t("confirm email")}
          <input
            value={values.doubleEmail}
            onChange={(e) => handleChange("doubleEmail", e.target.value)}
            style={borderStyle(validations.doubleEmail)}
            placeholder={t("confirm email")}
          />
          {validateEmail(values.doubleEmail) &&
            values.doubleEmail === values.email && (
              <img
                src="/img/green-check-circle.png"
                className="signup__img_eye"
                alt="email success"
              />
            )}
          {!validations.doubleEmail && (
            <p className={styles.warning_text}>
              {t("confirm email does not match")}*
            </p>
          )}
        </div>
        <div className={styles.input_wrapper_full}>
          {t("discount")}
          <input
            type="number"
            onChange={(e) => setDiscount(e.target.value / 100)}
            placeholder={t("discount")}
          />
          <div className="discount_symbol">%</div>
        </div>
      </div>
      <button
        className="asset__btn asset__btn--full asset__btn--clr open-modal"
        onClick={submit}
        type="button"
      >
        Free Sale
      </button>
    </form>
  );
};

export default FreeModal;
