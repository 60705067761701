import PageError from "../views/pages/404Error";
// import PageActivity from "../views/pages/activity";
import PageArticleCreate from "../views/pages/Article/create";
import PageArticleEdit from "../views/pages/Article/edit";
import PageArticle from "../views/pages/Article";
import PageAuthor from "../views/pages/author";
import PageBlog from "../views/pages/blog";
import PageCollection from "../views/pages/collection";
import PageContact from "../views/pages/contact";
import PageEventCardCreate from "../views/pages/event/create_eventcard";
import PageCollectionCreate from "../views/pages/collection/create_collection";
import PageReferralLink from "../views/pages/collection/referral_link";
import PageEventCard from "../views/pages/event/eventcard";
import PageExplorer from "../views/pages/explorer";
import PageForgetpassword from "../views/pages/forget";
import Home from "../views/pages/home";
import PagePrivacy from "../views/pages/privacy";
import PageTermsPrivacy from "../views/pages/terms_privacy";
import PageSignin from "../views/pages/signin";
import PageSignup from "../views/pages/signup";
import PageMessageCreate from "../views/pages/message/create";
import PageResetPassword from "../views/pages/reset_password";
import PageAdminUsers from "../views/pages/admin/users";
import PageUserCreate from "../views/pages/admin/users/create_user";
import PageAdminSettings from "../views/pages/admin/settings";
import PageWallet from "../views/pages/wallet";
import About from "../views/pages/about";
import TermsAndConditions from "../views/pages/terms";
import PageAdminReport from "../views/pages/admin/report";
import CheckTicket from "../views/pages/check";
import PageLiked from "../views/pages/liked";
import Selfie from "../views/pages/selfie";
import PageFaq from "../views/pages/faq";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/error",
    element: <PageError />,
  },
  {
    path: "/article/create",
    element: <PageArticleCreate />,
  },
  {
    path: "/article/edit/:id",
    element: <PageArticleEdit />,
  },
  {
    path: "/article/:title/:id",
    element: <PageArticle />,
  },
  {
    path: "/admin/users",
    element: <PageAdminUsers />,
  },
  {
    path: "/admin/settings",
    element: <PageAdminSettings />,
  },
  {
    path: "/admin/report",
    element: <PageAdminReport />,
  },
  {
    path: "/admin/users/create",
    element: <PageUserCreate />,
  },
  {
    path: "/activity/*",
    element: <PageWallet />,
  },
  {
    path: "/signin",
    element: <PageSignin />,
  },
  {
    path: "/signup",
    element: <PageSignup />,
  },
  {
    path: "/forget",
    element: <PageForgetpassword />,
  },
  {
    path: "/reset_password/:code",
    element: <PageResetPassword />,
  },
  {
    path: "/privacy",
    element: <PagePrivacy />,
  },
  {
    path: "/terms",
    element: <TermsAndConditions />,
  },
  {
    path: "/terms-privacy",
    element: <PageTermsPrivacy />,
  },
  {
    path: "/blog",
    element: <PageBlog />,
  },
  {
    path: "/contacts",
    element: <PageContact />,
  },
  {
    path: "/explorer",
    element: <PageExplorer />,
  },
  {
    path: "/liked",
    element: <PageLiked />,
  },
  {
    path: "/collection/:id",
    element: <PageCollection />,
  },
  {
    path: "/collection/:id/:referral",
    element: <PageCollection />,
  },
  {
    path: "/author",
    element: <PageAuthor />,
  },
  {
    path: "/event/eventcard/create/:id",
    element: <PageEventCardCreate />,
  },
  {
    path: "/collection/create",
    element: <PageCollectionCreate />,
  },
  {
    path: "/collection/:id/referral_link",
    element: <PageReferralLink />,
  },
  {
    path: "/event/eventcard/:id",
    element: <PageEventCard />,
  },
  {
    path: "/event/eventcard/:id/:other_website",
    element: <PageEventCard />,
  },
  {
    path: "/message/create",
    element: <PageMessageCreate />,
  },
  {
    path: "/check_ticket/:user_name/:event_name/:serial_text/:key",
    element: <CheckTicket />,
  },
  {
    path: "/check_ticket/:event_name/:serial_text/:key/:wallet/:nft_address/:token_id",
    element: <CheckTicket />,
  },
  {
    path: "/haash-giveaway-selfie",
    element: <Selfie />,
  },
  {
    path: "/faq",
    element: <PageFaq />,
  },
  {
    path: "*",
    element: <PageError />,
  },
];

export default routes;
