import React, { useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";

import { useUserContext } from "../../context/UserContext";
import { useAppContext } from "../../context/AppContext";
import { getLikesNumber, isVideoFile } from "../../utils";
import AddonsModal from "../custom_modals/addons_modal";
import Fiat from "../Fiat";
import FiatSymbol from "../FiatSymbol";
import EventCountDown from "../countdown";
import VideoPlayer from "../video_player";
import styles from "./index.module.css";

const EventCardInfo = ({
  eventCard,
  onClickLike,
  showFloorMap,
  isSold,
  handleBuyTicket,
  ticketAmount,
  setTicketAmount,
  fixedTime,
}) => {
  const { userInfo } = useUserContext();
  const recaptchaRef = useRef();
  const { t } = useTranslation();
  const likeNumber = useMemo(() => {
    return getLikesNumber(eventCard);
  }, [eventCard]);
  const [captcha, setCaptcha] = useState(true);
  const { setModal } = useAppContext();
  const currentTime = new Date();
  const timeZoneDiff =
    (currentTime.getTimezoneOffset() / 60 + eventCard.time_zone) * 3600 * 1000;

  const showAddons = () => {
    setModal({
      open: true,
      children: <AddonsModal addons={JSON.parse(eventCard.addons)} />,
    });
  };

  const onChangeCaptcha = (e) => {
    setCaptcha(e);
  };

  const timeIsClose = () => {
    const currentTime = new Date();
    const timeZoneDiff =
      (currentTime.getTimezoneOffset() / 60 + eventCard.time_zone) *
      3600 *
      1000;
    if (
      eventCard.stop_time !== null &&
      currentTime.getTime() + timeZoneDiff >
        new Date(eventCard.stop_time).getTime()
    ) {
      return true;
    }
    return false;
  };

  const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  };

  const buttonText = () => {
    const currentTime = new Date();
    const timeZoneDiff =
      (currentTime.getTimezoneOffset() / 60 + eventCard.time_zone) *
      3600 *
      1000;
    let text =
      eventCard.total_tickets <= eventCard.buy_count ||
      (eventCard.stop_time !== null &&
        currentTime.getTime() + timeZoneDiff >
          new Date(eventCard.stop_time).getTime())
        ? t("sold out")
        : eventCard.sale_start_time !== null &&
          currentTime.getTime() + timeZoneDiff <
            new Date(eventCard.sale_start_time).getTime()
        ? t("coming soon")
        : userInfo?.user?.user_type === "CASHIER ADMIN"
        ? "Cash Sale"
        : t("buy");
    return text;
  };

  const isAvailable = () => {
    if (
      !isSold &&
      !timeIsClose() &&
      (eventCard.sale_start_time === null ||
        currentTime.getTime() + timeZoneDiff >
          new Date(eventCard.sale_start_time).getTime()) &&
      eventCard.deleted !== 1 &&
      captcha
    )
      return true;
    else return false;
  };

  return (
    <div className={styles.assets__container}>
      <div className={styles.asset__item}>
        {isSafari() && isVideoFile(eventCard.picture_large) ? (
          <div className={styles.event__image_contain}>
            <LazyLoadImage
              src={`${process.env.REACT_APP_ASSET_BASE_URL}/${eventCard.picture_small}`}
              alt="large image"
              className={styles.event__image_item}
            />
          </div>
        ) : isVideoFile(eventCard.picture_large) ? (
          <VideoPlayer path={eventCard.picture_large} />
        ) : (
          <div className={styles.event__image_contain}>
            <LazyLoadImage
              src={`${process.env.REACT_APP_ASSET_BASE_URL}/${eventCard.picture_large}`}
              alt="large image"
              className={styles.event__image_item}
            />
          </div>
        )}
      </div>
      <div className={styles.asset__info}>
        <div className={styles.asset__info_title}>
          <p className={styles.asset__name}>{eventCard.name}</p>
          <div className={styles.flex__align_center}>
            <div className="card__likes" onClick={() => onClickLike()}>
              {userInfo &&
              eventCard.likes_number &&
              eventCard.likes_number.includes(userInfo.user.id) ? (
                <LazyLoadImage
                  src="/img/icons/liked_blue.svg"
                  alt="like"
                  className="like-img"
                />
              ) : (
                <svg
                  className="like-img"
                  width="20"
                  height="20"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1107_4473)">
                    <path
                      className="heart_icon"
                      d="M14.1187 3.22152C13.7782 2.88086 13.3739 2.61062 12.9289 2.42625C12.4839 2.24187 12.007 2.14697 11.5253 2.14697C11.0437 2.14697 10.5667 2.24187 10.1218 2.42625C9.6768 2.61062 9.27251 2.88086 8.93201 3.22152L8.22534 3.92819L7.51867 3.22152C6.83088 2.53373 5.89803 2.14733 4.92534 2.14733C3.95265 2.14733 3.0198 2.53373 2.33201 3.22152C1.64421 3.90932 1.25781 4.84217 1.25781 5.81486C1.25781 6.78754 1.64421 7.72039 2.33201 8.40819L3.03867 9.11486L8.22534 14.3015L13.412 9.11486L14.1187 8.40819C14.4593 8.06769 14.7296 7.6634 14.9139 7.21843C15.0983 6.77345 15.1932 6.29651 15.1932 5.81486C15.1932 5.3332 15.0983 4.85626 14.9139 4.41129C14.7296 3.96631 14.4593 3.56203 14.1187 3.22152V3.22152Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1107_4473">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0.226562 0.147949)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </div>
            <p className={styles.like__number}>{likeNumber}</p>
          </div>
        </div>
        <div className={styles.asset__author}>
          <div className={styles.asset__author_items}>
            <div className="asset__author-item asset__author-first-item">
              <p className={styles.text__small_title}>{t("creator")}</p>
              <div style={{ display: "inline" }}>
                <div className={styles.creator__txt}>
                  {eventCard.creator.name}
                </div>
                <img
                  src="/img/icons/verified.svg"
                  alt="verified"
                  className={styles.verified__img}
                />
              </div>
            </div>
            <div className="asset__author-item">
              <p className={styles.text__small_title}>{t("collection")}</p>
              <div className={styles.collection__contain}>
                <LazyLoadImage
                  src={`${process.env.REACT_APP_ASSET_BASE_URL}/${eventCard.collection.picture_small}`}
                  alt="collecion image"
                  className={styles.asset__collection_img}
                />
                <Link
                  to={`/collection/${eventCard.collection.id}`}
                  className={styles.color__white}
                >
                  {eventCard.collection.name}
                </Link>
              </div>
            </div>
          </div>
          {eventCard.category === "Category1" && (
            <div className={styles.asset__author_items}>
              <div className="asset__author-item asset__author-first-item">
                <p className={styles.text__small_title}>{t("location")}</p>
                <div className={styles.flex__align_center}>
                  <p className={styles.text__small_val}>{eventCard.location}</p>
                </div>
              </div>
              <div className="asset__author-item asset__author-first-item">
                <p className={styles.text__small_title}>{t("date")}</p>
                <div className={styles.flex__align_center}>
                  <p className={styles.text__small_val}>
                    {moment(eventCard.date).format("ll")}
                  </p>
                </div>
              </div>
              <div className="asset__author-item asset__author-first-item">
                <p className={styles.text__small_title}>{t("time")}</p>
                <div className={styles.flex__align_center}>
                  <p className={styles.text__small_val}>
                    {fixedTime(eventCard.date)}
                  </p>
                </div>
              </div>
            </div>
          )}
          {eventCard.category === "Category3" &&
            (eventCard.period === null ? (
              <div className={styles.asset__author_items}>
                <div className="asset__author-item asset__author-first-item">
                  <p className={styles.text__small_title}>
                    {eventCard?.end_date ? t("start date") : t("date")}
                  </p>
                  <div className={styles.flex__align_center}>
                    {eventCard?.end_date ? (
                      <p className={styles.text__small_val}>
                        {moment(eventCard.date).format("lll")}
                      </p>
                    ) : (
                      <p className={styles.text__small_val}>
                        {moment(eventCard.date).format("ll")}
                      </p>
                    )}
                  </div>
                </div>
                <div className="asset__author-item">
                  <p className={styles.text__small_title}>
                    {eventCard?.end_date ? t("end date") : t("time")}
                  </p>
                  <div className={styles.flex__align_center}>
                    {eventCard?.end_date ? (
                      <p className={styles.text__small_val}>
                        {moment(eventCard.end_date).format("lll")}
                      </p>
                    ) : (
                      <p className={styles.text__small_val}>
                        {fixedTime(eventCard.date)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.asset__author_items}>
                <div className="asset__author-item asset__author-first-item">
                  <p className={styles.text__small_title}>
                    {t("Benefit Period")}
                  </p>
                  <div className={styles.flex__align_center}>
                    <p className={styles.text__small_val}>
                      {eventCard.period} {eventCard.period_unit}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {JSON.parse(eventCard.addons).length > 0 && (
          <div className={styles.asset__collection}>
            <div className={styles.asset__author_items}>
              <div className={styles.addons__detail_contain}>
                <div className={styles.flex}>
                  <div className={styles.flex__align_center}>
                    <img alt="addons" src="/img/addon.png" />
                  </div>
                  <p className={styles.addons__detail_text}>
                    <span className="fontBold">
                      {JSON.parse(eventCard.addons).length} Benefit
                      {JSON.parse(eventCard.addons).length > 1 ? "s" : null}
                      &nbsp;
                    </span>
                    Included
                  </p>
                </div>
                <button
                  className={styles.addons__view_btn}
                  disabled={
                    JSON.parse(eventCard.addons).length > 0 ? false : true
                  }
                  onClick={showAddons}
                >
                  {t("view")}
                </button>
              </div>
            </div>
          </div>
        )}

        {eventCard.category !== "Category2" && (
          <div className={styles.asset__collection}>
            <div className={styles.asset__author_items}>
              {eventCard.picture_floormap !== null && (
                <div
                  className="asset__author-item asset__author-first-item"
                  style={{ marginBottom: 0 }}
                >
                  <div style={{ margin: "10px 0" }}>
                    <div className={styles.flex}>
                      <img
                        src="/img/icons/map.svg"
                        alt="map"
                        className={styles.map__img}
                      />
                      <p className={styles.text__small_title}>
                        {t("floor plan")}
                      </p>
                    </div>
                    <button className={styles.map__btn} onClick={showFloorMap}>
                      {t("view")}
                    </button>
                  </div>
                </div>
              )}
              <div
                className="asset__author-item"
                style={{ marginBottom: 0, flex: 1 }}
              >
                <div className={styles.asset__timing}>
                  <div className={styles.flex__align_center}>
                    <img
                      src="/img/icons/clock.svg"
                      alt="clock"
                      className={styles.map__img}
                    />
                    {eventCard.category === "Category1" ? (
                      <p className="text__small-time">{t("event starts in")}</p>
                    ) : (
                      <p className="text__small-time">
                        {t("end of the offer")}
                      </p>
                    )}
                  </div>
                  <div className={styles.mt__10}>
                    <EventCountDown event={eventCard} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.w__100}>
          <div className={styles.purchase__contain}>
            {eventCard.category === "Category1" ? (
              <p className={styles.asset__desc}>
                {/* {eventCard.total_tickets - eventCard.buy_count}{" "}
                {t("ticket(s) left")} */}
              </p>
            ) : (
              <p className={styles.asset__desc}>
                {/* {eventCard.total_tickets - eventCard.buy_count}{" "}
                {t("item(s) left")} */}
              </p>
            )}
            <div>
              <p className="text__small-time text__small-tax">
                {eventCard.tax_include ? "INCL. TAX" : "EXCL. TAX"}
              </p>
              <p className={styles.asset__name} style={{ fontSize: 32 }}>
                <Fiat price={eventCard.price} currency={eventCard.currency} />
                &nbsp;
                <FiatSymbol />
              </p>
            </div>
          </div>
          {!captcha && (
            <div className={styles.color__white}>{t("capcha text")}</div>
          )}
          {!captcha && (
            <ReCAPTCHA
              className={styles.recaptcha}
              ref={recaptchaRef}
              sitekey="6LeaLwUgAAAAAIBN0ef2xzTx0rIfuLb1POyzr_ei"
              onChange={onChangeCaptcha}
            />
          )}
          <div className={styles.flex}>
            <div className={styles.assets__amount}>
              <p
                onClick={() => {
                  if (ticketAmount > 1) setTicketAmount(ticketAmount - 1);
                }}
              >
                -
              </p>
              <p className={styles.assets__amount_number}>{ticketAmount}</p>
              <p
                onClick={() => {
                  if (ticketAmount < 10) setTicketAmount(ticketAmount + 1);
                }}
              >
                +
              </p>
            </div>
            <button
              className={isAvailable() ? "buy__btn" : "buy__btn-disable"}
              onClick={isAvailable() ? handleBuyTicket : () => {}}
            >
              {buttonText()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCardInfo;
