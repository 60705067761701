import { useAppContext } from "../../../context/AppContext";
import SelfiePreview from "../../../components/custom_modals/selfie_preview__modal";

const Selfie = () => {
  const { setModal } = useAppContext();
  const openCamera = () => {
    setModal({
      open: true,
      children: <SelfiePreview />,
    });
  };
  const imgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    console.log("image change")
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setModal({
            open: true,
            children: <SelfiePreview uploadSrc={reader.result as string} />,
          });
        };
        reader.readAsDataURL(f);
      }
    }
  };
  return (
    <div className="container">
      <div className="selfie__start_container">
        <div className="selfie__select">
          <img alt="selfie" src="/img/selfie/traced.png" />
          <p className="selfie__title">
            Take or upload a selfie and share with your friends!
          </p>
          <p className="collection__author-description selfie__info">
            Share your Ha-Ash raffle ticket purchase
          </p>
          <button className="input__upload" onClick={() => openCamera()}>
            <img
              alt="camera"
              className="camera__img"
              src="/img/selfie/camera.png"
            />
            <p className="camera__text">OPEN CAMERA APP</p>
          </button>
          <div className="cr__evcard-uploadImg">
            <label
              id="file"
              htmlFor="selfieImage-upload"
              className="input__upload"
            >
              <img src="/img/selfie/upload.png" alt="upload" />{" "}
              <span>UPLOAD</span>
            </label>
            <input
              data-name="#file"
              id="selfieImage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg"
              onChange={imgChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selfie;
