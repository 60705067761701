import { useMemo } from "react";

const useFiatSymbol = (region) => {
  const fiatSymbol = useMemo(() => {
    switch (region) {
      case "TR":
        return "₺";
      case "GB":
        return "£";
      case "US":
        return "$";
      case "EU":
        return "€";
      case "IN":
        return "₹";
      default:
        return "€";
    }
  }, [region]);
  return fiatSymbol;
};

export default useFiatSymbol;
