/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { saveAs } from "file-saver";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { createReferralLinks } from "../../../api/event";
import { ILink, ICollection } from "../../../utils/interface";
import { getCollectionById } from "../../../api/event";

var md5 = require("md5");
const md5Str = md5(new Date()).substring(0, 10);

const PageReferralLink = () => {
  const { id } = useParams();
  const { userInfo } = useUserContext();
  const [links, setLinks] = useState<ILink[]>([] as ILink[]);
  const [collection, setCollection] = useState<ICollection>();
  const [linkCount, setLinkCount] = useState(0);
  const { setLoading, addToast } = useAppContext();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const add_Links = () => {
    setLinks([
      ...links,
      {
        code: md5Str + "-" + linkCount,
        active: 0,
      },
    ]);
    let _linkCount = linkCount + 1;
    setLinkCount(_linkCount);
    console.log(links);
  };

  const remove_Links = (index: number) => {
    setLinks(links.slice(0, index).concat(links.slice(index + 1)));
  };

  const handleCreate = async () => {
    setLoading(true);

    createReferralLinks({
      id: id,
      referral: JSON.stringify(links),
    })
      .then(async (res) => {
        if (res.success) {
          setLoading(false);
          addToast("Successfully created referral links", {
            appearance: "success",
            autoDismiss: true,
          });
          // navigate("/");
          getCollectionById(id).then((res) => {
            if (res.success) {
              setCollection(res.collection);
            }
          });
        } else {
          addToast("failed", { appearance: "error", autoDismiss: true });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("An error occured", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const exportFile = () => {
    let referral_links = "";
    if (collection?.referral !== null && collection?.referral !== "") {
      let links = JSON.parse(collection?.referral as string);
      links.forEach((element: any) => {
        referral_links += process.env.REACT_APP_API_BASE_URL + "/collection/" + id + "/" + element.code + "\n";
      });
    }
  
    var blob = new Blob([referral_links], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "referral_links.txt");
  }

  useEffect(() => {
    if (
      userInfo?.user?.user_type !== "ADMIN" &&
      userInfo?.user?.user_type !== "TURKISH ADMIN"
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    getCollectionById(id).then((res) => {
      if (res.success) {
        setCollection(res.collection);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="create__collection_container">
      <h2 className="cr__email--title">{t("Referral links")}</h2>
      <div className="referral__btn_container">
        <button
          type="button"
          className="sign__btn"
          onClick={() => exportFile()}
          style={{ margin: "0 0 40px 0", width: 150 }}
        >
          {t("download")}
        </button>
      </div>
      <div className="cr__collection--formContainer">
        <div className="cr__evcard-100" style={{ paddingLeft: 0 }}>
          {links.length !== 0 && (
            <div className="referral-container">
              {links.map((link: ILink, index: number) => (
                <div
                  className="addon-block"
                  style={{ marginBottom: 0 }}
                  key={index}
                >
                  <p
                    style={{
                      color: "#fff",
                      width: 30,
                      marginBottom: 0,
                    }}
                  >
                    {index + 1}
                  </p>
                  <p className="text__addon-description">{link.code}</p>
                  <div
                    className="sign__btn-second"
                    style={{
                      width: 40,
                      border: 0,
                      margin: 0,
                    }}
                    onClick={() => remove_Links(index)}
                  >
                    <LazyLoadImage
                      src="/img/trash.png"
                      alt="trush"
                      className="img__trush"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="sign__btn-second" onClick={add_Links}>
            {t("Generate")}
          </div>
        </div>
      </div>
      <div className="referral__btn_container">
        <button
          type="button"
          className={links.length === 0 ? "buy__btn-disable" : "sign__btn"}
          disabled={links.length === 0}
          onClick={handleCreate}
          style={{ marginRight: 0, marginLeft: 0 }}
        >
          {t("create referral links")}
        </button>
      </div>
    </div>
  );
};

export default PageReferralLink;
