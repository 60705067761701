import { useState } from "react";
import { useAppContext } from "../../../../context/AppContext";
import { isMobile } from "react-device-detect";

import OrchestraBronze1 from "../bronze/orchestra_bronze_1";
import OrchestraBronze2 from "../bronze/orchestra_bronze_2";
import OrchestraBronze3 from "../bronze/orchestra_bronze_3";
// import BronzeSide1 from "../bronze_side/bronze_side_1";
// import BronzeSide2 from "../bronze_side/bronze_side_2";
// import Obstructed from "../bronze_side/obstructed";
// import MezzanineBronze1 from "../bronze/mezzanine_bronze_1";
// import MezzanineBronze2 from "../bronze/mezzanine_bronze_2";
// import MezzanineBronze3 from "../bronze/mezzanine_bronze_3";
// import MezzanineBronze4 from "../bronze/mezzanine_bronze_4";
// import MezzanineBronze5 from "../bronze/mezzanine_bronze_5";

import styles from "./index.module.css";

const ChicagoUpdateSeatMap = ({ eventCard }) => {
  const { setModal } = useAppContext();
  const [maxWidth, setMaxWidth] = useState(isMobile ? 400 : 900);
  const zoomOut = () => {
    if (maxWidth < 1000) {
      setMaxWidth(maxWidth + 30);
    }
  };
  const zoomIn = () => {
    if (maxWidth > 400) {
      setMaxWidth(maxWidth - 30);
    }
  };

  return (
    <div className="container">
      <div className={styles.zoom_out}>
        <div onClick={() => zoomOut()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_out.svg"
            alt="zoom_out"
          />
        </div>
      </div>
      <div className={styles.zoom_in}>
        <div onClick={() => zoomIn()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_in.svg"
            alt="zoom_in"
          />
        </div>
      </div>
      <p className="seating__area_txt" style={{ width: maxWidth }}>
        1. Please select the seating area first
      </p>
      <div className="copernicus__map_container" style={{ width: maxWidth }}>
        <div
          className={styles.btn_close}
          onClick={() => setModal({ open: false })}
        >
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="#ffffff"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
          </svg>
        </div>
        <div className={styles.stage}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src="/img/map/chicago/copernicus_stage_c.svg"
              alt="stage"
            />
          </div>
        </div>
        <div className={styles.bronze_1}>
          <div
            onClick={() =>
              (eventCard.id === "2991d3fe-07bb-41c4-ac98-b9004872288a" ||
                eventCard.id === "ca64626a-18c0-40b9-942d-e9c1cba2ebd4") &&
              setModal({
                open: true,
                children: <OrchestraBronze1 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "2991d3fe-07bb-41c4-ac98-b9004872288a" ||
                eventCard.id === "ca64626a-18c0-40b9-942d-e9c1cba2ebd4"
                  ? "/img/map/chicago/bronze_1_u_active.svg"
                  : "/img/map/chicago/bronze_1_u.svg"
              }
              alt="bronze_1"
            />
          </div>
        </div>
        <div className={styles.bronze_3}>
          <div
            onClick={() =>
              (eventCard.id === "2991d3fe-07bb-41c4-ac98-b9004872288a" ||
                eventCard.id === "ca64626a-18c0-40b9-942d-e9c1cba2ebd4") &&
              setModal({
                open: true,
                children: <OrchestraBronze3 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "2991d3fe-07bb-41c4-ac98-b9004872288a" ||
                eventCard.id === "ca64626a-18c0-40b9-942d-e9c1cba2ebd4"
                  ? "/img/map/chicago/bronze_3_u_active.svg"
                  : "/img/map/chicago/bronze_3_u.svg"
              }
              alt="bronze_3"
            />
          </div>
        </div>
        <div className={styles.bronze_2}>
          <div
            onClick={() =>
              (eventCard.id === "2991d3fe-07bb-41c4-ac98-b9004872288a" ||
                eventCard.id === "a8ea5b4d-ba38-4916-a2b9-ccb9e0e7d1e2" ||
                eventCard.id === "ca64626a-18c0-40b9-942d-e9c1cba2ebd4") &&
              setModal({
                open: true,
                children: <OrchestraBronze2 eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "2991d3fe-07bb-41c4-ac98-b9004872288a" ||
                eventCard.id === "a8ea5b4d-ba38-4916-a2b9-ccb9e0e7d1e2" ||
                eventCard.id === "ca64626a-18c0-40b9-942d-e9c1cba2ebd4"
                  ? "/img/map/chicago/bronze_2_u_active.svg"
                  : "/img/map/chicago/bronze_2_u.svg"
              }
              alt="bronze_2"
            />
          </div>
        </div>
        <div className={styles.sound_light}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/sound_light.svg"
              alt="sound_light"
            />
          </div>
        </div>
        <div className={styles.ada_seat_1}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/ada_seat1_c.svg"
              alt="ada_seat_1"
            />
          </div>
        </div>
        <div className={styles.ada_seat_2}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/ada_seat2_c.svg"
              alt="ada_seat_2"
            />
          </div>
        </div>
        <div className={styles.bronze_side_1}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <BronzeSide1 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "854b1a5f-b44e-407b-9dcf-2de4d591e667"
                  ? "/img/map/chicago/bronze_side_1_u_active.svg"
                  : "/img/map/chicago/bronze_side_1_u.svg"
              }
              alt="bronze_side_1"
            />
          </div>
        </div>
        <div className={styles.bronze_side_2}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <BronzeSide2 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "854b1a5f-b44e-407b-9dcf-2de4d591e667"
                  ? "/img/map/chicago/bronze_side_2_u_active.svg"
                  : "/img/map/chicago/bronze_side_2_u.svg"
              }
              alt="bronze_side_2"
            />
          </div>
        </div>
        <div className={styles.obstructed}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <Obstructed eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/obstructed_c.svg"
              alt="obstructed"
            />
          </div>
        </div>
        <div className={styles.exit_1}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_1"
            />
          </div>
        </div>
        <div className={styles.exit_2}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_2"
            />
          </div>
        </div>
        <div className={styles.exit_3}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_3"
            />
          </div>
        </div>
        <div className={styles.exit_4}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_4"
            />
          </div>
        </div>
        <div className={styles.exit_5}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_5"
            />
          </div>
        </div>
        <div className={styles.exit_6}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_6"
            />
          </div>
        </div>
        <div className={styles.exit_7}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_7"
            />
          </div>
        </div>
        <div className={styles.exit_8}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_8"
            />
          </div>
        </div>
        <div className={styles.exit_9}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_9"
            />
          </div>
        </div>
        <div className={styles.mez_bronze_1}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <MezzanineBronze1 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "c95e5e86-a576-46f4-80e0-81d6cbe7533f"
                  ? "/img/map/chicago/mez_bronze_1_u_active.svg"
                  : "/img/map/chicago/mez_bronze_1_u.svg"
              }
              alt="mez_bronze_1"
            />
          </div>
        </div>
        <div className={styles.mez_bronze_2}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <MezzanineBronze2 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "c95e5e86-a576-46f4-80e0-81d6cbe7533f"
                  ? "/img/map/chicago/mez_bronze_2_u_active.svg"
                  : "/img/map/chicago/mez_bronze_2_u.svg"
              }
              alt="mez_bronze_2"
            />
          </div>
        </div>
        <div className={styles.mez_bronze_3}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <MezzanineBronze3 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "c95e5e86-a576-46f4-80e0-81d6cbe7533f"
                  ? "/img/map/chicago/mez_bronze_3_u_active.svg"
                  : "/img/map/chicago/mez_bronze_3_u.svg"
              }
              alt="mez_bronze_3"
            />
          </div>
        </div>
        <div className={styles.mez_bronze_4}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <MezzanineBronze4 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "c95e5e86-a576-46f4-80e0-81d6cbe7533f"
                  ? "/img/map/chicago/mez_bronze_4_u_active.svg"
                  : "/img/map/chicago/mez_bronze_4_u.svg"
              }
              alt="mez_bronze_4"
            />
          </div>
        </div>
        <div className={styles.mez_bronze_5}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <MezzanineBronze5 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "c95e5e86-a576-46f4-80e0-81d6cbe7533f"
                  ? "/img/map/chicago/mez_bronze_5_u_active.svg"
                  : "/img/map/chicago/mez_bronze_5_u.svg"
              }
              alt="mez_bronze_5"
            />
          </div>
        </div>
        <div className={styles.exit_10}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_10"
            />
          </div>
        </div>
        <div className={styles.exit_11}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/exit_c.svg"
              alt="exit_11"
            />
          </div>
        </div>
        <div className={styles.house_seat}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/chicago/house_seat_c.svg"
              alt="house_seat"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChicagoUpdateSeatMap;
