import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../context/UserContext";
import styles from "./index.module.css";

const GetStarted = () => {
  const { userInfo } = useUserContext();
  const { t } = useTranslation();
  return (
    <div className="row" style={{ maxWidth: 1224, margin: 0 }}>
      <div className="col-12">
        <div className={styles.main__sub__title} style={{ textAlign: "center" }}>
          {t("get started.title")}
        </div>
      </div>
      <div className={styles.feature}>
        <div className="feature__item">
          <div className={styles.feature__icon}>
            <img src="/img/started/create.svg" alt="data" />
          </div>
          <h3 className={styles.feature__title}>{t("get started.subTitle1")}</h3>
        </div>

        <div className="feature__item">
          <span className={styles.feature__icon}>
            <img src="/img/started/claim.svg" alt="data" />
          </span>
          <h3 className={styles.feature__title}>{t("get started.subTitle2")}</h3>
        </div>

        <div className="feature__item">
          <span className={styles.feature__icon}>
            <img src="/img/started/list.svg" alt="data" />
          </span>
          <h3 className={styles.feature__title}>
            {t("get started.subTitle3")}
            {userInfo ? (
              <Link to="/activity">
                {t("get started.connectWallet")}
              </Link>
            ) : (
              <Link to="/signin">{t("get started.connectWallet")}</Link>
            )}
          </h3>
        </div>

        <div className="feature__item feature__last_item">
          <span className="feature__icon feature__icon--red">
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <img src="/img/started/purchase.svg" alt="data" />
            </div>
          </span>
          <h3 className={styles.feature__title}>{t("get started.subTitle4")}</h3>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
