import { useState, useEffect } from "react";
import { useAppContext } from "../context/AppContext";

const Fiat = ({ price, currency }) => {
  const {
    country,
    rateEURvsUSD,
    rateGBPvsUSD,
    rateLIRAvsUSD,
    rateRUPEEvsUSD,
  } = useAppContext();
  const [fiat, setFiat] = useState(Number(price));
  useEffect(() => {
    switch (country) {
      case "TR":
        if (currency === "USD") {
          setFiat(Number(price) / rateLIRAvsUSD);
        } else if (currency === "TRY") {
          setFiat(Number(price));
        } else if (currency === "INR") {
          setFiat((Number(price) * rateRUPEEvsUSD) / rateLIRAvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateLIRAvsUSD);
        }
        break;
      case "GB":
        if (currency === "USD") {
          setFiat(Number(price) / rateGBPvsUSD);
        } else if (currency === "INR") {
          setFiat((Number(price) * rateRUPEEvsUSD) / rateGBPvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateLIRAvsUSD) / rateGBPvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateGBPvsUSD);
        }
        break;
      case "US":
        if (currency === "USD") {
          setFiat(Number(price));
        } else if (currency === "INR") {
          setFiat(Number(price) * rateRUPEEvsUSD);
        } else if (currency === "TRY") {
          setFiat(Number(price) * rateLIRAvsUSD);
        } else {
          setFiat(Number(price) * rateEURvsUSD);
        }
        break;
      case "IN":
        if (currency === "INR") {
          setFiat(Number(price));
        } else if (currency === "USD") {
          setFiat(Number(price) / rateRUPEEvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateLIRAvsUSD) / rateRUPEEvsUSD);
        } else {
          setFiat((Number(price) * rateEURvsUSD) / rateRUPEEvsUSD);
        }
        break;
      case "EU":
        if (currency === "USD") {
          setFiat(Number(price) / rateEURvsUSD);
        } else if (currency === "INR") {
          setFiat((Number(price) * rateRUPEEvsUSD) / rateEURvsUSD);
        } else if (currency === "TRY") {
          setFiat((Number(price) * rateLIRAvsUSD) / rateEURvsUSD);
        } else {
          setFiat(Number(price));
        }
        break;
      default:
        setFiat(Number(price));
        break;
    }
  }, [
    country,
    rateEURvsUSD,
    rateGBPvsUSD,
    rateLIRAvsUSD,
    rateRUPEEvsUSD,
    price,
    currency,
  ]);
  return <>{fiat.toFixed(2)}</>;
};

export default Fiat;
