// import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { Tooltip } from "@material-ui/core";
// import { withStyles } from "@material-ui/core/styles";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useAppContext } from "../../context/AppContext";

const SelfieSuccessMoal = () => {
  const { setModal } = useAppContext();
  const navigate = useNavigate();
//   const [open, setOpen] = useState(false);

//   const handleTooltipClose = () => {
//     setOpen(false);
//   };

//   const HtmlTooltip = withStyles((theme) => ({
//     tooltip: {
//       fontSize: "14px",
//       background: "#06060E",
//       boxShadow: "0px 0px 30px rgba(5, 5, 14, 0.8)",
//       padding: "18px",
//       fontWeight: 400,
//       letterSpacing: "0.03em",
//     },
//   }))(Tooltip);
  return (
    <div className="payment__success_container">
      <div>
        <img
          alt="payment success"
          style={style.success_img}
          src="/img/payment/check-circle.png"
        />
      </div>
      <p style={style.title}>Payment Successful!</p>
      <p style={style.info}>
        A confirmation email has been sent to your email address.
      </p>
      <div style={style.mint_container}>
        {/* <div style={style.mint_info}>
          <p style={style.info_text}>
            You’ll need 0.003BNB to claim your digital item on Binance Smart
            Chain
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div style={style.info_image}>
                <HtmlTooltip
                  title="BNB is the native token of the Binance smart Chain used to complete any transaction inside the network. You'll need this ammount in your digital wallet to cover the transaction fee."
                  aria-label="add"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                >
                  <img
                    alt="info"
                    onClick={() => setOpen(!open)}
                    src="/img/info_.png"
                  />
                </HtmlTooltip>
              </div>
            </ClickAwayListener>
          </p>
        </div>
        <button
          onClick={() => {
            setModal({ open: false });
            navigate("/activity");
          }}
          style={style.mint_btn}
        >
          MINT NOW
        </button> */}
        <img alt="selfie" src="/img/selfie/traced.png" />
        <p style={style.selfie__title}>
          Take or upload a selfie and share with your friends!
        </p>
        <p style={style.info}>
          Share your Ha-Ash raffle ticket purchase
        </p>
        <button
          onClick={() => {
            setModal({ open: false });
            navigate("/haash-giveaway-selfie");
          }}
          style={style.mint_btn}
        >
          <p className="camera__text">START</p>
        </button>
      </div>
      <button onClick={() => setModal({ open: false })} style={style.close_btn}>
        DONE
      </button>
    </div>
  );
};

const style = {
  container: {
    width: "490px",
    maxWidth: "98%",
    textAlign: "center",
    backgroundColor: "rgb(0, 18, 0)",
    color: "white",
    padding: "57px 41px 41px 41px",
    borderRadius: "10px",
    border: "1px solid rgb(31, 49, 39)",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  success_img: {
    width: "68px",
    height: "68px",
  },
  title: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    margin: "22px auto auto auto",
  },
  info: {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.03em",
    color: "rgba(255, 255, 255, 0.66)",
    margin: "10px auto 26px auto",
    maxWidth: 304,
  },
  mint_container: {
    width: "100%",
    // background: "rgba(255, 255, 255, 0.05)",
    background:
      "radial-gradient(50% 50% at 50% 0%, #2e266d 0%, rgba(255, 255, 255, 0.05) 100%)",
    borderRadius: "8px",
    padding: "18px 18px 26px 18px",
  },
  mint_info: {
    display: "flex",
  },
  info_text: {
    fontFamily: "Space Grotesk",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    textAlign: "center",
    display: "inline",
  },
  mint_btn: {
    border: "1px solid rgba(255, 255, 255, 0.33)",
    borderRadius: "4px",
    width: "100%",
    fontFamily: "Space Grotesk",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.15em",
    padding: "12px 10px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
  },
  close_btn: {
    background: "#6A4DFD",
    borderRadius: "4px",
    padding: "14px 0",
    fontFamily: "Space Grotesk",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.15em",
    color: "#FFFFFF",
    marginTop: "22px",
  },
  info_image: {
    display: "inline-block",
    marginLeft: "5px",
  },
  selfie__title: {
    fontFamily: "Space Grotesk",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    margin: "0 auto auto auto",
    maxWidth: "273px",
  },
};

export default SelfieSuccessMoal;
