import { useEffect, useState, useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";

import { buyTicket } from "../../../api/event";
import { turkish } from "../../../utils/location";
import useAnalyticsEventTracker from "../../../utils/ga";
import {
  USDTMainAddress,
  USDTPayment_ABI,
  USDT_MAIN_ABI,
  paymentAddress_mainnet,
} from "../../../utils/payment_contract";
import ParamModal from "../param_modal/index.js";
// import StripeModal from "./stripe_modal";
import StripeElementModal from "../stripe_element_modal";
import PaymentFailedMoal from "../payment_failed_modal";
import PasswordModal from "../password_modal";
import useConvertedFiat from "../../../hooks/useConvertedFiat";
import FiatSymbol from "../../FiatSymbol";
import TermsConditionModal from "../terms_conditions_modal";
import { getEventPrice } from "../../../utils";
import client from "../../../utils/ipfs_client";
import { convertHtmlToString } from "../../../utils/convertHtmlToString";
import styles from "./index.module.css";

const TicketBuyModal = ({ eventCard, handleEnd, handleTicket }) => {
  const {
    setLoading,
    setModal,
    country,
    buyTicketEvent,
    setBuyTicketEvent,
    addToast,
    discount,
    selectedSeats,
    tablePackage,
    rateEURvsUSD,
    rateLIRAvsUSD,
    rateRUPEEvsUSD,
    rateGBPvsUSD,
    ticketAmount,
    setTicketAmount,
  } = useAppContext();
  const { userInfo } = useUserContext();
  const [terms, setTerms] = useState(false);
  const memoizedTxn = useMemo(() => buyTicketEvent?.data?.txHash, [
    buyTicketEvent,
  ]);
  const currency = useMemo(() => {
    switch (country) {
      case "TR":
        return "₺";
      case "GB":
        return "£";
      case "US":
        return "$";
      case "IN":
        return "₹";
      default:
        break;
    }
  }, [country]);
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 576px)");
  const gaEventTracker = useAnalyticsEventTracker("Buy Ticket");
  // const usdPrice = useConvertedFiat(eventCard.price, eventCard.currency, "US");
  const convertedPrice = useConvertedFiat(
    eventCard.price,
    eventCard.currency,
    country
  );

  useEffect(() => {
    if (buyTicketEvent && buyTicketEvent.status) {
      const buyData = buyTicketEvent.data;
      console.log("buy with BKS", buyData);
      handleBuyTicket(buyData.txHash, buyData.wallet, "Binance Smart Chain");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedTxn]);

  useEffect(() => {
    if (selectedSeats.length > 0) {
      setTicketAmount(selectedSeats.length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buyInUSDT = async (provide) => {
    const provider = new ethers.providers.Web3Provider(provide);
    const chainId = Number(provider.provider.chainId);
    // if (chainId !== 97) {
    if (chainId !== 56) {
      addToast("Please change the network ", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      addToast("Please wait ... It might takes some time", {
        appearance: "warning",
        autoDismiss: true,
      });
      setLoading(true);
      try {
        const account = await provider.getSigner().getAddress();
        const USDT = new ethers.Contract(
          USDTMainAddress,
          USDT_MAIN_ABI,
          provider.getSigner()
        );
        const contract = new ethers.Contract(
          paymentAddress_mainnet,
          USDTPayment_ABI,
          provider.getSigner()
        );
        const price = calcPrice(eventCard.currency, eventCard.price);
        const totalUSDT = await USDT.balanceOf(account);
        if (ethers.BigNumber.from(totalUSDT).lt(price)) {
          addToast("You don't have enough USDT in your wallet", {
            appearance: "error",
            autoDismiss: true,
          });
          setLoading(false);
        } else {
          try {
            let txn = await USDT.approve(paymentAddress_mainnet, price);
            await txn.wait();
            const _payees = JSON.parse(eventCard.payees).map(
              (payee) => payee.wallet
            );
            const _fees = JSON.parse(eventCard.payees).map((payee) =>
              Number(Number(payee.fee).toFixed())
            );
            txn = await contract.payWithBUSD(
              account,
              eventCard.owner_wallet,
              _payees,
              _fees,
              price
            );
            await txn.wait();
            handleBuyTicket(txn.hash, account, "Binance Smart Chain");
          } catch (error) {
            setLoading(false);
            setModal({
              open: true,
              children: <PaymentFailedMoal />,
            });
          }
        }
      } catch (err) {
        setLoading(false);
        addToast(err.message, { appearance: "warning", autoDismiss: true });
      }
    }
  };

  const buyWithUSDT = async (_provide) => {
    let provide = null;
    if (isMobile && _provide !== "BKSWallet") {
      if (!window.ethereum) {
        window.location.href = `https://metamask.app.link/dapp/liveticket.live/event/eventcard/${eventCard.id}`;
      } else {
        provide = window.ethereum;
        const accounts = await provide.request({ method: "eth_accounts" });
        if (accounts.length === 0)
          await provide.request({ method: "eth_requestAccounts" });
        buyInUSDT(provide);
      }
    } else {
      if (_provide === "BKSWallet") {
        buyWithBKSWallet();
      } else {
        if (_provide === "Bitkeep" && window.isBitKeep) {
          provide = window.bitkeep.ethereum;
        } else if (_provide === "Metamask" && window.ethereum) {
          provide = window.ethereum;
        }

        if (provide === null) {
          addToast("You need to install " + _provide, {
            appearance: "warning",
            autoDismiss: true,
          });
          return;
        }

        const accounts = await provide.request({ method: "eth_accounts" });
        if (accounts.length === 0)
          await provide.request({ method: "eth_requestAccounts" });
        buyInUSDT(provide);
      }
    }
  };

  const buyWithBKSWallet = async () => {
    const price = calcPrice(eventCard.currency, eventCard.price);
    const data = {
      price,
      payees: eventCard.payees,
      owner_wallet: eventCard.owner_wallet
    }
    setModal({
      open: true,
      children: <PasswordModal data={data} handleBuyTicket={handleBuyTicket} />,
    });
  };

  const calcPrice = (currency, eventPrice) => {
    const _EURprice = ethers.BigNumber.from(
      Math.floor(rateEURvsUSD * 100000000)
    );
    const _INRprice = ethers.BigNumber.from(
      Math.floor(rateRUPEEvsUSD * 100000000)
    );
    const _TRYprice = ethers.BigNumber.from(
      Math.floor(rateLIRAvsUSD * 100000000)
    );
    const _GBPprice = ethers.BigNumber.from(
      Math.floor(rateGBPvsUSD * 100000000)
    );
    const _USDprice = ethers.BigNumber.from(100000000);
    const price = (currency === "USD"
      ? _USDprice
      : currency === "INR"
      ? _INRprice
      : currency === "EUR"
      ? _EURprice
      : currency === "TRY"
      ? _TRYprice
      : _GBPprice
    )
      .mul(ethers.BigNumber.from(ticketAmount))
      .mul(ethers.BigNumber.from(Math.floor(eventPrice * 1000)))
      .mul(ethers.BigNumber.from(10000000));
    return price;
  };

  const handleBuyTicket = async (orderid, _wallet, _chain) => {
    setLoading(true);
    const tokenObject = {
      name: eventCard?.name,
      description:
      eventCard.category !== "Category2"
          ? convertHtmlToString(eventCard.venue_description)
          : convertHtmlToString(eventCard.description),
      image: eventCard.picture_ipfs,
      attributes: [
        {
          trait_type: "Price",
          value: getEventPrice(eventCard).toFixed(2),
        },
        { trait_type: "Currency", value: currency },
        { trait_type: "Location", value: eventCard.location },
        { trait_type: "Date", value: eventCard.date },
        { trait_type: "Collection", value: eventCard.collection.name },
        {
          trait_type: "Addons",
          value: JSON.parse(eventCard.addons),
        },
      ],
    };
    const added = await client.add(JSON.stringify(tokenObject));
    const ipfs_url = `https://bkstage.infura-ipfs.io/ipfs/${added.path}`;
    const nftData = {
      contract: eventCard.NFT_address,
      IPFS_URL: ipfs_url,
      account: userInfo.user.wallet_address,
      picture_ipfs: eventCard.picture_ipfs,
    };
    const ticketData = {
      wallet_address: _wallet,
      blockchain: _chain,
      eventcard: eventCard.id,
      collection: eventCard.collection.id,
      price: eventCard.price * (tablePackage === 0 ? ticketAmount : tablePackage),
      pay_order_id: orderid,
      count: tablePackage === 0 ? ticketAmount : 1,
      buyer: userInfo.user.id,
      service_date: localStorage.getItem("service_date")
        ? new Date(localStorage.getItem("service_date"))
            .toString()
            .substring(0, 16)
        : null,
      seats: JSON.stringify(selectedSeats),
      discount: discount * 100,
    };
    const allData = {
      ticketData,
      nftData
    };
    buyTicket(allData)
      .then((res) => {
        localStorage.setItem("ticketId", res.ticket.id);
        if (res.success) {
          gaEventTracker(
            "purchased",
            "Purchased " + ticketAmount + " of ticket: " + eventCard.name
          );
          handleEnd();
        } else {
          setLoading(false);
          setModal({
            open: true,
            children: <PaymentFailedMoal />,
          });
        }
        localStorage.removeItem("other_website");
        setBuyTicketEvent(false);
      })
      .catch((error) => {
        addToast("failed", { appearance: "error", autoDismiss: true });
        setModal({ open: false });
        localStorage.removeItem("other_website");
        setBuyTicketEvent(false);
      });
  };

  const buyWithParam = () => {
    if (turkish(country)) {
      setModal({
        open: true,
        children: (
          <ParamModal
            eventCard={eventCard}
            amount={ticketAmount}
            handleTicket={handleTicket}
          />
        ),
      });
    } else {
      setModal({
        open: true,
        children: (
          <StripeElementModal
            eventCard={eventCard}
            amount={ticketAmount}
            handleTicket={handleTicket}
          />
        ),
      });
    }
  };

  return (
    <div className={styles.container}>
      <h4 className="modal__title">{t("proceed to Pay")}</h4>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.btn_close}
        onClick={() => setModal({ open: false })}
      >
        <path
          d="M18 6L6 18"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="order__summary">
        <p className="order__summary_title">{t("order summary")}</p>
        <div className="order__summary_item">
          <p className="order__summary_text">{eventCard.name}</p>
          <p className="order__summary_text">
            {Number(
              (
                convertedPrice * (tablePackage === 0 ? ticketAmount : tablePackage)
              ).toFixed(2)
            ).toString()}
            <FiatSymbol />
          </p>
        </div>
        <p className="order__summary_quantity">Quantity: {ticketAmount}</p>
        {!eventCard.tax_include && (
          <div className="order__summary_item">
            <p className="order__summary_text">Tax</p>
            <p className="order__summary_text">
              {Number(
                (
                  (convertedPrice *
                    eventCard.tax *
                    (tablePackage === 0 ? ticketAmount : tablePackage)) /
                  100
                ).toFixed(2)
              ).toString()}
              <FiatSymbol />
            </p>
          </div>
        )}
        <div className="divide"></div>
        <div className="order__summary_item">
          <p className="order__summary_text fontBold mb-0">
            Total
            {eventCard.tax_include && (
              <span className="text__small-time"> (INCL. TAX)</span>
            )}
          </p>
          <p className="order__summary_text fontBold mb-0">
            {eventCard.tax_include
              ? (
                  Number(convertedPrice) *
                  (tablePackage === 0 ? ticketAmount : tablePackage)
                ).toFixed(2)
              : (
                  Number((1 + eventCard.tax / 100) * convertedPrice) *
                  (tablePackage === 0 ? ticketAmount : tablePackage)
                ).toFixed(2)}
            <FiatSymbol />
          </p>
        </div>
      </div>
      <div className="order__summary terms__check">
        <div className="sign__group--checkbox">
          <input
            id="remember2"
            name="remember2"
            type="checkbox"
            checked={terms}
            onChange={() => setTerms(!terms)}
          />
          <label htmlFor="remember2" className="terms__text">
            I have read and agree with the&nbsp;
            <span
              className="terms_and_conditions"
              onClick={() =>
                setModal({
                  open: true,
                  children: (
                    <TermsConditionModal terms={eventCard.terms_conditions} />
                  ),
                })
              }
            >
              {t("terms and conditions")}
            </span>
            &nbsp;of this product
          </label>
        </div>
      </div>
      <button
        disabled={!terms}
        style={{ opacity: terms ? 1 : 0.3 }}
        className="asset__btn asset__btn--full asset__btn--clr open-modal"
        onClick={buyWithParam}
      >
        <LazyLoadImage
          alt="credit"
          src="/img/card/credit-card.png"
          style={{ marginRight: "14px" }}
        />
        {t("buy with credit card")}
      </button>
      {!turkish(country) &&
      (eventCard.collection.id !== "572b2ae7-a049-4a99-81c3-e38e935bf166" &&
        eventCard.collection.id !== "4a6412bd-96be-4524-9f00-529edae0ce22") ? (
        <div style={{ opacity: terms ? 1 : 0.3 }}>
          <p className={styles.text_or}>{t("or")}</p>

          <button
            className={styles.btn_crypto}
            onClick={() => buyWithUSDT("Metamask")}
          >
            <LazyLoadImage
              src="/img/metamask-seeklogo.com.svg"
              alt="metamask"
              height={30}
            />
          </button>

          <button
            className={styles.btn_crypto}
            onClick={() => buyWithUSDT("Bitkeep")}
          >
            <LazyLoadImage src="/img/bitkeep.svg" alt="bitkeep" width={100} />
          </button>

          <button
            className={styles.btn_crypto}
            onClick={() => buyWithUSDT("BKSWallet")}
          >
            <LazyLoadImage
              src="/img/logo-without-text.png"
              alt="logo"
              height={30}
              style={{ marginRight: "5px" }}
            />
            <span
              style={{
                marginLeft: 5,
              }}
            >
              BKS wallet
            </span>
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TicketBuyModal;
