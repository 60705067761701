// @ts-nocheck
import { ColDef, RowClassParams } from "ag-grid-community";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { allUsers } from "../../../../api/user";
import { IUser } from "../../../../utils/interface";
import { useUserContext } from "../../../../context/UserContext";
import { useAppContext } from "../../../../context/AppContext";
import Search from "../../../../components/Search";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const PageAdminUsers = () => {
  const { userInfo } = useUserContext();
  const [users, setUsers] = useState<IUser[]>([] as IUser[]);
  const [originUsers, setOriginUsers] = useState<IUser[]>([] as IUser[]);
  const navigate = useNavigate();
  const { setLoading } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    allUsers().then((res) => {
      if (res.success) {
        sortUsers(res.users);
        let _users: {
          Name: string;
          Email: string;
          Gender: string;
          Date: "string";
          Email_verified: boolean;
          Followers: number;
          "Wallet address": string;
        }[] = [];
        res.users.map((item: IUser[]) => {
          let user = {
            Name: item.name,
            Email: item.email,
            "Mobile Number": item.phone,
            Gender: item.gender,
            "Birth Year": item.date,
            Email_verified: item.email_verified ? "Yes" : "No",
            Followers: item.followers,
            "Wallet address": item.wallet_address,
          };
          _users.push(user);
          return _users;
        });
        setUsers(_users);
        setOriginUsers(_users);
      }
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortUsers = (array: never[]) => {
    setUsers(
      array.sort(
        (item1: any, item2: any) =>
          new Date(item2.createdAt).getTime() -
          new Date(item1.createdAt).getTime()
      )
    );
  };

  const createSuperUser = () => {
    navigate("/admin/users/create");
  };

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: t("name"), width: 170 },
      { field: t("email"), width: 240 },
      { field: t("phone"), width: 180 },
      { field: t("gender"), width: 120 },
      { field: t("birth year"), width: 140 },
      { field: t("email verified"), width: 180 },
      { field: t("followers"), width: 150 },
      { field: t("wallet address"), width: 450 },
    ],
    [t]
  );
  const getRowClass = (params: RowClassParams) => {
    if (params.rowIndex % 2 === 0) return "odd-row";
  };

  const searchUsers = (value) => {
    let res = originUsers;
    let searchArrays = value.split(" ");
    searchArrays.forEach((element) => {
      res = res.filter((user: any) =>
        element === ""
          ? user
          : user.Name.toLowerCase().includes(element.trim().toLowerCase()) ||
            user.Email.toLowerCase().includes(element.trim().toLowerCase())
      );
    });
    setUsers(res);
  };

  useEffect(() => {
    if (
      userInfo &&
      userInfo.user.user_type !== "ADMIN" &&
      userInfo.user.user_type !== "SUPER"
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo) {
      navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container users_container">
      <div className="see__users--header">
        <h1 className="see__users--title">{t("see all users")}</h1>
        <button
          type="button"
          className="sign__btn"
          onClick={createSuperUser}
          style={{ maxWidth: 340 }}
        >
          {t("create super user")}
        </button>
      </div>
      <div className="container__search">
        <Search
          onChange={(val) => searchUsers(val)}
          placeholder={t("search users")}
        />
      </div>
      <div className="ag-theme-alpine table-wrapper mb-5 users_table">
        <AgGridReact
          className="w-full h-full ag-grid-table"
          rowClass={["custom-row"]}
          rowData={users}
          headerHeight={60}
          rowHeight={60}
          debounceVerticalScrollbar
          columnDefs={columnDefs}
          getRowClass={getRowClass}
          suppressRowHoverHighlight={false}
        >
          <AgGridColumn
            headerName="NAME"
            field="Name"
            cellClass={["date-time"]}
          ></AgGridColumn>
          <AgGridColumn
            headerName="EMAIL"
            field="Email"
            cellClass={"color-type"}
          ></AgGridColumn>
          <AgGridColumn
            headerName="MOBILE NUMBER"
            field="Mobile Number"
            cellClass={"color-type"}
          ></AgGridColumn>
          <AgGridColumn
            headerName="GENDER"
            field="gender"
            cellClass={"color-type"}
          ></AgGridColumn>
          <AgGridColumn
            headerName="DATE"
            field="date"
            cellClass={"color-type"}
          ></AgGridColumn>
          <AgGridColumn
            headerName="Email_verified"
            field="Email_verified"
            cellClass={"color-type"}
          ></AgGridColumn>
          <AgGridColumn
            headerName="FOLLOWERS"
            field="Followers"
            cellClass={"color-type"}
          ></AgGridColumn>
          <AgGridColumn
            headerName="WALLET ADDRESS"
            field="Wallet address"
            cellClass={"color-type"}
          ></AgGridColumn>
        </AgGridReact>
      </div>
    </div>
  );
};

export default PageAdminUsers;
