/* eslint-disable array-callback-return */
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { deleteEventCardById } from "../../../api/event";
import { IEventCard } from "../../../utils/interface";
import Tile from "../../../components/tile";
import CarouselButton from "../../../components/CarouselButton";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./index.module.css";

const Lastevents = () => {
  const isMobile = useMediaQuery("(max-width: 450px)");
  const isTablet = useMediaQuery("(max-width: 800px)");
  const [latestEvents, setLatestEvents] = useState<IEventCard[]>(
    [] as IEventCard[]
  );
  const {
    carouselItem,
    allItems,
  } = useAppContext();
  const latestEventCarousel = useRef<typeof OwlCarousel>(null);
  const { t } = useTranslation();

  useEffect(() => {
    let temp: IEventCard[] = [];
    const d = new Date();
    temp = allItems.sort(
      (a: IEventCard, b: IEventCard) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    let shift = 0;
    temp.map((item: IEventCard) => {
      if (new Date(item.date).getTime() - d.getTime() < 0) {
        shift++;
      }
    });
    for (let index = 0; index < shift; index++) {
      let item = temp[0];
      temp.splice(0, 1);
      temp.push(item);
    }
    for (let index = 0; index < temp.length; index++) {
      if (temp[index].id === "e787b769-4fab-44f9-bdf1-3b793f1649d8") {
        const giveAway = temp[index];
        temp.splice(index, 1);
        temp.unshift(giveAway);
      }
    }
    setLatestEvents(
      temp.filter((eventcard: IEventCard) => eventcard.category === "Category1")
    );
  }, [allItems]);

  const handleDeleteEventCard = (eventCardId: string) => {
    deleteEventCardById(eventCardId)
      .then((res) => {
        if (res) {
          const updatedEvents = [...latestEvents].filter(
            (item: IEventCard) => item.id !== eventCardId
          );
          setLatestEvents(updatedEvents);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const titleHeader = () => {
    return (
      <div className="col-12" style={{ padding: 0 }}>
        <div className={styles.main__sub__title}>{t("latest events")}</div>
      </div>
    );
  };

  const next = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.next(500);
  };

  const prev = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.prev(500);
  };

  return latestEvents.length > 0 ? (
    <section className="row row--grid" style={{ margin: 0, padding: 0 }}>
      {titleHeader()}

      <div className="col-12 p__zero">
        <div className="carousel-wrapper">
          <div
            className="left__arrow_btn"
            onClick={() => prev(latestEventCarousel)}
          >
            <img src="/img/chevrons-left.svg" alt="left" />
          </div>
          <div
            className="right__arrow_btn"
            onClick={() => next(latestEventCarousel)}
          >
            <img src="/img/chevrons-right.svg" alt="right" />
          </div>
          <CarouselButton
            prev={() => prev(latestEventCarousel)}
            next={() => next(latestEventCarousel)}
          />
          <OwlCarousel
            className="owl-theme"
            margin={0}
            items={carouselItem}
            autoplay={true}
            lazyLoad={true}
            autoplayHoverPause={true}
            dots={isTablet ? false : true}
            dotsEach={latestEvents.length / 4}
            loop={latestEvents.length < 4 ? (isMobile ? true : false) : true}
            ref={latestEventCarousel as any}
          >
            {latestEvents.map((eventcard: IEventCard, i) => (
              <div key={`explorer_event_${i}`}>
                <Tile
                  eventCard={eventcard}
                  i={i}
                  deleteEventCard={handleDeleteEventCard}
                />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default Lastevents;
