import Countdown from "react-countdown";

const renderer = ({ days, hours, minutes, seconds, completed, size }) => {
  if (completed) {
    return <span>End</span>;
  } else {
    return (
      <span>
        {
            days !== 0? days+'d': (hours !== 0? hours+'h': minutes !== 0? minutes+'m': seconds !== 0? seconds+'s': '')
        }
      </span>
    );
  }
};

const CountTime = ({ date, time_zone, size = 'md' }) => {
  const d = new Date(date) - parseInt(time_zone) * 3600 * 1000;
  return <Countdown date={d} renderer={(props) => renderer({...props, size})} />;
};

export default CountTime;
