import { Suspense, useEffect } from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import TimeAgo from "javascript-time-ago";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { provider } from "web3-core";
import ReactGA from 'react-ga';
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";

import AppContextProvider from "./context/AppContext";
import UserContextProvider from "./context/UserContext";

import View from "./views";
import CookieConsent from "./components/cookieconsent";

import "./App.css";

// const TRACKING_ID = "UA-245593666-1";
// const TRACKING_ID = "G-NN5E1KCP4Z";
// ReactGA.initialize(TRACKING_ID);

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

function getLibrary(provider: provider) {
  return new Web3(provider);
}

function App() {
  useEffect(() => {
    document.title = "Liveticket";
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <div className="App">
      <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}
        placement="top-right"
      >
        <CookiesProvider>
          <AppContextProvider>
            <UserContextProvider>
              <Web3ReactProvider getLibrary={getLibrary}>
                <Suspense fallback={null}>
                  <BrowserRouter>
                    <View />
                  </BrowserRouter>
                  <CookieConsent />
                </Suspense>
              </Web3ReactProvider>
            </UserContextProvider>
          </AppContextProvider>
        </CookiesProvider>
      </ToastProvider>
    </div>
  );
}

export default App;
