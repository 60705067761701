import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

const PaymentSuccessMoal = () => {
  const { setModal } = useAppContext();
  const navigate = useNavigate();
  return (
    <div className="payment__success_container">
      <div>
        <img
          alt="payment success"
          style={style.success_img}
          src="/img/payment/check-circle.png"
        />
      </div>
      <p style={style.title}>Payment Successful!</p>
      <p style={style.info}>
        A confirmation email has been sent to your email address.
      </p>
      <div style={style.mint_container}>
        <img style={style.printer} alt="printer" src="/img/printer.svg" />
        <div style={style.mint_info}>
          <div style={style.print_text}>
            We’ve attached the printable PDF version of your ticket(s) to the
            confirmation email.
            <br />
            <br />
            You can also download it from the ‘’My Assets’’ page in your
            profile.
          </div>
        </div>
        <button
          onClick={() => {
            setModal({ open: false });
            navigate("/activity");
          }}
          style={style.mint_btn}
        >
          DOWNLOAD NOW
        </button>
      </div>
      <button onClick={() => setModal({ open: false })} style={style.close_btn}>
        DONE
      </button>
    </div>
  );
};

const style = {
  success_img: {
    width: "68px",
    height: "68px",
  },
  title: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    margin: "22px auto auto auto",
  },
  info: {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.03em",
    color: "rgba(255, 255, 255, 0.66)",
    margin: "10px auto 10px auto",
    maxWidth: 304,
  },
  mint_container: {
    width: "100%",
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "8px",
    padding: "18px 18px 20px 18px",
    marginTop: 16,
  },
  mint_info: {
    display: "flex",
  },
  print_text: {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    textAlign: "center",
    display: "inline",
    maxWidth: 355,
    margin: "0 auto 16px auto",
  },
  mint_btn: {
    border: "1px solid rgba(255, 255, 255, 0.33)",
    borderRadius: "4px",
    width: "100%",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.15em",
    padding: "12px 10px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
  },
  close_btn: {
    background: "#6A4DFD",
    borderRadius: "4px",
    padding: "14px 0",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.15em",
    color: "#FFFFFF",
    marginTop: "22px",
  },
  printer: {
    marginBottom: 12,
  },
};

export default PaymentSuccessMoal;
