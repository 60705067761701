import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import FilterItem from "./FilterItem";

const FilterSort = ({
  currentBtn,
  currentTag,
  sort,
  setCurrentBtn,
  setCurrentTag,
  setSort,
}) => {
  const isMobile = useMediaQuery("(max-width: 450px)");
  const { t } = useTranslation();
  const filterList = [
    "all",
    "festival",
    "concert",
    "club",
    "store",
    "meet & greet",
    "awards",
    "art",
    "sport",
    "hospitality",
    "platform",
    "experience",
    "show",
    "cirque",
  ];
  return (
    <div className="explorer__top-buttons">
      <div className="explorer__top-btn--group">
        <button
          className={
            currentBtn === "filter"
              ? "explorer__top-active--btn"
              : "explorer__top-inactive--btn"
          }
          onClick={() =>
            currentBtn === "" ? setCurrentBtn("filter") : setCurrentBtn("")
          }
        >
          <img src="/img/icons/filter.svg" alt="filter" />
          <p className="explorer__btn-text">{t("filter")}</p>
          {!isMobile && <img src="/img/icons/dropdown.svg" alt="dropdown" />}
        </button>
        {currentBtn === "filter" && (
          <div
            className={
              isMobile ? "explorer__top-btn-opts-mb" : "explorer__top-btn-opts"
            }
          >
            {filterList.map((item, index) => (
              <FilterItem
                value={item}
                key={index}
                currentTag={currentTag}
                setCurrentBtn={setCurrentBtn}
                setCurrentTag={setCurrentTag}
              />
            ))}
          </div>
        )}
      </div>
      <div className="explorer__top-btn--group">
        <button
          className={
            currentBtn === "sort"
              ? "explorer__top-active--btn"
              : "explorer__top-inactive--btn"
          }
          onClick={() =>
            currentBtn === "" ? setCurrentBtn("sort") : setCurrentBtn("")
          }
        >
          <img src="/img/icons/sort.svg" alt="sort" />
          <p className="explorer__btn-text">{t("sort")}</p>
          {!isMobile && <img src="/img/icons/dropdown.svg" alt="dropdown" />}
        </button>
        {currentBtn === "sort" && (
          <div className="explorer__top-btn-opts">
            <div className="explorer__top-checkbox">
              <input
                id="recent"
                name="recent"
                type="checkbox"
                checked={sort === 0}
                className="empty"
                onChange={() => {
                  setSort(0);
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="recent">{t("recently listed")}</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSort;
