import { Button, Fade, Paper, Popper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RedButton } from "./Buttons";

const RenderButton = withStyles({
  root: {
    color: "#fff",
    fontSize: "12px",
    padding: "5px 20px",
    backgroundColor: "#702FA0",
    borderRadius: "5px",
    border: "solid 1px #f0f0f0",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "#702FA0",
    },
    "&:focus": {
      outline: "none",
    },
  },
})(Button);

export default function DeletePopper(props) {
  const { onClickDelete } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.target);
  };

  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;

  return (
    <div
      onMouseLeave={() => {
        setAnchorEl(null);
      }}
    >
      <RenderButton
        aria-describedby={id}
        className="card__delete_btn"
        onClick={handleClick}
        large="false"
        small="true"
      >
        {t("delete")}
      </RenderButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top-end"
        transition
        disablePortal={false}
        style={{
          zIndex: 1000,
        }}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent",
          },
          hide: {
            enabled: false
          }
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              style={{
                padding: 12,
                width: 250,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography style={{ fontSize: 14, marginBottom: 12 }}>
                {t("do you really want to delete?")}
              </Typography>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    setAnchorEl(null);
                  }}
                >
                  {t("cancel")}
                </Button>
                <RedButton small="true" large="false" onClick={() => {
                  onClickDelete();
                  setAnchorEl(null);
                }  }>
                  {t("delete")}
                </RedButton>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
