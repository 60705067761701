import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import Search from "../../../components/Search";
import FaqItem from "../../../components/faq_item";
import Faqs from "../../../utils/faq";
import styles from "./index.module.css";

const PageFaq = () => {
  const [search, setSearch] = useState("");
  const faqs = useMemo(
    () =>
      search === ""
        ? Faqs
        : Faqs.filter(
            (item) =>
              item.answer.toLowerCase().includes(search.toLowerCase()) ||
              item.question.toLowerCase().includes(search.toLowerCase())
          ),
    [search]
  );
  const { t } = useTranslation();
  return (
    <div className={styles.faq__container}>
      <h1 className={styles.faq__title}>Frequently Asked Questions</h1>
      <p className={styles.faq__description}>
        Need something cleared up? Here are out most frequently asked questions
        and answers.
      </p>
      <div className={styles.faq__search_container}>
        <Search
          onChange={setSearch}
          placeholder={t("search questions and answers")}
        />
      </div>
      {faqs.map((item, index) => (
        <FaqItem
          key={index}
          answer={
            item?.answer_mobile && isMobile ? item.answer_mobile : item.answer
          }
          question={item.question}
        />
      ))}
      <div className={styles.faq__contact}>
        <p className={styles.faq__contact_text}>Still have questions?</p>
        <div className={styles.faq__contact_link}>
          <a href="https://linktr.ee/backstagecustomersupport">
            CONTACT SUPPORT
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageFaq;
