const TransactionTable = ({ transactions, page, count }) => {
  return (
    <table className="transaction_table">
      <thead>
        <tr>
          <th>Transaction Hash</th>
          <th>Block</th>
          <th>From</th>
          <th>To</th>
          <th>Date</th>
          <th>Value</th>
          <th className="transaction__last_col">Txn Fee</th>
        </tr>
      </thead>
      <tbody>
        {transactions?.map(
          (item, index) =>
            index >= (page - 1) * count &&
            index < page * count && (
              <tr className="transaction_row" key={index}>
                <td>{item?.hash.substring(0, 18)}...</td>
                <td>{item?.block_number}</td>
                <td>
                  {item?.from_address.substring(0, 8)}...
                  {item?.from_address.substring(
                    item?.from_address.length - 8,
                    item?.from_address.length
                  )}
                </td>
                <td>
                  {item?.to_address.substring(0, 8)}...
                  {item?.to_address.substring(
                    item?.to_address.length - 8,
                    item?.to_address.length
                  )}
                </td>
                <td>
                  {item?.block_timestamp.substring(0, 16).replace("T", " ")}
                </td>
                <td>{Number(item.value) / 10 ** 18} BNB</td>
                <td className="transaction__last_col">
                  {(Number(item.receipt_gas_used) * Number(item.gas_price)) /
                    10 ** 18}
                </td>
              </tr>
            )
        )}
      </tbody>
    </table>
  );
};

export default TransactionTable;
