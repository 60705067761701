import api from "./api";

const craftgatePaymentSuccess = (info) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/payment/craftgate-payment-success", info)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const stripePaymentIntent = (req) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/payment/stripe-payment-intent-test", req)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const stripePaymentCapture = (req) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/payment/stripe-payment-capture", req)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const stripePaymentConfirm = (req) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/payment/stripe-payment-confirm", req)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const cashPayment = (req) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/payment/cash-payment", req)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const createPaymentIntent = (req) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/payment/create-payment-intent", req)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const dropBox = (req) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/payment/drop-box", req)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export {
  craftgatePaymentSuccess,
  stripePaymentIntent,
  stripePaymentCapture,
  stripePaymentConfirm,
  cashPayment,
  dropBox,
  createPaymentIntent,
};
