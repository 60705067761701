import { useMemo } from "react";
import { useAppContext } from "../context/AppContext";

const FiatSymbol = ({currency = 'null'}) => {
  const { country } = useAppContext();
  const symbol = useMemo(() => {
    if (currency !== 'null') {
      switch (currency) {
        case 'USD':
          return '$';
        case 'GBP':
          return '£';
        case 'TRY':
          return '₺';
        case 'EUR':
          return '€';
        case 'INR':
          return '₹';
        default:
          break;
      }
    } else {
      switch (country) {
        case 'TR':
          return '₺';
        case 'GB':
          return '£';
        case 'US':
          return '$';
        case 'EU':
          return '€';
        case 'IN':
          return '₹';
        default:
          break;
      }
    }
  }, [country, currency]);
  return (
    <>
      {
        symbol
      }
    </>
  );
};

export default FiatSymbol;
