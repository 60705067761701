import React from "react";
import { useTranslation } from "react-i18next";

const ItemLinks = ({ validations, values, handleChange }) => {
  const { t } = useTranslation();
  const borderStyle = (str) => {
    return str === "has-empty"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };
  return (
    <div className="cr__evcard-subItem" style={{ paddingBottom: 0 }}>
      <h4 className="cr__evcard--subtitle">
        <span></span>
        {t("links")}
      </h4>
      <div className="cr__evcard-rowParent">
        <div className="sign__group cr__evcard-50">
          <p className="cr__evcard-labelTitle">Facebook</p>
          <input
            id="facebook"
            type="text"
            name="facebook"
            className="sign__input"
            value={values.facebook}
            onChange={(e) => handleChange("facebook", e.target.value)}
          />
        </div>
        <div className="sign__group cr__evcard-50">
          <p className="cr__evcard-labelTitle">Twitter</p>
          <input
            id="twitter"
            type="text"
            name="twitter"
            className="sign__input"
            value={values.twitter}
            onChange={(e) => handleChange("twitter", e.target.value)}
          />
        </div>
      </div>
      <div className="cr__evcard-rowParent">
        <div className="sign__group cr__evcard-50">
          <p className="cr__evcard-labelTitle">Instagram</p>
          <input
            id="instagram"
            type="text"
            name="instagram"
            className="sign__input"
            value={values.instagram}
            onChange={(e) => handleChange("instagram", e.target.value)}
          />
        </div>
        <div className="sign__group cr__evcard-50">
          <p className="cr__evcard-labelTitle">TikTok</p>
          <input
            id="tiktok"
            type="text"
            name="tiktok"
            className="sign__input"
            value={values.tiktok}
            onChange={(e) => handleChange("tiktok", e.target.value)}
          />
        </div>
      </div>
      <div className="cr__evcard-rowParent">
        <div className="sign__group cr__evcard-50">
          <p className="cr__evcard-labelTitle">Telegram</p>
          <input
            id="telegram"
            type="text"
            name="telegram"
            className="sign__input"
            value={values.telegram}
            onChange={(e) => handleChange("telegram", e.target.value)}
          />
        </div>
        <div className="sign__group cr__evcard-50">
          <p className="cr__evcard-labelTitle">Website</p>
          <input
            id="website"
            type="text"
            name="website"
            className="sign__input"
            value={values.website}
            onChange={(e) => handleChange("website", e.target.value)}
          />
        </div>
      </div>
      <div className="cr__evcard-rowParent">
        <div className="sign__group cr__evcard-50">
          <p className="cr__evcard-labelTitle">Email</p>
          <input
            id="email"
            type="text"
            name="email"
            className="sign__input"
            value={values.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
        </div>
      </div>
      <div className="cr__evcard-rowParent">
        <div className="sign__group cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("BSC wallet owner")}</p>
          <input
            id="owner_wallet"
            type="text"
            name="owner_wallet"
            className="sign__input"
            value={values.owner_wallet}
            onChange={(e) => handleChange("owner_wallet", e.target.value)}
            style={borderStyle(validations.owner_wallet)}
          />

          {validations.owner_wallet === "has-empty" && (
            <span className="text-error">
              {t("correct BSC wallet address required")}*
            </span>
          )}
        </div>
        <div className="sign__group cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("NEAR Wallet Owner")}</p>
          <input
            id="owner_near_wallet"
            type="text"
            name="owner_near_wallet"
            className="sign__input"
            value={values.owner_account}
            onChange={(e) => handleChange("owner_account", e.target.value)}
            style={borderStyle(validations.owner_account)}
          />

          {validations.owner_account === "has-empty" && (
            <span className="text-error">
              {t("correct NEAR wallet address required")}*
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemLinks;
