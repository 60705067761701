import { useEffect, useState, useRef, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";

import CountrySelect from "../../CountrySelect";

import useConvertedFiat from "../../../hooks/useConvertedFiat";
import useFiatSymbol from "../../../hooks/useFiatSymbol";
import { turkish } from "../../../utils/location";
import { getEventPrice } from "../../../utils";
import { convertHtmlToString } from "../../../utils/convertHtmlToString";

import styles from "./index.module.css";

const ParamModal = ({ eventCard, amount, handleTicket }) => {
  console.log("param");
  const {
    setModal,
    setLoading,
    country,
    selectedSeats,
    discount,
    tablePackage,
  } = useAppContext();
  const cardForm = useRef(null);
  const { userInfo } = useUserContext();
  const [wallet] = useState(userInfo.user.wallet_address);
  const [values, setValues] = useState({
    name: "",
    cardNumber: "",
    cardAddress: "",
    expDate: "",
    CVC: "",
    governmentId: "",
    street: "",
    city: "",
    zip_code: "",
    country: "",
  });
  const initialValidation = {
    name: true,
    cardNumber: true,
    cardAddress: true,
    expDate: true,
    CVC: true,
    governmentId: true,
    street: true,
    city: true,
    zip_code: true,
    country: true,
  };
  const [validations, setValidations] = useState(initialValidation);
  const currency = useMemo(() => {
    switch (country) {
      case "TR":
        return "₺";
      case "GB":
        return "£";
      case "US":
        return "$";
      case "IN":
        return "₹";
      default:
        break;
    }
  }, [country]);
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 576px)");
  const convertedPrice = useConvertedFiat(
    eventCard.price,
    eventCard.currency,
    country
  );

  const handleChange = (prop, value) => {
    setValidations((prevState) => ({ ...prevState, [prop]: true }));
    setValues({ ...values, [prop]: value });
  };

  const checkValidation = () => {
    if (values.street === "") {
      setValidations({ ...initialValidation, street: false });
      return false;
    } else if (values.city === "") {
      setValidations({ ...initialValidation, city: false });
      return false;
    } else if (values.zip_code === "") {
      setValidations({ ...initialValidation, zip_code: false });
      return false;
    } else if (values.country === "") {
      setValidations({ ...initialValidation, country: false });
      return false;
    } else if (!values.name.match(/^[A-Za-z ]+$/) || values.name.length === 0) {
      setValidations({ ...initialValidation, name: false });
      return false;
    } else if (
      !values.cardNumber.replace(/\s/g, "").match(/^[0-9]+$/) ||
      values.cardNumber.length !== 19
    ) {
      setValidations({ ...initialValidation, cardNumber: false });
      return false;
    } else if (values.expDate.length !== 5) {
      setValidations({ ...initialValidation, expDate: false });
      return false;
    } else if (!values.CVC.match(/^[0-9]+$/) || values.CVC.length !== 3) {
      setValidations({ ...initialValidation, CVC: false });
      return false;
    } else {
      return true;
    }
  };

  const cardSubmit = (e) => {
    e.preventDefault();
    if (!checkValidation()) return;
    localStorage.removeItem("service_date");
    setLoading(true);
    cardForm.current.submit();
  };

  const setExpectionDate = (e) => {
    const temp = formatExpireDate(e.target.value);
    handleChange("expDate", temp);
  };

  const setCardNum = (e) => {
    const temp = formatCardNumber(e.target.value);
    handleChange("cardNumber", temp);
  };

  const formatExpireDate = (date) => {
    const cleaned = `${date}`.replace(/\D/g, "").substring(0, 4);
    const firstCode = cleaned.substring(0, 2);
    const secondCode = cleaned.substring(2, 4);

    if (cleaned.length > 2) {
      return `${firstCode}/${secondCode}`;
    }
    if (cleaned.length > 0) {
      return `${firstCode}`;
    }
    return "";
  };

  const formatCardNumber = (cardNumberString) => {
    const cleaned = `${cardNumberString}`.replace(/\D/g, "").substring(0, 16);
    const firstCode = cleaned.substring(0, 4);
    const secondCode = cleaned.substring(4, 8);
    const thirdCode = cleaned.substring(8, 12);
    const last = cleaned.substring(12, 16);

    if (cleaned.length > 12) {
      return `${firstCode} ${secondCode} ${thirdCode} ${last}`;
    }
    if (cleaned.length > 8) {
      return `${firstCode} ${secondCode} ${thirdCode}`;
    }
    if (cleaned.length > 4) {
      return `${firstCode} ${secondCode}`;
    }
    if (cleaned.length > 0) {
      return `${firstCode}`;
    }
    return "";
  };

  const CURRENCY = {
    TR: "TRY",
    US: "USD",
    IN: "INR",
    GB: "GBP",
  };

  const totalCurrencyPrice =
    (
      convertedPrice *
      (1 + (eventCard.tax_include ? 0 : eventCard.tax / 100)) *
      (tablePackage === 0 ? amount : tablePackage)
    ).toFixed(2) + useFiatSymbol(country);

  const ticketCurrencyPrice =
    (
      convertedPrice *
      (1 + (eventCard.tax_include ? 0 : eventCard.tax / 100))
    ).toFixed(2) + useFiatSymbol(country);
  const PayPrice =
    useFiatSymbol(country) +
    (
      convertedPrice *
      (1 + (eventCard.tax_include ? 0 : eventCard.tax / 100)) *
      (tablePackage === 0 ? amount : tablePackage)
    ).toFixed(2);

  const emailData = {
    mobile: isMobile,
    email: userInfo.user.email,
    ticket_number: Number(amount),
    user_name: userInfo.user.name,
    totalPrice: totalCurrencyPrice,
    ticketPrice: ticketCurrencyPrice,
    collection_name: eventCard.collection.name,
    scan: eventCard.scan,
    ticket_type: eventCard.collection.category,
    item: eventCard,
    addons: JSON.parse(eventCard.addons),
    start_now:
      eventCard.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
    date: new Date(localStorage.getItem("service_date") || eventCard.date)
      .toString()
      .substring(0, 21),
    end_date: new Date(
      localStorage.getItem("service_date") || eventCard.end_date
    )
      .toString()
      .substring(0, 21),
  };
  const ticketData = {
    wallet_address: wallet,
    blockchain: "Binance Smart Chain",
    buyer: userInfo.user.id,
    eventcard: eventCard.id,
    collection: eventCard.collection.id,
    price: eventCard.price * (tablePackage === 0 ? amount : tablePackage),
    pay_order_id: "craftgate payment",
    count: (tablePackage === 0 ? amount : 1),
    card_address: "",
    service_date: localStorage.getItem("service_date")
      ? new Date(localStorage.getItem("service_date"))
        .toString()
        .substring(0, 16)
      : null,
    seats: JSON.stringify(selectedSeats),
    discount: discount * 100,
  };

  const tokenObject = {
    name: eventCard?.name,
    description:
      eventCard.category !== "Category2"
        ? convertHtmlToString(eventCard.venue_description)
        : convertHtmlToString(eventCard.description),
    image: eventCard.picture_ipfs,
    attributes: [
      {
        trait_type: "Price",
        value: getEventPrice(eventCard).toFixed(2),
      },
      { trait_type: "Currency", value: currency },
      { trait_type: "Location", value: eventCard.location },
      { trait_type: "Date", value: eventCard.date },
      { trait_type: "Collection", value: eventCard.collection.name },
      {
        trait_type: "Addons",
        value: JSON.parse(eventCard.addons),
      },
    ],
  };

  const dataObject = {
    event: {
      id: eventCard?.id || "",
      name: eventCard?.name || "",
      price:
        eventCard.tax_include
          ? parseInt(
            convertedPrice * 100 * amount
          )
          : parseInt(
            (convertedPrice * 100 +
              convertedPrice *
              eventCard.tax) * amount
          ),
    },
    card: {
      cardHolderName: values.name || "",
      cardAddress: values.cardAddress,
      cardNumber: values.cardNumber.replace(/\s/g, "") || "",
      expireMonth: values.expDate?.split("/")[0] || "",
      expireYear: values.expDate?.split("/")[1] || "",
      cvc: values.CVC || 0,
    },
    currency: CURRENCY[country] || "EUR",
    governmentId: values.governmentId,
    cardAddress: values.cardAddress,
    emailData,
    ticketData,
    other_website: localStorage.getItem("other_website"),
    return_url: window.location.href,
    tokenObject,
    userWallet: userInfo.user.wallet_address,
    contractAddress: eventCard.NFT_address,
    picture_ipfs: eventCard.picture_ipfs
  };

  const borderStyle = (validation) => {
    return !validation
      ? {
        border: "#FF4E4E 1px solid",
      }
      : {};
  };

  useEffect(() => {
    localStorage.setItem("ccObject", JSON.stringify(dataObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <form
      method="post"
      action={`${process.env.REACT_APP_API_BASE_URL}/api/payment/craftgate`}
      className={styles.container}
      ref={cardForm}
    >
      <input
        type="hidden"
        name="data"
        value={JSON.stringify({ ...dataObject })}
      />
      <div className={styles.top}>
        <div className={styles.back} onClick={handleTicket}>
          <svg
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 13L1 7L7 1"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className={styles.back_text}>BACK</span>
        </div>
        <div
          className={styles.btn_close}
          onClick={() => setModal({ open: false })}
        >
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="#ffffff"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
          </svg>
        </div>
      </div>
      <div className={styles.title}>{t("Pay with Credit Card")}</div>
      <div className={styles.wrapper}>
        <div className={styles.input_wrapper_full}>
          {t("Billing Address")}
          <input
            value={values.street}
            onChange={(e) => handleChange("street", e.target.value)}
            style={borderStyle(validations.street)}
            placeholder={t("street address")}
          />
          {!validations.street && (
            <p className={styles.warning_text}>{t("Invalid street")}</p>
          )}
        </div>

        <div className={styles.input_wrapper_half}>
          <input
            value={values.city}
            onChange={(e) => handleChange("city", e.target.value)}
            style={borderStyle(validations.city)}
            placeholder={t("city")}
          />
          {!validations.city && (
            <p className={styles.warning_text}>{t("Invalid city")}</p>
          )}
        </div>

        <div className={styles.input_wrapper_half}>
          <input
            value={values.zip_code}
            onChange={(e) => handleChange("zip_code", e.target.value)}
            style={borderStyle(validations.zip_code)}
            placeholder={t("postal zip")}
          />
          {!validations.zip_code && (
            <p className={styles.warning_text}>
              {t("Invalid Postal/ZIP code")}
            </p>
          )}
        </div>

        <div className={styles.input_wrapper_full}>
          <CountrySelect
            handleChange={handleChange}
            validations={validations}
          />
        </div>
        {!validations.country && (
          <p className={styles.warning_text}>{t("Invalid country")}</p>
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.input_wrapper_full}>
          {t("Name On Card")}
          <input
            value={values.name}
            onChange={(e) => handleChange("name", e.target.value)}
            style={borderStyle(validations.name)}
            placeholder={t("Enter cardholder's name")}
          />
        </div>
        {!validations.name && (
          <p className={styles.warning_text}>{t("Invalid card name")}</p>
        )}
        {turkish(country) && (
          <div className={styles.input_wrapper_full}>
            {t("Government Id Number")}
            <input
              value={values.governmentId}
              onChange={(e) => handleChange("governmentId", e.target.value)}
              style={borderStyle(validations.governmentId)}
              placeholder={t("Input government id number")}
            />
          </div>
        )}

        {!validations.governmentId && (
          <p className={styles.warning_text}>{t("Invalid government Id")}</p>
        )}
        <div className={styles.input_wrapper_full}>
          {t("Card Number")}
          <input
            maxLength={19}
            className={styles.cc_number}
            style={borderStyle(validations.cardNumber)}
            placeholder="0000 0000 0000 0000"
            autocompletetype="cc-number"
            type="tel"
            value={values.cardNumber}
            onChange={setCardNum}
          />
          <img
            alt="credit card"
            src="/img/card/credit-card.png"
            className={styles.credit_card}
          />
        </div>
        {!validations.cardNumber && (
          <p className={styles.warning_text}>{t("Invalid card number")}</p>
        )}
        <div className={styles.input_wrapper_half}>
          {t("Expiration Date")}
          <input
            value={values.expDate}
            style={borderStyle(validations.expDate)}
            maxLength="5"
            onChange={setExpectionDate}
            placeholder="MM/YY"
          />
          {!validations.expDate && (
            <p className={styles.warning_text}>
              {t("Invalid expiration date")}
            </p>
          )}
        </div>

        <div className={styles.input_wrapper_half}>
          CVC/CVV{" "}
          <input
            value={values.CVC}
            maxLength="3"
            style={borderStyle(validations.CVC)}
            onChange={(e) => handleChange("CVC", e.target.value)}
            placeholder="000"
          />
          {!validations.CVC && (
            <p className={styles.warning_text}>{t("Invalid CVC")}</p>
          )}
        </div>
      </div>
      <button
        className="asset__btn asset__btn--full asset__btn--clr open-modal"
        onClick={cardSubmit}
        type="button"
      >
        {t("Pay") + " " + PayPrice}
      </button>
    </form>
  );
};

export default ParamModal;
