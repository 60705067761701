/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { Routes, Route, Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import Web3 from "web3";
import { ethers } from "ethers";
import Overview from "./overview";
import Currencies from "./currencies";
import DigitalItems from "./digitalItems";
import SendReceive from "./sendRecevie";
import Transactions from "./transactions";
import Offers from "./offers";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { getTicketsFromNFTs } from "../../../api/event";
import { getUserToken } from "../../../api/token";
import { ITicket, IToken } from "../../../utils/interface";
import { bnbPriceABI, bnbPriceAddress } from "../../../utils/nft_contract";
import { isWalletValid } from "../../../utils";
import { defaultTokens } from "../../../utils/default_tokens";
import styles from "./index.module.css";

const PageWallet = () => {
  const location = useLocation();
  const {
    setLoading,
    setDigitalItems,
    setTokens,
    setBalance,
    setBnbPrice,
    setTransactions,
    setErc20Transactions,
    setErc721Transactions,
  } = useAppContext();
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const wallet = useMemo(() => {
    return userInfo?.user?.wallet_address;
  }, [userInfo]);
  const path = location.pathname;
  const fetchNfts = async () => {
    axios
      .get(
        "https://deep-index.moralis.io/api/v2/" +
          wallet +
          "/nft?chain=bsc&format=decimal",
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": process.env.REACT_APP_MORALIS as string,
          },
        }
      )
      .then(async (response) => {
        console.log(response.data.result);
        let tokenURLs = [];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          tokenURLs.push(
            "https://bscscan.com/token/" +
              element.token_address +
              "?a=" +
              element.token_id
          );
        }
        const res = await getTicketsFromNFTs({ tokenURLs });
        sortTickets(res.tickets);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const sortTickets = (array: []) => {
    setDigitalItems(
      array.sort(
        (item1: ITicket, item2: ITicket) =>
          new Date(item2.createdAt).getTime() -
          new Date(item1.createdAt).getTime()
      )
    );
  };

  const getAllTokens = async () => {
    setLoading(true);
    const userTokens = await getUserToken({ userId: userInfo?.user?.id });
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = wallet;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.token.getWalletTokenBalances({
      address,
      chain,
    });
    const data = response.toJSON();
    const promises = data.map((item) => {
      if (!item.possible_spam) {
        return new Promise((resolve, reject) => {
          const res = Moralis.EvmApi.token.getTokenPrice({
            address: item.token_address,
            chain: EvmChain.BSC,
          });
          resolve(res);
        });
      } else {
        return item;
      }
    });
    let tokenData: IToken[] = [];
    await Promise.all(promises)
      .then((results) => {
        console.log("All promises resolved:", results);
        results.forEach((element: any, index: number) => {
          tokenData.push({
            ...data[index],
            balance: Number(
              (
                Number(data[index].balance) /
                10 ** data[index].decimals
              ).toFixed(6)
            ),
            price: !element?.balance ? element?.toJSON()?.usdPrice : 0,
          });
        });
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });

    const allTokens = defaultTokens.concat(userTokens);
    const balancedUserTokens = allTokens.map((item1: any) => {
      const matchingItem = tokenData.find(
        (item2) =>
          item2.token_address.toLowerCase() ===
          item1.token_address.toLowerCase()
      );
      return {
        ...item1,
        balance: matchingItem ? matchingItem?.balance : 0,
        price: matchingItem ? matchingItem?.price : 0,
      };
    });
    setTokens(balancedUserTokens);
    setLoading(false);
  };

  const fetchBalance = async () => {
    if (wallet) {
      const web3 = new Web3("https://bsc-dataseed.binance.org/");
      const balanceAmount = await web3.eth.getBalance(
        userInfo.user.wallet_address
      );
      setBalance(Number(BigInt(balanceAmount)) / 1000000000000000000);
      const provider = new ethers.providers.JsonRpcProvider(
        "https://bsc-dataseed.binance.org"
      );
      const bnbPriceContract = new ethers.Contract(
        bnbPriceAddress,
        bnbPriceABI,
        provider
      );
      const bnbPrice = await bnbPriceContract.latestAnswer();
      setBnbPrice(bnbPrice.toNumber() / 100000000);
    }
  };

  const getTransactions = async () => {
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = wallet;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.transaction.getWalletTransactionsVerbose(
      {
        address,
        chain,
      }
    );
    const result = response.toJSON().result;
    setTransactions(result);
  };

  const getERC20Transfer = async () => {
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = wallet;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.token.getWalletTokenTransfers({
      address,
      chain,
    });

    const result = response.toJSON().result;
    setErc20Transactions(result);
  };

  const getERC721Transfer = async () => {
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = wallet;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.nft.getWalletNFTTransfers({
      address,
      chain,
    });

    const result = response.toJSON().result;
    setErc721Transactions(result);
  };

  useEffect(() => {
    if (isWalletValid(wallet)) {
      fetchNfts();
      getAllTokens();
      fetchBalance();
      getTransactions();
      getERC20Transfer();
      getERC721Transfer();
    }
  }, [wallet]);
  useEffect(() => {
    if (!userInfo) {
      navigate("/signin");
    }
  }, []);
  return (
    <div className="container wallet__container">
      <div className={styles.side_menu}>
        <p className={styles.assets_title}>My Assets</p>
        <Link to="/activity">
          <div
            className={
              path === "/activity"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/overview.svg"
              className={styles.assets__menu_img}
              alt="overview"
            />
            <p className={styles.menu__item_text}>Overview</p>
          </div>
        </Link>
        <Link to="/activity/currencies">
          <div
            className={
              path === "/activity/currencies"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/currencies.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>Currencies</p>
          </div>
        </Link>
        <Link to="/activity/digital_items">
          <div
            className={
              path === "/activity/digital_items"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/NFTs.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>Digital Items</p>
          </div>
        </Link>
        <Link to="/activity/send_receive">
          <div
            className={
              path === "/activity/send_receive"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/send.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>Send & Receive</p>
          </div>
        </Link>
        <Link to="/activity/transaction">
          <div
            className={
              path === "/activity/transaction"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/transaction.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>Transactions</p>
          </div>
        </Link>
        <Link to="/activity/offers">
          <div
            className={
              path === "/activity/offers"
                ? styles.assets__menu_item_active
                : styles.assets__menu_item
            }
          >
            <img
              src="/img/wallet/offers.svg"
              className={styles.assets__menu_img}
              alt="currencies"
            />
            <p className={styles.menu__item_text}>Offers</p>
            <div className={styles.red_offer}>
              <span>0</span>
            </div>
          </div>
        </Link>
      </div>
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="currencies" element={<Currencies />} />
        <Route path="digital_items" element={<DigitalItems />} />
        <Route path="send_receive" element={<SendReceive />} />
        <Route path="transaction" element={<Transactions />} />
        <Route path="offers" element={<Offers />} />
        <Route path="*" element={<Outlet />} />
      </Routes>
    </div>
  );
};

export default PageWallet;
