/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useCookies } from "react-cookie";
import { isValidPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input/input";

import ItemSelectModal from "../../../components/custom_modals/item_select_modal";
import PhoneInput from "../../../components/PhoneInput";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import {
  changePassword,
  changeSocial,
  getAllUserBackgrounds,
} from "../../../api/user";
import { updateProfile } from "../../../api/auth";

type IWallet = {
  wallet: string;
  walletNear: string;
};

const Profile = ({ wallet, walletNear }: IWallet) => {
  const { userInfo, setUserInfo } = useUserContext();
  const [tapState, setTapState] = useState("general");
  const [imagePreview, setImagePreview] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [userBackgrounds, setUserBackgrounds] = useState<string[]>(
    [] as string[]
  );
  const isMobile = useMediaQuery("(max-width: 450px)");
  const { setLoading, setModal, addToast } = useAppContext();
  const [cookies, setCookie] = useCookies();
  const { t } = useTranslation();

  useEffect(() => {
    getAllUserBackgrounds().then((res) => {
      if (res.success) {
        setUserBackgrounds(res.userbackgrounds);
      }
    });
  }, []);

  const set_Icon = (icon_src: string) => {
    setImagePreview(icon_src);
    setIsImageChanged(true);
    handleGeneralChange("img", icon_src);
  };

  const [generalValidations, setGeneralValidations] = useState({
    name: "",
    email: "",
    mobile: "",
    website: "",
    bsc: "",
    img: "",
    description: "",
  });

  const initalGeneralValidations = {
    name: "",
    email: "",
    mobile: "",
    website: "",
    bsc: "",
    img: "",
    description: "",
  };

  const [generalValues, setGeneralValues] = useState({
    name: userInfo?.user?.name || "",
    email: userInfo?.user?.email || "",
    mobile: userInfo?.user?.phone || "",
    website: userInfo?.user?.website || "",
    bsc: userInfo?.user?.wallet_address || "",
    img: userInfo?.user?.background || "",
    description: userInfo?.user?.description || "",
  });

  const [isGeneralChanged, setIsGeneralChanged] = useState(false);

  const handleGeneralChange = (prop: string, value: string) => {
    setIsGeneralChanged(true);
    setGeneralValidations(initalGeneralValidations);
    setGeneralValues({ ...generalValues, [prop]: value });
  };

  const ValidateEmail = (input: string) => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const checkGeneralValidations = () => {
    if (generalValues.name === "") {
      setGeneralValidations({
        ...initalGeneralValidations,
        name: "has-empty",
      });
      return false;
    } else if (!ValidateEmail(generalValues.email)) {
      setGeneralValidations({
        ...initalGeneralValidations,
        email: "has-empty",
      });
      return false;
    } else if (generalValues.mobile === "") {
      setGeneralValidations({
        ...initalGeneralValidations,
        mobile: "has-empty",
      });
      return false;
    } else if (!isValidPhoneNumber(generalValues.mobile)) {
      setGeneralValidations({
        ...initalGeneralValidations,
        mobile: "has-error",
      });
      return false;
    } else {
      setGeneralValidations(initalGeneralValidations);
    }
    return true;
  };

  const handleChangeGeneral = () => {
    if (!checkGeneralValidations()) return;

    updateProfile({
      name: generalValues.name,
      email: generalValues.email,
      phone: generalValues.mobile,
      wallet_address: generalValues.bsc,
      background: generalValues.img,
      facebook: userInfo?.user?.facebook,
      instagram: userInfo?.user?.instagram,
      twitter: userInfo?.user?.twitter,
      medium: userInfo?.user?.medium,
      wallet_address_near: userInfo?.user?.wallet_address_near,
      website: generalValues.website,
      description: generalValues.description,
    })
      .then((res) => {
        setLoading(false);

        if (res.success) {
          addToast("Saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          setCookie("userInfo", res.data);
          setUserInfo(res.data);
          setIsGeneralChanged(false);
        } else {
          const message = res.message ? res.message : "failed";
          addToast(message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
  };

  const [socialValidations, setSocialValidations] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    medium: "",
  });

  const initalSocialValidations = {
    facebook: "",
    twitter: "",
    instagram: "",
    medium: "",
  };

  const [socialValues, setSocialValues] = useState({
    facebook: userInfo?.user?.facebook || "",
    twitter: userInfo?.user?.twitter || "",
    instagram: userInfo?.user?.instagram || "",
    medium: userInfo?.user?.medium || "",
  });

  const [isSocialChanged, setIsSocialChanged] = useState(false);

  const checkSocialValidation = () => {
    if (
      socialValues.facebook !== "" &&
      !socialValues.facebook.includes("facebook.com/")
    ) {
      setSocialValidations({
        ...initalSocialValidations,
        facebook: "has-empty",
      });
      return false;
    } else if (
      socialValues.twitter !== "" &&
      !socialValues.twitter.includes("twitter.com/")
    ) {
      setSocialValidations({
        ...initalSocialValidations,
        twitter: "has-empty",
      });
      return false;
    } else if (
      socialValues.instagram !== "" &&
      !socialValues.instagram.includes("instagram.com/")
    ) {
      setSocialValidations({
        ...initalSocialValidations,
        instagram: "has-empty",
      });
      return false;
    } else if (
      socialValues.medium !== "" &&
      !socialValues.medium.includes("medium.com/")
    ) {
      setSocialValidations({
        ...initalSocialValidations,
        medium: "has-empty",
      });
      return false;
    } else {
      setSocialValidations(initalSocialValidations);
    }
    return true;
  };

  const borderStyle = (str: string) => {
    return str === "has-empty" || str === "has-danger" || str === "has-error"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  const handleSocialChange = (prop: string, value: string) => {
    setIsSocialChanged(true);
    setSocialValidations({ ...initalSocialValidations });
    setSocialValues({ ...socialValues, [prop]: value });
  };

  const handleChangeSocial = () => {
    if (!checkSocialValidation()) return;

    setLoading(true);

    changeSocial(socialValues)
      .then((res) => {
        setLoading(false);

        if (res.success) {
          addToast("Saved successfully", {
            appearance: "success",
            autoDismiss: true,
          });
          setIsSocialChanged(false);
          setCookie("userInfo", { ...userInfo, user: res.user });
        } else {
          const message = res.message ? res.message : "failed";
          addToast(message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
  };

  const [pwdValidations, setPwdValidations] = useState({
    oldPwd: "",
    newPwd: "",
    confirmPwd: "",
  });

  const initalPwdValidations = {
    oldPwd: "",
    newPwd: "",
    confirmPwd: "",
  };

  const [pwdValues, setPwdValues] = useState({
    oldPwd: "",
    newPwd: "",
    confirmPwd: "",
  });

  useEffect(() => {
    setGeneralValues({
      name: userInfo?.user?.name || "",
      email: userInfo?.user?.email || "",
      mobile: userInfo?.user?.phone || "",
      website: userInfo?.user?.website || "",
      bsc: userInfo?.user?.wallet_address || "",
      img: userInfo?.user?.background || "",
      description: userInfo?.user?.description || "",
    });
    setSocialValues({
      facebook: userInfo?.user?.facebook || "",
      twitter: userInfo?.user?.twitter || "",
      instagram: userInfo?.user?.instagram || "",
      medium: userInfo?.user?.medium || "",
    });
  }, [userInfo]);

  const handlePwdChange = (prop: string, value: string) => {
    setPwdValidations(initalPwdValidations);
    setPwdValues({ ...pwdValues, [prop]: value });
  };

  const isPasswordStrong = () => {
    const str = pwdValues.newPwd;
    if (str.length < 8) return;
    const len = str.length;
    let uppercase = 0;
    let lowercase = 0;
    let numbers = 0;
    let specials = 0;
    for (let i = 0; i < len; i++) {
      if (str[i] >= "A" && str[i] <= "Z") uppercase++;
      else if (str[i] >= "a" && str[i] <= "z") lowercase++;
      else if (str[i] >= "0" && str[i] <= "9") numbers++;
      else specials++;
    }
    return uppercase && lowercase && numbers && specials;
  };

  const checkPwdValidations = () => {
    if (pwdValues.oldPwd === "") {
      setPwdValidations({ ...initalPwdValidations, oldPwd: "has-empty" });
      return false;
    } else if (!isPasswordStrong()) {
      addToast(
        "Password should be 8+ characters and combined with uppercase, lowercase, special character and numbers.",
        { appearance: "error", autoDismiss: true }
      );
      setPwdValidations({ ...initalPwdValidations, newPwd: "has-empty" });
      return false;
    } else if (pwdValues.newPwd !== pwdValues.confirmPwd) {
      setPwdValidations({ ...initalPwdValidations, confirmPwd: "has-empty" });
      return false;
    } else {
      setPwdValidations(initalPwdValidations);
    }
    return true;
  };

  const handleChangePassword = () => {
    if (!checkPwdValidations()) return;

    setLoading(true);

    changePassword({
      old_password: pwdValues.oldPwd,
      new_password: pwdValues.newPwd,
    })
      .then((res) => {
        setLoading(false);

        if (res.success) {
          addToast("Password changed", {
            appearance: "success",
            autoDismiss: true,
          });
        } else {
          const message = res.message ? res.message : "failed";
          addToast(message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
  };

  const imageChange = (e: React.MouseEvent<HTMLInputElement>) => {
    setModal({
      open: true,
      children: (
        <ItemSelectModal
          title="Select User Background"
          handleEnd={set_Icon}
          icons={userBackgrounds}
        />
      ),
    });
  };

  const handlePhone = (value: string) => {
    handleGeneralChange("mobile", value);
  };

  const renderGeneralTab = () => (
    <>
      {isMobile && <p className="profile__tab_title">{t("general")}</p>}
      <div className="profile__section">
        <div className="sign__group" style={{ marginRight: isMobile ? 0 : 55 }}>
          <p className="sign__form-label">
            {isMobile ? t("user name") : t("name")}
          </p>
          <input
            type="text"
            className="sign__input"
            value={generalValues.name}
            onChange={(e) => handleGeneralChange("name", e.target.value)}
          />
          {generalValidations.name === "has-empty" && (
            <span className="text-error">{t("name required")}*</span>
          )}
        </div>
        <div className="sign__group">
          <p className="sign__form-label">{t("email")}</p>
          <input
            type="text"
            className="sign__input"
            value={generalValues.email}
            onChange={(e) => handleGeneralChange("email", e.target.value)}
          />
          {generalValidations.email === "has-empty" && (
            <span className="text-error">{t("valid email required")}*</span>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "flex-start",
        }}
      >
        <div className="sign__group" style={{ marginRight: isMobile ? 0 : 55 }}>
          <p className="sign__form-label">{t("mobile number")}</p>
          <PhoneNumberInput
            phoneNumber={generalValues.mobile}
            handlePhone={handlePhone}
            borderStyle={borderStyle(generalValidations.mobile)}
          />
          {generalValidations.mobile === "has-empty" && (
            <span className="text-error">
              {t("valid mobile number required")}*
            </span>
          )}
          {generalValidations.mobile === "has-error" && (
            <span className="text-error">{t("input correct format")}*</span>
          )}
        </div>
        <div className="sign__group">
          <p className="sign__form-label">{t("website")}</p>
          <input
            type="text"
            className="sign__input"
            value={generalValues.website}
            onChange={(e) => handleGeneralChange("website", e.target.value)}
          />
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
      >
        <div className="sign__group">
          <p className="sign__form-label">{t("background image")}</p>
          <p className="input__upload" onClick={imageChange}>
            <LazyLoadImage
              src="/img/icons/upload-1.svg"
              alt="data"
              style={{ width: 16, height: 16, marginRight: 10 }}
            />{" "}
            <span className="profile__upload_text">{t("upload")}</span>
          </p>
        </div>
      </div>
      <div className="sign__group" style={{ marginRight: isMobile ? 0 : 55 }}>
        <p className="sign__form-label">{t("description")}</p>
        <textarea
          name="message"
          className="sign__input profile__description"
          value={generalValues.description}
          onChange={(e) => handleGeneralChange("description", e.target.value)}
        />
      </div>
      <button
        className={isGeneralChanged ? "sign__btn" : "sign__btn-inactive"}
        type="button"
        onClick={() =>
          isGeneralChanged
            ? handleChangeGeneral()
            : console.log("You should complete the forms!")
        }
      >
        {t("save")}
      </button>
    </>
  );

  const renderSocialTab = () => (
    <>
      {isMobile && (
        <p className="profile__tab_title mt_50">{t("social media")}</p>
      )}
      <div className="profile__section">
        <div className="sign__group" style={{ marginRight: isMobile ? 0 : 55 }}>
          <p className="sign__form-label">Facebook</p>
          <input
            type="text"
            className="sign__input"
            value={socialValues.facebook}
            placeholder="facebook.com/username"
            onChange={(e) => handleSocialChange("facebook", e.target.value)}
          />
          {socialValidations.facebook === "has-empty" && (
            <span className="text-error">{t("correct URL required")}*</span>
          )}
        </div>
        <div className="sign__group">
          <p className="sign__form-label">Twitter</p>
          <input
            type="text"
            className="sign__input"
            value={socialValues.twitter}
            placeholder="twitter.com/username"
            onChange={(e) => handleSocialChange("twitter", e.target.value)}
          />
          {socialValidations.twitter === "has-empty" && (
            <span className="text-error">{t("correct URL required")}*</span>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "flex-start",
        }}
      >
        <div className="sign__group" style={{ marginRight: isMobile ? 0 : 55 }}>
          <p className="sign__form-label">Instagram</p>
          <input
            type="text"
            className="sign__input"
            value={socialValues.instagram}
            placeholder="instagram.com/username"
            onChange={(e) => handleSocialChange("instagram", e.target.value)}
          />
          {socialValidations.instagram === "has-empty" && (
            <span className="text-error">{t("correct URL required")}*</span>
          )}
        </div>
        <div className="sign__group">
          <p className="sign__form-label">Medium</p>
          <input
            type="text"
            className="sign__input"
            placeholder="medium.com/username"
            value={socialValues.medium}
            onChange={(e) => handleSocialChange("medium", e.target.value)}
          />
          {socialValidations.medium === "has-empty" && (
            <span className="text-error">{t("correct URL required")}*</span>
          )}
        </div>
      </div>

      <button
        className={isSocialChanged ? "sign__btn" : "sign__btn-inactive"}
        type="button"
        onClick={() =>
          isSocialChanged
            ? handleChangeSocial()
            : console.log("You should complete the forms!")
        }
      >
        {t("save")}
      </button>
    </>
  );

  const renderPwdTab = () => (
    <>
      {isMobile && (
        <p className="profile__tab_title mt_50">{t("change password")}</p>
      )}
      <div className="profile__section">
        <div className="sign__group" style={{ marginRight: isMobile ? 0 : 55 }}>
          <p className="sign__form-label">{t("old password")}</p>
          <input
            type="password"
            className="sign__input"
            value={pwdValues.oldPwd}
            onChange={(e) => handlePwdChange("oldPwd", e.target.value)}
          />
          {pwdValidations.oldPwd === "has-empty" && (
            <span className="text-error">{t("old password required")}*</span>
          )}
        </div>
        <div className="sign__group">
          <p className="sign__form-label">{t("new password")}</p>
          <input
            type="password"
            className="sign__input"
            value={pwdValues.newPwd}
            onChange={(e) => handlePwdChange("newPwd", e.target.value)}
          />
          {pwdValidations.newPwd === "has-empty" && (
            <span className="text-error">{t("strong password required")}*</span>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: isMobile ? "100%" : "50%",
          paddingRight: isMobile ? 0 : 28,
        }}
      >
        <div className="sign__group">
          <p className="sign__form-label">{t("confirm new password")}</p>
          <input
            type="password"
            className="sign__input"
            value={pwdValues.confirmPwd}
            onChange={(e) => handlePwdChange("confirmPwd", e.target.value)}
          />
          {pwdValidations.confirmPwd === "has-empty" && (
            <span className="text-error">
              {t("confirm password does not match")}*
            </span>
          )}
        </div>
      </div>

      <button
        className={"sign__btn"}
        type="button"
        onClick={handleChangePassword}
      >
        {t("change password")}
      </button>
    </>
  );

  return (
    <div className={isMobile ? " " : "col-12 col-lg-8 col-xl-8"}>
      <div className="profile__settings-header">
        <p className="profile__settings-title">{t("profile settings")}</p>
        {!isMobile && (
          <div className="profile__settings-tags">
            <p
              className={
                tapState === "general"
                  ? "profile__settings-active"
                  : "profile__settings-inactive"
              }
              onClick={() => setTapState("general")}
            >
              {t("general")}
            </p>
            <p
              className={
                tapState === "social"
                  ? "profile__settings-active"
                  : "profile__settings-inactive"
              }
              onClick={() => setTapState("social")}
            >
              {t("social media")}
            </p>
            <p
              className={
                tapState === "pwd"
                  ? "profile__settings-active"
                  : "profile__settings-inactive"
              }
              onClick={() => setTapState("pwd")}
            >
              {t("change password")}
            </p>
          </div>
        )}
      </div>
      {tapState === "general" && !isMobile && renderGeneralTab()}
      {tapState === "social" && !isMobile && renderSocialTab()}
      {tapState === "pwd" && !isMobile && renderPwdTab()}
      {isMobile && (
        <>
          {renderGeneralTab()} {renderSocialTab()} {renderPwdTab()}
        </>
      )}
    </div>
  );
};
export default Profile;
