import { useEffect, useState } from "react";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { useAppContext } from "../../../context/AppContext";
import styles from "./index.module.css";

const TransactionDetailModal = ({ transaction, wallet }) => {
  const { setModal } = useAppContext();
  const [usdPrice, setUsdPrice] = useState(0);
  const getPrice = async () => {
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = transaction.address;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.token.getTokenPrice({
      address,
      chain,
    });

    const price = response.toJSON().usdPrice;
    setUsdPrice(price);
  };

  useEffect(() => {
    getPrice();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  return (
    <div className={styles.modal_contain}>
      <div>
        <h4 className={styles.modal_title}>Transaction Details</h4>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.btn_close}
          onClick={() => setModal({ open: false })}
        >
          <path
            d="M18 6L6 18"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className={styles.top_part}>
        {transaction?.from_address === wallet ? (
          <img src="/img/wallet/sent_trans.svg" alt="send" />
        ) : (
          <img src="/img/wallet/received_trans.svg" alt="receive" />
        )}
        <div>
          <p className={styles.token_amount}>
            {Number(transaction.value) /
              10 ** Number(transaction.token_decimals)}{" "}
            {transaction?.token_symbol}
          </p>
        </div>
        <p className={styles.token_price}>
          $
          {(
            (Number(transaction.value) /
              10 ** Number(transaction.token_decimals)) *
            usdPrice
          ).toFixed(3)}{" "}
          USD
        </p>
      </div>
      <div className={styles.detail_info}>
        <p className={styles.property}>TRANSACTION HASH</p>
        <p className={styles.value}>{transaction.transaction_hash}</p>
      </div>
      <div className={styles.detail_info}>
        <p className={styles.property}>
          {transaction?.from_address === wallet ? "TO" : "FROM"}
        </p>
        <p className={styles.value}>
          {transaction?.from_address === wallet
            ? transaction.to_address
            : transaction.from_address}
        </p>
      </div>
      <div className={styles.detail_info}>
        <p className={styles.property}>STATUS</p>
        <div className={styles.status_block}>
          <p className={styles.value} style={{ marginBottom: 0 }}>
            Completed
          </p>
          <img alt="check" src="/img/wallet/small_check-circle.svg" />
        </div>
      </div>
      <div className={styles.detail_info}>
        <p className={styles.property}>TRANSACTION DATE</p>
        <p className={styles.value}>
          {transaction?.block_timestamp.substring(0, 16).replace("T", " ")}
        </p>
      </div>
      <div className={styles.detail_info}>
        <p className={styles.property}>TRANSACTION FEE</p>
        <p className={styles.value}>$0.00048</p>
      </div>
      <a href={`https://bscscan.com/tx/${transaction.transaction_hash}`}>
        <div className={styles.bottom_btn}>
          <span style={{ marginTop: 2 }}>VIEW ON BLOCKCHAIN</span>
          <img
            alt="scan link"
            src="/img/wallet/external-link.svg"
            style={{ marginLeft: 10 }}
          />
        </div>
      </a>
    </div>
  );
};

export default TransactionDetailModal;
