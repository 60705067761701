import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useMediaQuery } from "usehooks-ts";
import OwlCarousel from "react-owl-carousel";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Web3 from "web3";

import TicketBuyModal from "../../../components/custom_modals/ticket_buy_modal";
import CashModal from "../../../components/custom_modals/cash_modal";
import FreeModal from "../../../components/custom_modals/free_modal";
import PaymentSuccessMoal from "../../../components/custom_modals/payment_success_modal";
import SelfieSuccessMoal from "../../../components/custom_modals/selfie_success_modal";
import PaymentFailedMoal from "../../../components/custom_modals/payment_failed_modal";
import FloorMapModal from "../../../components/custom_modals/floor_map_modal";
import SelectDateModal from "../../../components/custom_modals/select_date_modal";
import CouponModal from "../../../components/custom_modals/coupon_modal";
import CarouselButton from "../../../components/CarouselButton";
import { useAppContext } from "../../../context/AppContext";
import EventCardInfo from "../../../components/event_card_info";
import Tile from "../../../components/tile";

import {
  buyTicket,
  getEventCardById,
  getLatestEventCards,
  updateEventLike,
  updateCollectionReferral,
  getTicketById,
} from "../../../api/event";
import {
  craftgatePaymentSuccess,
  stripePaymentConfirm,
} from "../../../api/credit";
import { IEventCard } from "../../../utils/interface";
import useAnalyticsEventTracker from "../../../utils/ga";
import { sendMailTicket } from "../../../api/message";
// import * as nearAPI from "near-api-js";
import { useUserContext } from "../../../context/UserContext";

import { getEventPrice } from "../../../utils";

import useConvertedFiat from "../../../hooks/useConvertedFiat";
import useFiatSymbol from "../../../hooks/useFiatSymbol";

// import DomingoSeatMap from "../../../components/custom_modals/domingo_map/seat_board";
// import CongressSeatMap from "../../../components/custom_modals/congress_map/seat_board";
// import AhoySeatMap from "../../../components/custom_modals/ahoy_map/seat_board";
// import HoustonSeatMap from "../../../components/custom_modals/houston_map/seat_board";
import AureneSeatMap from "../../../components/custom_modals/aurene_map/seat_board";
import LisnerSeatMap from "../../../components/custom_modals/lisner_map/seat_board";
// import ChicagoSeatMap from "../../../components/custom_modals/chicago_map/seat_board";
import ChicagoUpdateSeatMap from "../../../components/custom_modals/chicago_update_map/seat_board";
import RitzSeatMap from "../../../components/custom_modals/ritz_map/seat_board";
import Ritz7thSeatMap from "../../../components/custom_modals/ritz_7th_map/seat_board";
// import MiamiSeatMap from "../../../components/custom_modals/miami_map/seat_board";
// import RickySeatMap from "../../../components/custom_modals/ricky_map/seat_board";
// import EnriqueSeatMap from "../../../components/custom_modals/enrique_map/seat_board";
import client from "../../../utils/ipfs_client";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const PageEventCard = () => {
  let { id } = useParams();
  let { other_website } = useParams();

  const {
    setModal,
    loading,
    setLoading,
    country,
    carouselItem,
    addToast,
    paymentModal,
    setPaymentModal,
    selectedSeats,
    setSelectedSeats,
    couponModal,
    setCouponModal,
    ticketAmount,
    setTicketAmount,
    // discount,
    // setDiscount,
  } = useAppContext();
  const { userInfo } = useUserContext();
  const [eventCard, setEventCard] = useState<any>();
  const [latestEvents, setLatestEvents] = useState<IEventCard[]>([]);
  const latestEventCarousel = useRef<typeof OwlCarousel>(null);
  const container = useRef<any>(null);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies();
  const [isSold, setSold] = useState(false);
  
  const [details, setDetails] = useState(true);
  const gaEventTracker = useAnalyticsEventTracker("Buy Ticket");
  const [searchParams] = useSearchParams();
  const isTablet = useMediaQuery("(max-width: 550px)");
  const isMobile = useMediaQuery("(max-width: 450px)");

  const [updateScreen, setUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setUpdate(!updateScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestEvents]);

  useEffect(() => {
    const func1 = async () => {
      addToast(
        "NEAR Wallet has been connected, Please buy your ticket on NEAR!",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
      navigate(`/event/eventcard/${id}`);
    };

    const func2 = () => {
      setLoading(true);
      const param = JSON.parse(localStorage.getItem("buyInfo") || "{}");
      param.pay_order_id = searchParams.get("transactionHashes");
      buyTicket(param)
        .then((res) => {
          setLoading(false);
          if (res.success) {
            addToast("You bought the ticket", {
              appearance: "success",
              autoDismiss: true,
            });
            handleBought();
          } else {
            addToast("failed", { appearance: "error", autoDismiss: true });
          }
        })
        .catch((error) => {
          addToast("failed", { appearance: "error", autoDismiss: true });
        });
      setLoading(false);
      localStorage.removeItem("buyInfo");
      navigate(`/event/eventcard/${id}`);
    };

    if (searchParams.get("account_id")) func1();
    else if (searchParams.get("transactionHashes")) func2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setLoading(true);
    getEventCardData();
    if (id) {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (other_website !== undefined) {
      let data = {
        website: other_website,
        event: id,
      };
      localStorage.setItem("other_website", JSON.stringify(data));
    }
    getLatestEventCards().then((res) => {
      if (res.success) {
        setLatestEvents(res.eventcards);
      }
    });
  }, [other_website, id]);

  useEffect(() => {
    const status = searchParams.get("status");

    if (status === "success" && localStorage.getItem("ccObject") !== null) {
      setLoading(true);
      paramSuccessHandle();
    } else if (status === "failed") {
      setModal({
        open: true,
        children: <PaymentFailedMoal />,
      });
      localStorage.removeItem("other_website");
      localStorage.removeItem("ccObject");
      navigate(`/event/eventcard/${id}`);
    } else if (status === "overload") {
      addToast(
        "Please choose your seats again because other users already bought the seats.",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
      localStorage.removeItem("other_website");
      localStorage.removeItem("ccObject");
      navigate(`/event/eventcard/${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, addToast, navigate, id]);

  useEffect(() => {
    const terms = searchParams.get("termsConditions");
    if (terms) {
      setDetails(false);
      navigate(`/event/eventcard/${id}`);
    }
    const payment_intent = searchParams.get("payment_intent");
    const payment_intent_client_secret = searchParams.get(
      "payment_intent_client_secret"
    );
    if (payment_intent && payment_intent_client_secret) {
      setLoading(true);
      stripeSuccessHandle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (paymentModal && userInfo.user.user_type === "CASHIER ADMIN") {
      setPaymentModal(false);
      setModal({
        open: true,
        children: (
          <CashModal
            amount={selectedSeats.length}
            eventCard={eventCard}
            handleTicket={handleBuyTicket}
          />
        ),
      });
    } else if (paymentModal && userInfo.user.user_type === "FREE ADMIN") {
      setPaymentModal(false);
      setModal({
        open: true,
        children: (
          <FreeModal
            amount={selectedSeats.length}
            eventCard={eventCard}
            handleTicket={handleBuyTicket}
          />
        ),
      });
    } else if (paymentModal) {
      setPaymentModal(false);
      if (eventCard.collection.id === "64011436-3dc1-4bc0-bd82-4398e8fb4a11") {
        setModal({
          open: true,
          children: <CouponModal />,
        });
      } else {
        setModal({
          open: true,
          children: (
            <TicketBuyModal
              eventCard={eventCard}
              handleEnd={handleBought}
              handleTicket={handleBuyTicket}
            />
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentModal]);

  useEffect(() => {
    if (couponModal) {
      setCouponModal(false);
      setModal({
        open: true,
        children: (
          <TicketBuyModal
            eventCard={eventCard}
            handleEnd={handleBought}
            handleTicket={handleBuyTicket}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponModal]);

  useEffect(() => {
    setSelectedSeats([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setTicketAmount(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paramSuccessHandle = async () => {
    const token = searchParams.get("token");
    const NFT_IPFS = await uploadIPFS();
    console.log(NFT_IPFS);
    const nftData = {
      contract: JSON.parse(localStorage.getItem("ccObject") as string)
        ?.contractAddress,
      IPFS_URL: NFT_IPFS,
      account: JSON.parse(localStorage.getItem("ccObject") as string)
        ?.userWallet,
      picture_ipfs: JSON.parse(localStorage.getItem("ccObject") as string)
        ?.picture_ipfs,
    };
    craftgatePaymentSuccess({
      data: JSON.parse(localStorage.getItem("ccObject") as string),
      token: token,
      nftData,
    }).then((res) => {
      getEventCardData();
      if (res) {
        updateReferral();
        setModal({
          open: true,
          children: <PaymentSuccessMoal />,
        });
      } else {
        setModal({
          open: true,
          children: <PaymentFailedMoal />,
        });
      }
      navigate(`/event/eventcard/${id}`);
      localStorage.removeItem("other_website");
      localStorage.removeItem("ccObject");
      setLoading(false);
    });
  };

  const stripeSuccessHandle = async () => {
    if (!loading) {
      setLoading(true);
    }
    const payment_intent = searchParams.get("payment_intent");
    const token = localStorage.getItem("stripe_token");
    const payment_intent_client_secret = searchParams.get(
      "payment_intent_client_secret"
    );
    const NFT_IPFS = await uploadIPFS();
    console.log(NFT_IPFS);
    const nftData = {
      contract: JSON.parse(localStorage.getItem("ccObject") as string)
        ?.contractAddress,
      IPFS_URL: NFT_IPFS,
      account: JSON.parse(localStorage.getItem("ccObject") as string)
        ?.userWallet,
      picture_ipfs: JSON.parse(localStorage.getItem("ccObject") as string)
        ?.picture_ipfs,
    };
    stripePaymentConfirm({
      payment_intent,
      payment_intent_client_secret,
      token,
      data: JSON.parse(localStorage.getItem("ccObject") as string),
      nftData,
    })
      .then((res) => {
        getEventCardData();
        localStorage.removeItem("ccObject");
        if (res) {
          if (id === "e787b769-4fab-44f9-bdf1-3b793f1649d8") {
            setModal({
              open: true,
              children: <SelfieSuccessMoal />,
            });
          } else {
            setModal({
              open: true,
              children: <PaymentSuccessMoal />,
            });
          }
        } else {
          setModal({
            open: true,
            children: <PaymentFailedMoal />,
          });
          localStorage.removeItem("other_website");
        }
        navigate(`/event/eventcard/${id}`);
        setLoading(false);
      })
      .catch((error) => {
        addToast(
          "Payment failed, contact card issuer or try with a different card.",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        localStorage.removeItem("ccObject");
        navigate(`/event/eventcard/${id}`);
        console.log(error);
        setLoading(false);
      });
  };

  const uploadIPFS = async () => {
    if (localStorage.getItem("ccObject")) {
      const added = await client.add(
        JSON.stringify(
          JSON.parse(localStorage.getItem("ccObject") as string)?.tokenObject
        )
      );
      const ipfs_url = `https://bkstage.infura-ipfs.io/ipfs/${added.path}`;
      return ipfs_url;
    } else return "no data";
  };

  const getEventCardData = async () => {
    await getEventCardById(id).then((res) => {
      console.log(res, id);
      if (res.success) {
        if (res.eventcard.deleted === 1) {
          navigate("/");
        } else {
          setEventCard(res.eventcard);
          fixedTime(res.eventcard.date);

          if (res.eventcard.total_tickets === res.eventcard.buy_count) {
            setSold(true);
          } else {
            setSold(false);
          }
        }
      } else {
        navigate("/");
      }
      const token = searchParams.get("token");
      if (token === null) {
        setLoading(false);
      }
    });
  };

  const updateReferral = async () => {
    if (localStorage.getItem("referral")) {
      await updateCollectionReferral(
        JSON.parse(localStorage.getItem("referral") || "")
      );
    }
    localStorage.removeItem("referral");
  };

  const _onClickLike = () => {
    if (!userInfo) return;
    let likes: any[] = [];
    try {
      likes = JSON.parse((eventCard as IEventCard).likes_number);
    } catch (err) {
      likes = [];
    }
    if (typeof likes !== "object" || likes === null) likes = [];
    const userId = userInfo.user.id;
    if (likes.includes(userId)) {
      const index = likes.indexOf(userId);
      likes.splice(index, 1);
    } else {
      likes.push(userId);
    }
    updateEventLike({
      id: (eventCard as IEventCard).id,
      likes_number: JSON.stringify(likes),
    }).then((res) => {
      if (res.success) {
        setEventCard({ ...eventCard, likes_number: JSON.stringify(likes) });
      }
    });
  };

  const next = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.next(500);
  };

  const prev = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.prev(500);
  };

  const handleBuyTicket = () => {
    if (eventCard.collection.id === "b4f96ec6-3de6-48aa-964d-11b0a974e5e1") {
      addToast("You can buy it later.", {
        appearance: "info",
        autoDismiss: true,
      });
      return;
    }

    const currentTime = new Date();
    const timeZoneDiff =
      (currentTime.getTimezoneOffset() / 60 + eventCard.time_zone) *
      3600 *
      1000;

    if (
      eventCard.stop_time !== null &&
      currentTime.getTime() + timeZoneDiff >
        new Date(eventCard.stop_time).getTime()
    ) {
      addToast(
        "You can purchase the event ticket up to 2 hours before the event starts.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
      return;
    }

    if (isNaN(Number(ticketAmount)) || Number(ticketAmount) <= 0) {
      return;
    }

    if (Boolean(cookies.userInfo)) {
      if (eventCard.total_tickets <= eventCard.buy_count) {
        addToast("Already sold full amount of tickets", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      } else if (
        eventCard.buy_count + Number(ticketAmount) >
        eventCard.total_tickets
      ) {
        addToast(
          `Only ${eventCard.total_tickets -
            eventCard.buy_count} tickets are left`,
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        return;
      } else if (eventCard.price === 0) {
        handleGetTicket();
        return;
      }
      if (eventCard.category === "Category3" && eventCard.period !== null) {
        const start = eventCard.fixed_period
          ? new Date(eventCard.date)
          : new Date();
        let end = eventCard.fixed_period
          ? new Date(eventCard.date)
          : new Date();
        if (eventCard.period_unit === "Month(s)") {
          end.setMonth(end.getMonth() + Number(eventCard.period));
        } else if (eventCard.period_unit === "Year(s)") {
          end.setFullYear(end.getFullYear() + Number(eventCard.period));
        } else if (eventCard.period_unit === "Day(s)") {
          end.setDate(end.getDate() + Number(eventCard.period));
        }
        if (
          eventCard.period_unit === "Day(s)" &&
          Number(eventCard.period) === 1
        ) {
          setModal({
            open: true,
            children: (
              <SelectDateModal
                start={eventCard.date}
                end={eventCard.end_date}
                selectDate={showPaymentModal}
              />
            ),
          });
        } else {
          addToast(
            `This service is valid for ${eventCard.period +
              " " +
              eventCard.period_unit} from ${start
              .toString()
              .substring(0, 15)} to ${end.toString().substring(0, 15)}.`,
            {
              appearance: "info",
              autoDismiss: true,
            }
          );
          showPaymentModal(true);
        }
      } else {
        showPaymentModal(true);
      }

      return;
    } else {
      const url = `/event/eventcard/${id}`;
      setCookie("userRedirect", url);
      navigate("/signin");
    }
  };

  const showPaymentModal = async (value: boolean) => {
    setLoading(true);
    const web3 = new Web3("https://bsc-dataseed.binance.org/");
    const balanceAmount = await web3.eth.getBalance(
      process.env.REACT_APP_MINT_WALLET as string
    );
    setLoading(false);
    const trx = Number(BigInt(balanceAmount)) / 1000000000000000000;
    if (trx < 0.013) {
      addToast(
        "Sorry, try again in 5 minutes.",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
      return;
    }
    if (eventCard.NFT_address === null) {
      addToast(
        "Sorry, you can buy it after setting is finished.",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
      return;
    }
    if (eventCard.collection.id === "64011436-3dc1-4bc0-bd82-4398e8fb4a11") {
      setModal({
        open: true,
        children: <AureneSeatMap eventCard={eventCard} />,
      });
    } else if (
      eventCard.collection.id === "b0b4a7fe-ad0a-4786-99e0-7bb78d0162c7"
    ) {
      setModal({
        open: true,
        children: <RitzSeatMap eventCard={eventCard} />,
      });
    } else if (
      eventCard.collection.id === "e76cfeaa-d043-4c79-b7e1-d65c472fc993"
    ) {
      setModal({
        open: true,
        children: <Ritz7thSeatMap eventCard={eventCard} />,
      });
    } else if (
      eventCard.collection.id === "850a8cbf-229e-4be9-848a-0cb8771cea47"
    ) {
      setModal({
        open: true,
        children: <LisnerSeatMap eventCard={eventCard} />,
      });
    } else if (
      eventCard.collection.id === "308ac148-9310-4f9d-8c53-35167e6b560e"
    ) {
      setModal({
        open: true,
        children: <ChicagoUpdateSeatMap eventCard={eventCard} />,
      });
    } else {
      if (value) {
        if (userInfo.user.user_type === "CASHIER ADMIN") {
          setModal({
            open: true,
            children: (
              <CashModal
                amount={Number(ticketAmount)}
                eventCard={eventCard}
                handleTicket={handleBuyTicket}
              />
            ),
          });
        } else if (userInfo.user.user_type === "FREE ADMIN") {
          setModal({
            open: true,
            children: (
              <FreeModal
                amount={Number(ticketAmount)}
                eventCard={eventCard}
                handleTicket={handleBuyTicket}
              />
            ),
          });
        } else {
          setModal({
            open: true,
            children: (
              <TicketBuyModal
                eventCard={eventCard}
                handleEnd={handleBought}
                handleTicket={handleBuyTicket}
              />
            ),
          });
        }
      } else {
        setModal({ open: false });
      }
    }
  };

  const totalCurrencyPrice =
    useConvertedFiat(eventCard?.price, eventCard?.currency, country) *
      (selectedSeats.length === 0
        ? Number(ticketAmount)
        : selectedSeats.length) +
    useFiatSymbol(country);

  const ticketCurrencyPrice =
    useConvertedFiat(eventCard?.price, eventCard?.currency, country) +
    useFiatSymbol(country);

  const handleBought = async () => {
    const ticketId = localStorage.getItem("ticketId");
    const res = await getTicketById({ id: ticketId });
    let url = new URL(res.ticket.tokenURL);
    let params = new URLSearchParams(url.search);
    let token_id = params.get("a");
    const emailData = {
      mobile: isMobile,
      email: userInfo.user.email,
      ticket_number:
        selectedSeats.length === 0
          ? Number(ticketAmount)
          : selectedSeats.length,
      user_name: userInfo.user.name,
      ticketId,
      totalPrice: totalCurrencyPrice,
      ticketPrice: ticketCurrencyPrice,
      collection_name: eventCard.collection.name,
      scan: eventCard.scan,
      ticket_type: eventCard.collection.category,
      item: eventCard,
      seats: selectedSeats,
      addons: JSON.parse(eventCard.addons),
      start_now:
        eventCard.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
      date: new Date(localStorage.getItem("service_date") || eventCard.date)
        .toString()
        .substring(0, 21),
      end_date: new Date(
        localStorage.getItem("service_date") || eventCard.end_date
      )
        .toString()
        .substring(0, 21),
      wallet: userInfo?.user?.wallet_address,
      nft_address: res.ticket.nft_address,
      token_id,
    };
    localStorage.removeItem("service_date");

    sendMailTicket(emailData)
      .then((res) => {
        updateReferral();
        setLoading(false);
        setModal({
          open: true,
          children: <PaymentSuccessMoal />,
        });
        localStorage.removeItem("ticketId");
      })
      .catch((err) => {
        setLoading(false);
        setModal({
          open: true,
          children: <PaymentFailedMoal />,
        });
        localStorage.removeItem("ticketId");
      });
  };

  const handleGetTicket = () => {
    setLoading(true);
    const param = {
      wallet_address: "_wallet",
      blockchain: "_chain",
      eventcard: eventCard.id,
      collection: eventCard.collection.id,
      price: getEventPrice(eventCard) * Number(ticketAmount),
      pay_order_id: "orderid",
      count: ticketAmount.toString(),
      buyerId: userInfo.user.id,
      other_website: localStorage.getItem("other_website"),
    };
    buyTicket(param)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("ticketId", res.ticket.id);
        if (res.success) {
          gaEventTracker(
            "purchased",
            "Purchased " + ticketAmount + " of ticket: " + eventCard.name
          );
          addToast("You get the ticket", {
            appearance: "success",
            autoDismiss: true,
          });
          setModal({ open: false });
          handleBought();
        } else {
          addToast("failed", { appearance: "error", autoDismiss: true });
          setModal({ open: false });
        }
        localStorage.removeItem("other_website");
      })
      .catch((error) => {
        addToast("failed", { appearance: "error", autoDismiss: true });
        setModal({ open: false });
        localStorage.removeItem("other_website");
      });
  };

  const fixedTime = (date: any) => {
    const dd = moment(date).format("h:mm a");
    return dd;
  };

  const showFloorMap = () => {
    setModal({
      open: true,
      children: <FloorMapModal eventCard={eventCard} />,
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="item__detail_container" ref={container}>
      {isTablet ? (
        <div className="article__top">
          <div onClick={goBack}>
            <LazyLoadImage src="/img/icons/arrow-left.svg" alt="data" />
          </div>
        </div>
      ) : (
        <div className="row row--grid">
          <div className="col-12">
            <ul className="breadcrumb">
              <li className="breadcrumb__item">
                <Link to="/">{t("home")}</Link>
              </li>
              <li className="breadcrumb__item">{eventCard?.creator?.name}</li>
              <li className="breadcrumb__item breadcrumb__item--active">
                {t("item")}
              </li>
            </ul>
          </div>
        </div>
      )}

      {eventCard && (
        <EventCardInfo
          eventCard={eventCard}
          onClickLike={() => _onClickLike()}
          showFloorMap={() => showFloorMap()}
          isSold={isSold}
          handleBuyTicket={() => handleBuyTicket()}
          ticketAmount={ticketAmount}
          setTicketAmount={setTicketAmount}
          fixedTime={fixedTime}
        />
      )}

      {eventCard && (
        <div className="description__contain">
          <div className="details__terms">
            <p
              className={
                !details ? "description__title" : "description__title_active"
              }
              onClick={() => setDetails(true)}
            >
              {t("details")}
            </p>
            <p
              className={
                details ? "description__title" : "description__title_active"
              }
              onClick={() => setDetails(false)}
            >
              {t("terms and conditions")}
            </p>
          </div>
          <div
            className="description__text"
            dangerouslySetInnerHTML={{
              __html: details
                ? eventCard?.description
                  ? eventCard?.description
                  : eventCard?.venue_description
                : eventCard?.terms_conditions,
            }}
          />
        </div>
      )}

      {!isMobile && (
        <section className="row row--grid">
          <div className="col-12 p__zero">
            <div className="main__title main__title--border-top">
              <h2>
                <Link to="/explorer" style={{ fontWeight: 700 }}>
                  {t("other author assets")}
                </Link>
              </h2>
            </div>
          </div>

          {latestEvents.length > 0 && (
            <div style={{ width: "100%", overflow: "hidden" }}>
              <div
                className="carousel-wrapper"
                style={{ position: "relative" }}
              >
                <CarouselButton
                  prev={() => prev(latestEventCarousel)}
                  next={() => next(latestEventCarousel)}
                />
                {isLoading && (
                  <OwlCarousel
                    className="owl-theme"
                    margin={0}
                    items={carouselItem}
                    autoplay={true}
                    autoplayHoverPause={true}
                    loop={
                      latestEvents.length < 4 ? (isTablet ? true : false) : true
                    }
                    dots={false}
                    ref={latestEventCarousel as any}
                  >
                    {latestEvents.map((eventcard: any, i) => (
                      <Tile
                        key={`explorer_event_${i}`}
                        eventCard={eventcard}
                        i={i}
                        deleteEventCard={() => {}}
                      />
                    ))}
                  </OwlCarousel>
                )}
              </div>
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default PageEventCard;
