import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import CustomModalComponent from "../components/custom_modals";
import { MessageDialog } from "../components/Dialogs";
import Loading from "../components/Loading";
import { useAppContext } from "../context/AppContext";
import { useUserContext } from "../context/UserContext";
import routes from "../routes";
import Footer from "./layout/footer";
import Header from "./layout/header";
import {
  eurPriceFeed,
  liraPriceFeed,
  rupeePriceFeed,
  gbpPriceFeed,
} from "../utils/chainlink";

const View = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();
  const {
    loading,
    country,
    setCountry,
    message,
    setMessage,
    modal,
    setModal,
    scrollItem,
    setRateEURvsUSD,
    setRateGBPvsUSD,
    setRateLIRAvsUSD,
    setRateRUPEEvsUSD,
    setCarouselItem,
  } = useAppContext();
  const { setUserInfo } = useUserContext();
  const location = useLocation();

  const { i18n } = useTranslation();

  useEffect(() => {
    resetItem();
    window.addEventListener(
      "resize",
      function(event) {
        resetItem();
      },
      true
    );
  });

  useEffect(() => {
    if (country === "TR") {
      i18n.changeLanguage("tr");
    } else {
      i18n.changeLanguage("en");
    }
  }, [country, i18n]);

  const getRoutes = () =>
    routes.map((prop, key) => <Route key={key} {...prop} />);

  const navigate = useNavigate();

  const resetItem = () => {
    if (window.innerWidth >= 1224) {
      setCarouselItem(4);
    } else if(window.innerWidth >= 451) {
      setCarouselItem(Math.round((window.innerWidth - 20) / 300 * 10) / 10 - 0.1)
    } else {
      setCarouselItem(1);
    }
  };

  const timeout = 3600 * 1000;

  const onIdle = () => {
    // if (Boolean(cookies.userInfo)) removeCookie("userInfo");
    Cookies.remove("userInfo");
    setUserInfo(null);
    navigate("/signin");
    setModal({
      open: false,
    });
  };

  const onActive = () => {
    console.log("active");
  };

  const getIPaddress = async () => {
    if (country === "") {
      try {
        const request = await fetch(
          "https://ipinfo.io/json?token=dfbc22df7d8da7"
        );
        const json = await request.json();
        if (json.country === "TR" || json.country === "IN") {
          setCountry(json.country);
        } else if (json.timezone.search("Europe") !== -1) {
          setCountry("EU");
        } else {
          setCountry("US");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getRate = async () => {
    try {
      await Promise.all([
        eurPriceFeed.latestRoundData().then((roundData: any) => {
          setRateEURvsUSD((roundData?.answer).toNumber() / 100000000);
        }),
        liraPriceFeed.latestRoundData().then((roundData: any) => {
          setRateLIRAvsUSD((roundData?.answer).toNumber() / 100000000);
        }),
        rupeePriceFeed.latestRoundData().then((roundData: any) => {
          setRateRUPEEvsUSD((roundData?.answer).toNumber() / 100000000);
        }),
        gbpPriceFeed.latestRoundData().then((roundData: any) => {
          setRateGBPvsUSD((roundData?.answer).toNumber() / 100000000);
        }),
      ]);
    } catch (error) {
      // const res = await axios({
      //   method: "get",
      //   url: "https://api.exchangerate.host/latest?base=USD",
      // });
      // setRateEURvsUSD(1/res?.data?.rates?.EUR);
      // setRateLIRAvsUSD(1/res?.data?.rates?.TRY);
      // setRateRUPEEvsUSD(1/res?.data?.rates?.INR);
      // setRateGBPvsUSD(1/res?.data?.rates?.GBP);
    }
  };

  useIdleTimer({
    timeout,
    onActive,
    onIdle,
  });

  useEffect(() => {
    getIPaddress();
    getRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (scrollItem.current as HTMLDivElement).scrollIntoView({
      behavior: "smooth",
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <div className="body__container">
        <Header scrollItem={scrollItem} />
        <Routes>{getRoutes()}</Routes>
        <Footer />
      </div>
      <MessageDialog
        open={message?.open}
        title={message?.title}
        description={message?.description}
        onClose={(event: any, reason: any) =>
          reason !== "clickaway" && setMessage({ ...message, open: false })
        }
      />
      <CustomModalComponent
        open={modal.open}
        children={modal.children}
        onClose={(event: any, reason: any) =>
          setModal({ ...modal, open: false })
        }
      />
      <Loading open={loading} />
    </>
  );
};

export default View;
