import { useAppContext } from "../../context/AppContext";

const AddonsModal = ({ addons }) => {
  const { setModal } = useAppContext();
  return (
    <div className="addon__modal-contain">
      <span style={style.btn_close} onClick={() => setModal({ open: false })}>
        <svg
          style={{ marginTop: "-4px" }}
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="#ffffff"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </span>
      <p style={style.event_name}>Benefits included ({addons.length})</p>
      <div className="addon_content">
        {addons.map((item, index) => (
          <div className="addon_block" key={index}>
            <div
              className={
                index <= 1
                  ? "addon__gold-effect"
                  : index <= 3
                  ? "addon__blue-effect"
                  : "addon__gray-effect"
              }
            ></div>
            <img src={item.icon} style={style.addon_icon} alt={item.name} />
            <p style={style.addon_name}>{item.name}</p>
            <p style={style.addon_description}>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const style = {
  btn_close: {
    width: "20px",
    height: "25px",
    display: "inline-block",
    paddingBottom: "2px",
    borderRadius: "3px",
    verticalAlign: "top",
    cursor: "pointer",
    position: "absolute",
    top: "28px",
    right: "28px",
  },
  addon_content: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  addon_icon: {
    width: "44px",
    height: "44px",
  },
  addon_name: {
    fontFamily: "Space Grotesk",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    marginTop: 19,
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.02em",
    color: "#FFFFFF",
  },
  addon_description: {
    fontFamily: "Space Grotesk",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.02em",
    color: "rgba(255, 255, 255, 0.66)",
  },
  event_name: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 25,
  },
};

export default AddonsModal;
