import { useState } from "react";
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { addToken } from "../../../api/token";
import { getUserToken } from "../../../api/token";
import { isWalletValid } from "../../../utils";
import { defaultTokens } from "../../../utils/default_tokens";
import styles from "./index.module.css";

const TokenAddModal = () => {
  const [address, setAddress] = useState("");
  const { userInfo } = useUserContext();
  const { setLoading, addToast, setModal, setTokens, tokens } = useAppContext();
  const addAssets = async () => {
    console.log(tokens);
    const isExistToken = tokens.find(
      (token) => token.token_address.toLowerCase() === address.toLowerCase()
    );
    if (!isExistToken && isWalletValid(address)) {
      setLoading(true);
      try {
        if (!Moralis.Core.isStarted) {
          await Moralis.start({
            apiKey: process.env.REACT_APP_MORALIS,
          });
        }
        const chain = EvmChain.BSC;
        const addresses = [address];
        const response = await Moralis.EvmApi.token.getTokenMetadata({
          addresses,
          chain,
        });

        const metadata = response.toJSON()[0];
        if (metadata.symbol !== "" && metadata.name !== "") {
          const data = {
            user_id: userInfo.user.id,
            token_address: address,
            decimals: metadata.decimals,
            symbol: metadata.symbol,
            balance: 0,
            logo:
              "https://r.poocoin.app/smartchain/assets/" +
              address +
              "/logo.png",
          };
          const res = await addToken(data);
          console.log(res);
          if (res) {
            addToast("Successfully added a new token!", {
              appearance: "success",
              autoDismiss: true,
            });
            await getAllTokens();
          } else {
            addToast("Failed to add new token.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        } else {
          addToast("Failed to add new token.", {
            appearance: "error",
            autoDismiss: true,
          });
        }
      } catch (error) {
        console.log(error);
        addToast("Failed to add new token.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      setModal({ open: false });
      setLoading(false);
    } else {
      addToast("The asset already exists or you entered an incorrect address.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const getAllTokens = async () => {
    setLoading(true);
    const userTokens = await getUserToken({ userId: userInfo?.user?.id });
    if (!Moralis.Core.isStarted) {
      await Moralis.start({
        apiKey: process.env.REACT_APP_MORALIS,
      });
    }

    const address = userInfo?.user?.wallet_address;

    const chain = EvmChain.BSC;

    const response = await Moralis.EvmApi.token.getWalletTokenBalances({
      address,
      chain,
    });
    const data = response.toJSON();
    const promises = data.map((item) => {
      if (!item.possible_spam) {
        return new Promise((resolve, reject) => {
          const res = Moralis.EvmApi.token.getTokenPrice({
            address: item.token_address,
            chain: EvmChain.BSC,
          });
          resolve(res);
        });
      } else {
        return item;
      }
    });
    let tokenData = [];
    await Promise.all(promises)
      .then((results) => {
        console.log("All promises resolved:", results);
        results.forEach((element, index) => {
          tokenData.push({
            ...data[index],
            balance: Number(
              (
                Number(data[index].balance) /
                10 ** data[index].decimals
              ).toFixed(6)
            ),
            price: !element?.balance ? element?.toJSON()?.usdPrice : 0,
          });
        });
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
    const allTokens = defaultTokens.concat(userTokens);
    const balancedUserTokens = allTokens.map((item1) => {
      const matchingItem = tokenData.find(
        (item2) =>
          item2.token_address.toLowerCase() ===
          item1.token_address.toLowerCase()
      );
      return {
        ...item1,
        balance: matchingItem ? matchingItem?.balance : 0,
        price: matchingItem ? matchingItem?.price : 0,
      };
    });
    console.log(balancedUserTokens);
    setTokens([...balancedUserTokens]);
    setLoading(false);
  };
  return (
    <div className={styles.container}>
      <p>Add New Asset</p>
      <div className={styles.wrapper}>
        <div className={styles.input_wrapper_full}>
          Contract Address
          <input
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Input address"
          />
        </div>
      </div>
      <button
        className={styles.add__btn}
        onClick={addAssets}
        type="button"
      >
        ADD ASSETS
      </button>
    </div>
  );
};

export default TokenAddModal;
