import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";

const SelectDateModal = ({ start, end, selectDate }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 576px)");
  const [value, onChange] = useState(new Date(start));
  const [selectData, setSelectData] = useState([]);
  const confirm = () => {
    localStorage.setItem("service_date", value);
    selectDate(true);
  };
  const cancel = () => {
    selectDate(false);
  };

  useEffect(() => {
    let tempDate = new Date(start);
    let dates = []
    while (
      tempDate.getFullYear() !== new Date(end).getFullYear() ||
      tempDate.getMonth() !== new Date(end).getMonth() ||
      tempDate.getDate() <= new Date(end).getDate()
    ) {
      dates.push(tempDate.toString());
      tempDate.setDate(tempDate.getDate() + 1);
    }
    setSelectData([...dates]);
  }, [start, end]);

  return (
    <div style={isMobile ? style.containerMobile : style.container}>
      <p style={{ textAlign: "center" }}>
        {t("Select Service Date")}
      </p>
      <div className="sign__group">
        <select id="category" name="category" className="sign__select" onChange={(e) => onChange(e.target.value) }>
          {
            selectData.map((item, index) => <option key={index} value={item}>{item.substring(0, 15)}</option>)
          }
        </select>
        <img
          src="/img/icons/arrow-down.svg"
          alt="arrow down"
          className="cr__evcard-selectArrow"
          style={{top: 30}}
        />
      </div>
      <div style={{ display: "flex", gap: 10 }}>
        <button
          className="asset__btn asset__btn--full open-modal"
          onClick={confirm}
        >
          <span
            style={{
              marginLeft: 5,
            }}
          >
            {t("confirm")}
          </span>
        </button>
        <button
          className="asset__btn asset__btn--full open-modal"
          onClick={cancel}
        >
          <span
            style={{
              marginLeft: 5,
            }}
          >
            {t("cancel")}
          </span>
        </button>
      </div>
    </div>
  );
};

const style = {
  container: {
    maxWidth: "400px",
    backgroundColor: "rgb(0, 18, 0)",
    color: "white",
    padding: "25px 40px 20px",
    borderRadius: "12px",
    border: "1px solid rgb(31, 49, 39)",
    margin: "auto",
    position: "relative",
  },
  containerMobile: {
    maxWidth: "95%",
    backgroundColor: "rgb(0, 18, 0)",
    color: "white",
    padding: "25px 20px 20px",
    borderRadius: "12px",
    border: "1px solid rgb(31, 49, 39)",
    margin: "auto",
    position: "relative",
  },
  btn_close: {
    position: "absolute",
    top: "20px",
    right: "35px",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  btn_closeMobile: {
    position: "absolute",
    top: "30px",
    right: "15px",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  btn_crypto: {
    border: "1px solid rgba(255, 255, 255, 0.33)",
  },
};

export default SelectDateModal;
