import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { getArticleById } from "../../../api/article";
import { IArticle } from "../../../utils/interface";
import { dateString } from "../../../utils";
import styles from "./index.module.css";

const PageArticle = () => {
  const { id } = useParams();
  const [article, setArticle] = useState<IArticle>({} as IArticle);
  const {
    loading,
    setLoading,
    addToast,
  } = useAppContext();
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getArticleById(id)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setArticle(res.article);
        } else {
          addToast(res.message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast("An error occured", {
          appearance: "error",
          autoDismiss: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.article}>
      {userInfo?.user?.user_type === "ADMIN" && (
        <Link to={`/article/edit/` + id}>
          <p className={styles.edit__article}>{t("edit article")}</p>
        </Link>
      )}
      {isMobile ? (
        <div className="article__top">
          <div onClick={() => navigate("/blog")}>
            <LazyLoadImage src="/img/icons/arrow-left.svg" alt="data" />
          </div>
          <p>{t("article")}</p>
          <div>
            <LazyLoadImage
              src="/img/icons/upload.svg"
              alt="data"
              style={{ display: "none" }}
            />
          </div>
        </div>
      ) : (
        <div className="article__top">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <Link to="/blog">{t("news")}</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">
              {t("article")}
            </li>
          </ul>
        </div>
      )}
      <div className={styles.article__content}>
        <div className={styles.img_wrapper}>
          <LazyLoadImage
            src={`${process.env.REACT_APP_ASSET_BASE_URL}/${article?.image}`}
            alt=""
          />
          <div className={styles.article__container}>
            <div className={styles.article__news_group}>
              <span className={styles.article__category}>{t("news")}</span>
              {isMobile ? (
                <span className={styles.article__date}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20ZM14.09814,9.63379,13,10.26807V7a1,1,0,0,0-2,0v5a1.00025,1.00025,0,0,0,1.5.86621l2.59814-1.5a1.00016,1.00016,0,1,0-1-1.73242Z"></path>
                  </svg>{" "}
                  {dateString(article?.createdAt)} &nbsp; &#8226;
                </span>
              ) : (
                <span className={styles.article__date}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20ZM14.09814,9.63379,13,10.26807V7a1,1,0,0,0-2,0v5a1.00025,1.00025,0,0,0,1.5.86621l2.59814-1.5a1.00016,1.00016,0,1,0-1-1.73242Z"></path>
                  </svg>{" "}
                  {dateString(article?.createdAt)}
                </span>
              )}
            </div>
            <div className="article__message">
              <LazyLoadImage src="/img/icons/message.svg" alt="data" />
              <p style={{ margin: 0 }}>0</p>
            </div>
          </div>
        </div>

        {!loading && !article && <h1>Can't find the article</h1>}

        {!loading && article && (
          <>
            <h1 className={styles.article__content_title}>{article.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: article.description }} />
          </>
        )}
      </div>
      <Link to="/blog">
        <div className={styles.article__back_block}>
          <div>
            <LazyLoadImage
              src="/img/arrow-left-sm.png"
              className={styles.article__img}
              alt="data"
            />
          </div>
          <p className={styles.article__back_text}>{t("Back to News")}</p>
        </div>
      </Link>
      <div className="bottom_block"></div>
    </div>
  );
};

export default PageArticle;
