import { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";
import * as htmlToImage from "html-to-image";
import SelfieBoard from "./selfie_board__modal";

import { useAppContext } from "../../context/AppContext";

const SelfiePreview = ({ uploadSrc = "" }) => {
  const { setLoading, setModal } = useAppContext();
  const webcamRef = useRef(null);
  const domEl = useRef(null);
  const [imgSrc, setImgSrc] = useState(uploadSrc);

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, setImgSrc]);

  const getImage = async () => {
    domEl.current.style.backgroundImage = `url(${imgSrc})`;
    setLoading(true);
    const dataUrl = await htmlToImage.toSvg(domEl.current);
    setModal({
      open: true,
      children: <SelfieBoard uploadSrc={dataUrl} />,
    });
    setLoading(false);
  };

  return (
    <div style={style.container}>
      <div className="photo">
        {imgSrc === "" ? (
          <Webcam
            style={style.camera}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            forceScreenshotSourceSize="true"
            mirrored={true}
            videoConstraints={{
              facingMode: "user",
              aspectRatio: 320 / 320,
            }}
          />
        ) : (
          <div
            ref={domEl}
            className="photo"
            style={{
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundImage: `url(${imgSrc})`,
              backgroundAttachment: "scroll"
            }}
          ></div>
        )}
      </div>
      {imgSrc === "" && (
        <div style={{ width: "100%", marginTop: "-8.5%", zIndex: 10 }}>
          <img
            alt="capture camera"
            onClick={() => capture()}
            style={style.blue_camera}
            src="/img/selfie/blueCamera.png"
          />
        </div>
      )}
      {imgSrc !== "" && (
        <button
          onClick={getImage}
          className="input__upload"
          style={{ width: 270 }}
        >
          CONTINUE
        </button>
      )}
      {uploadSrc === "" && imgSrc !== "" && (
        <button
          onClick={() => setImgSrc("")}
          className="input__upload"
          style={{ width: 270 }}
        >
          RETAKE PHOTO
        </button>
      )}
      {uploadSrc !== "" && (
        <button
          onClick={() => setModal({ open: false })}
          className="input__upload"
          style={{ width: 270 }}
        >
          BACK
        </button>
      )}
    </div>
  );
};

const style = {
  container: {
    maxWidth: "98%",
    textAlign: "center",
    backgroundColor: "rgb(0, 18, 0)",
    color: "white",
    borderRadius: "10px",
    border: "1px solid rgb(31, 49, 39)",
    padding: "20px",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  camera: {
    width: "100%",
    position: "relative",
    zIndex: 10,
    overflow: "hidden",
  },
  blue_camera: {
    width: "16%",
    cursor: "pointer",
    borderRadius: 1000,
  },
};

export default SelfiePreview;
