// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { createArticle } from "../../../api/article";

import JoditEditor from "jodit-react";
import "jodit";
import "jodit/build/jodit.min.css";

const PageArticleCreate = () => {
  const { userInfo } = useUserContext();
  const [file, setFile] = useState<File>();
  const [imagePreview, setImagePreview] = useState("");
  const { setLoading, addToast } = useAppContext();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const editor = useRef(null);

  const editorConfig = {
    readonly: false,
    toolbar: true,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    uploader: {
      insertImageAsBase64URI: true,
    },
    height: 280,
  };

  useEffect(() => {
    if (
      userInfo?.user?.user_type !== "ADMIN" &&
      userInfo?.user?.user_type !== "TURKISH ADMIN"
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const [values, setValues] = useState({
    title: "",
    description: "",
  });

  const [validations, setValidations] = useState({
    image: "",
    title: "",
    description: "",
  });

  const borderStyle = (str: string) => {
    return str === "has-empty"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  const checkvalidations = () => {
    if (!file) {
      setValidations({ image: "has-empty", title: "", description: "" });
      return false;
    } else if (values.title === "") {
      setValidations({ image: "", title: "has-empty", description: "" });
      return false;
    } else {
      setValidations({ image: "", title: "", description: "" });
    }

    return true;
  };

  const imageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    const f = e.target.files[0];
    if (reader !== undefined && f !== undefined) {
      reader.onloadend = () => {
        setFile(f);
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(f);
    }
  };

  const handleChange = (prop: string, value: string) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const handleCreate = () => {
    if (!checkvalidations()) return;

    const fd = new FormData();
    fd.append("image", file);
    fd.append("title", values.title);
    fd.append("description", editor.current.value);

    setLoading(true);

    createArticle(fd)
      .then((res) => {
        setLoading(false);
        if (res.success) {
          navigate("/blog");
        } else {
          addToast("failed", { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("An error occured", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
  return (
    <div className="create__article_container">
      <h2 className="cr__email--title">{t("create article")}</h2>

      <div className="cr__email--formContainer">
        <div>
          <p className="cr__article--subtitle">{t("upload article image")}</p>
          <p className="cr__email--propstitle mb-0">
            {t("image resolution")} 1080x1080
          </p>
          <div className="cr__evcard-uploadImg">
            <label
              id="file"
              htmlFor="article__file-upload"
              className="input__upload"
            >
              {imagePreview ? (
                file.name
              ) : (
                <>
                  <LazyLoadImage src="/img/icons/upload-1.svg" alt="data" />{" "}
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file1"
              id="article__file-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg"
              onChange={imageChange}
              src={imagePreview}
            />

            {validations.image === "has-empty" && (
              <span className="text-error">{t("image required")}*</span>
            )}
            {imagePreview && (
              <LazyLoadImage
                src={imagePreview}
                style={{ maxWidth: "100%", display: "none" }}
                alt="data"
              />
            )}
          </div>
        </div>
        <div className="mt_50">
          <p className="cr__article--subtitle">{t("article details")}</p>
          <p className="cr__email--propstitle mb-0">{t("article title")}</p>
          <div className="sign__group">
            <input
              id="itemname"
              type="text"
              name="itemname"
              className="sign__input"
              placeholder="e. g. 'Crypto Heart'"
              value={values.title}
              style={borderStyle(validations.title)}
              onChange={(e) => handleChange("title", e.target.value)}
            />
            {validations.title === "has-empty" && (
              <span className="text-error">{t("name required")}*</span>
            )}
          </div>
        </div>

        <div className="mt_50">
          <p className="cr__email--propstitle mb-0">{t("content")}</p>
          <div className="text-editor-wrapper-article">
            <JoditEditor
              config={editorConfig}
              ref={editor}
            />
          </div>
          {validations.description === "has-empty" && (
            <span className="text-error">{t("description required")}*</span>
          )}
        </div>
      </div>
      <div className="create__post_btn">
        <button
          type="button"
          className="sign__btn post__btn"
          onClick={handleCreate}
        >
          {t("create post")}
        </button>
      </div>
    </div>
  );
};

export default PageArticleCreate;
