import React, {
  createContext,
  useState,
  useContext,
  FC,
  useEffect,
  useRef,
} from "react";
import { useToasts } from "react-toast-notifications";
import PropTypes from "prop-types";

interface IMessage {
  open: boolean;
  title?: string;
  description?: string;
}

// export const appContextDefaultValue: AppContextValue = {}
interface AppContextInfterface {
  loading: boolean;
  setLoading?: any;
  message: IMessage;
  setMessage?: any;
  ticketData: string;
  setTicketData?: any;
  country: string;
  setCountry?: any;
  modal: any;
  setModal?: any;
  settings?: any;
  setSettings?: any;
  isCookiePopup: boolean;
  setCookiePopup?: any;
  searchStr: string;
  setSearchStr?: any;
  scrollItem?: any;
  buyTicketEvent: boolean;
  setBuyTicketEvent?: any;
  mintNFTEvent: any;
  setMintNFTEvent?: any;
  carouselItem: number;
  setCarouselItem?: any;
  rateEURvsUSD: number;
  rateLIRAvsUSD: number;
  rateRUPEEvsUSD: number;
  rateGBPvsUSD: number;
  setRateEURvsUSD?: any;
  setRateLIRAvsUSD?: any;
  setRateRUPEEvsUSD?: any;
  setRateGBPvsUSD?: any;
  pageNumber: number;
  setPageNumber?: any;
  allItems: any;
  setAllItems?: any;
  allCollections: any;
  setAllCollections?: any;
  addToast?: any;
  cPageNumber: number;
  setCPageNumber?: any;
  paymentModal: boolean;
  setPaymentModal?: any;
  couponModal: boolean;
  setCouponModal?: any;
  selectedSeats: any;
  setSelectedSeats?: any;
  discount: number;
  setDiscount?: any;
  tablePackage: number;
  setTablePackage?: any;
  digitalItems: any;
  setDigitalItems?: any;
  tokens: any;
  setTokens?: any;
  balance: number;
  bnbPrice: number;
  setBalance?: any;
  setBnbPrice?: any;
  transactions: any;
  setTransactions?: any;
  erc20Transactions: any;
  setErc20Transactions?: any;
  erc721Transactions: any;
  setErc721Transactions?: any;
  ticketAmount: number;
  setTicketAmount?: any;
}

const defaultState: AppContextInfterface = {
  loading: false,
  message: { open: false },
  ticketData: "null",
  country: "",
  modal: { open: false },
  isCookiePopup: false,
  searchStr: "",
  buyTicketEvent: false,
  mintNFTEvent: false,
  carouselItem: 4,
  rateEURvsUSD: 1,
  rateLIRAvsUSD: 1,
  rateRUPEEvsUSD: 1,
  rateGBPvsUSD: 1,
  pageNumber: 0,
  allItems: [],
  cPageNumber: 0,
  paymentModal: false,
  couponModal: false,
  selectedSeats: [],
  discount: 0,
  allCollections: [],
  tablePackage: 0,
  digitalItems: [],
  tokens: [],
  bnbPrice: 0,
  balance: 0,
  transactions: [],
  erc20Transactions: [],
  erc721Transactions: [],
  ticketAmount: 1,
};

export const AppContext = createContext(defaultState);

const AppContextProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ open: false } as IMessage);
  const [modal, setModal] = useState({ open: false });
  const [settings, setSettings] = useState([]);
  const [isCookiePopup, setCookiePopup] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [country, setCountry] = useState("");
  const [discount, setDiscount] = useState(0);
  const [rateEURvsUSD, setRateEURvsUSD] = useState(1);
  const [rateLIRAvsUSD, setRateLIRAvsUSD] = useState(1);
  const [rateRUPEEvsUSD, setRateRUPEEvsUSD] = useState(1);
  const [rateGBPvsUSD, setRateGBPvsUSD] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [cPageNumber, setCPageNumber] = useState(0);
  const [ticketData, setTicketData] = useState("null");
  const scrollItem = useRef();
  const [buyTicketEvent, setBuyTicketEvent] = useState(false);
  const [mintNFTEvent, setMintNFTEvent] = useState(false);
  const [carouselItem, setCarouselItem] = useState(4);
  const [allItems, setAllItems] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [paymentModal, setPaymentModal] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [tablePackage, setTablePackage] = useState(0);
  const [digitalItems, setDigitalItems] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [erc20Transactions, setErc20Transactions] = useState([]);
  const [erc721Transactions, setErc721Transactions] = useState([]);
  const [balance, setBalance] = useState(0);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [ticketAmount, setTicketAmount] = useState(1);
  const { addToast } = useToasts();
  useEffect(() => {
    if (localStorage.getItem("consent") === null) setCookiePopup(true);
  }, []);

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        message,
        setMessage,
        country,
        setCountry,
        modal,
        setModal,
        settings,
        setSettings,
        isCookiePopup,
        setCookiePopup,
        searchStr,
        setSearchStr,
        scrollItem,
        ticketData,
        setTicketData,
        buyTicketEvent,
        setBuyTicketEvent,
        mintNFTEvent,
        setMintNFTEvent,
        carouselItem,
        setCarouselItem,
        rateEURvsUSD,
        rateGBPvsUSD,
        rateLIRAvsUSD,
        rateRUPEEvsUSD,
        setRateEURvsUSD,
        setRateGBPvsUSD,
        setRateLIRAvsUSD,
        setRateRUPEEvsUSD,
        pageNumber,
        setPageNumber,
        allItems,
        setAllItems,
        addToast,
        cPageNumber,
        setCPageNumber,
        paymentModal,
        setPaymentModal,
        couponModal,
        setCouponModal,
        selectedSeats,
        setSelectedSeats,
        discount,
        setDiscount,
        allCollections,
        setAllCollections,
        tablePackage,
        setTablePackage,
        digitalItems,
        setDigitalItems,
        tokens,
        setTokens,
        balance,
        setBalance,
        bnbPrice,
        setBnbPrice,
        transactions,
        setTransactions,
        erc20Transactions,
        setErc20Transactions,
        erc721Transactions,
        setErc721Transactions,
        ticketAmount,
        setTicketAmount,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.object,
};

export default AppContextProvider;
export const useAppContext = () => useContext(AppContext);
