import React from "react";
import styles from "./index.module.css";

const Bep20Item = ({ item, wallet }) => {
  return (
    <div className={styles.transaction_item}>
      <div className={styles.hash_block}>
        <p className={styles.item_title}>Transaction Hash</p>
        <p className={styles.item_value}>{item.transaction_hash}</p>
      </div>
      <div className={styles.type_chain}>
        <div>
          <p className={styles.item_title}>Type</p>
          {item?.from_address === wallet ? (
            <img src="/img/wallet/send_tran.svg" alt="send" />
          ) : (
            <img src="/img/wallet/receive_tran.svg" alt="receive" />
          )}
        </div>
        <div>
          <p className={styles.item_title}>Chain</p>
          <img alt="send" src="/img/wallet/bsc_chain.svg" />
        </div>
      </div>
      <div className={styles.date_block}>
        <p className={styles.item_title}>From</p>
        <p className={styles.item_value}>{item.from_address}</p>
      </div>
      <div className={styles.date_block}>
        <p className={styles.item_title}>To</p>
        <p className={styles.item_value}>{item.to_address}</p>
      </div>
      <div className={styles.date_block}>
        <p className={styles.item_title}>Date</p>
        <p className={styles.item_value}>
          {item.block_timestamp.substring(0, 16).replace("T", " ")}
        </p>
      </div>
      <div style={{ display: "flex", gap: 20 }}>
        <div>
          <p className={styles.item_title}>Token</p>
          <p className={styles.item_value}>{item.token_symbol}</p>
        </div>
        <div>
          <p className={styles.item_title}>Value</p>
          <p className={styles.item_value}>
            {Number(item.value) / 10 ** Number(item.token_decimals)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Bep20Item;
