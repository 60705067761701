import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageUpload = ({
  validations,
  values,
  largeFile,
  largeImageChange,
  largeImagePreview,
  largeImageLoaded,
  smallFile,
  smallImageChange,
  smallImagePreview,
  nftFile,
  nftImageChange,
  nftImagePreview,
  floorMapFile,
  floorMapImageChange,
  floorMapImagePreview,
}) => {
  const { t } = useTranslation();
  const borderStyle = (str) => {
    return str === "has-empty"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };
  return (
    <div className="cr__evcard-subItem">
      <h4 className="cr__evcard--subtitle">
        <span></span>
        {t("upload item image")}
      </h4>
      <div className="cr__evcard-rowParent" style={{ marginBottom: "30px" }}>
        <div className="cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("large picture")}</p>
          <p className="cr__evcard-labelDesc">Image size 1080 x 1080</p>
          <div className="cr__evcard-uploadImg">
            <label
              id="file1"
              htmlFor="largeimage-upload"
              className="input__upload"
              style={borderStyle(validations.picture_large)}
            >
              {largeImagePreview ? (
                largeFile?.name
              ) : (
                <>
                  <img src="/img/selfie/upload.png" alt="upload" />
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file1"
              id="largeimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif,.mp3,.wav,.mp4,.mov,.avi"
              onChange={largeImageChange}
              src={largeImagePreview}
            />

            {validations.picture_large === "has-empty" ? (
              <span className="text-error">{t("large image required")}*</span>
            ) : (
              validations.picture_large === "has-invalid" && (
                <span className="text-error">{t("wrong image size")}*</span>
              )
            )}
          </div>
          {largeImagePreview && (
            <LazyLoadImage
              src={largeImagePreview}
              onLoad={largeImageLoaded}
              className="text-center"
              width={"100%"}
              style={{ display: "none" }}
              alt="large preview"
            />
          )}
        </div>
        <div className="cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("small picture")}</p>
          <p className="cr__evcard-labelDesc">Image size 288 x 288</p>
          <div className="cr__evcard-uploadImg">
            <label
              id="file2"
              htmlFor="smallimage-upload"
              className="input__upload"
              style={borderStyle(validations.picture_small)}
            >
              {smallImagePreview ? (
                smallFile?.name
              ) : (
                <>
                  <img src="/img/selfie/upload.png" alt="upload" />{" "}
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file2"
              id="smallimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif"
              onChange={smallImageChange}
              src={smallImagePreview}
            />

            {validations.picture_small === "has-empty" ? (
              <span className="text-error">{t("small image required")}*</span>
            ) : (
              validations.picture_small === "has-invalid" && (
                <span className="text-error">{t("small image rule")}*</span>
              )
            )}
          </div>
          {smallImagePreview && (
            <div
              className="text-center"
              style={{ textAlign: "center", display: "none" }}
            >
              <LazyLoadImage src={smallImagePreview} width={"50%"} alt="small preview" />
            </div>
          )}
        </div>
      </div>
      <div className="cr__evcard-rowParent">
        <div className="cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("NFT picture")}</p>
          <p className="cr__evcard-labelDesc">
            {t("image to send as NFT")} (max size 100MB)
          </p>
          <div className="cr__evcard-uploadImg">
            <label
              id="file3"
              htmlFor="nftimage-upload"
              className="input__upload"
              style={borderStyle(validations.picture_nft)}
            >
              {nftImagePreview ? (
                nftFile?.name
              ) : (
                <>
                  <img src="/img/selfie/upload.png" alt="upload" />
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file1"
              id="nftimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif,.mp3,.wav,.mp4,.mov,.avi"
              onChange={nftImageChange}
              src={nftImagePreview}
            />

            {validations.picture_nft === "has-empty" && (
              <span className="text-error">{t("NFT image required")}*</span>
            )}
          </div>
          {nftImagePreview && (
            <LazyLoadImage
              src={nftImagePreview}
              className="text-center"
              width={"100%"}
              style={{ display: "none" }}
              alt="nft preview"
            />
          )}
        </div>
        {values.category === "Category1" && (
          <div className="cr__evcard-50">
            <p className="cr__evcard-labelTitle">{t("floor map")}</p>
            <p className="cr__evcard-labelDesc">Image size up to 2000 x 2000</p>
            <div className="cr__evcard-uploadImg">
              <label
                id="file3"
                htmlFor="floormapimage-upload"
                className="input__upload"
                style={borderStyle(validations.picture_floormap)}
              >
                {floorMapImagePreview ? (
                  floorMapFile?.name
                ) : (
                  <>
                    <img src="/img/selfie/upload.png" alt="upload" />{" "}
                    <span>{t("upload")}</span>
                  </>
                )}
              </label>
              <input
                data-name="#file1"
                id="floormapimage-upload"
                name="file"
                type="file"
                accept=".png,.jpg,.jpeg,.gif,.mp3,.wav,.mp4,.mov,.avi"
                onChange={floorMapImageChange}
                src={floorMapImagePreview}
              />

              {validations.picture_floormap === "has-empty" && (
                <span className="text-error">
                  {t("Floor Map image required")}*
                </span>
              )}
            </div>
            {floorMapImagePreview && (
              <LazyLoadImage
                src={floorMapImagePreview}
                className="text-center"
                width={"100%"}
                style={{ display: "none" }}
                alt="floorMap preview"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
