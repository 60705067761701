import { useState, useEffect } from "react";
import { useAppContext } from "../../../context/AppContext";
import styles from "./index.module.css";

const CouponModal = () => {
  const { addToast, setDiscount, setCouponModal } = useAppContext();
  const [coupon, setCoupon] = useState("");
  const handleEvent = async () => {
    if (coupon === "TACCS20") {
      addToast("Success! You wil get 20% discount", {
        appearance: "success",
        autoDismiss: true,
      });
      setDiscount(0.2);
      setCouponModal(true);
    } else {
      addToast(
        "Failed. Please input correct code. If you don't have, you can skip.",
        { appearance: "error", autoDismiss: true }
      );
    }
  };
  const handleClose = async () => {
    setCouponModal(true);
  };
  useEffect(() => {
    setDiscount(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.input_wrapper_full}>
            <input
              type="text"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
              placeholder="Input coupon code for discount"
            />
          </div>
        </div>
        <div className={styles.btn__container}>
          <button
            className={styles.add__btn}
            onClick={handleEvent}
            type="button"
          >
            Confirm
          </button>
          <button
            className={styles.add__btn}
            onClick={handleClose}
            type="button"
          >
            Skip
          </button>
        </div>
      </div>
    </form>
  );
};

export default CouponModal;
