import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__logo}>
        <img
          src="/img/liveticketlogowhite.png"
          alt="logo"
        />
      </div>
      <div className={styles.footer__content}>
        <div className={styles.footer__fields}>
          <div className={styles.footer__item}>
            <div>
              <h6 className={styles.footer__title}>{t("marketplace")}</h6>
              <div className={styles.footer__nav}>
                <Link to="/explorer">{t("explore")}</Link>
                <Link to="/blog">{t("news")}</Link>
                <Link to="/about">{t("about.title")}</Link>
              </div>
            </div>
            <div>
              <h6 className={styles.footer__title}>{t("contact us")}</h6>
              <div className={styles.footer__nav}>
                <Link to="/faq">FAQ</Link>
                <Link to="/contacts">{t("business inquiry")}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer__end}>
          <p className={styles.footer__copyright}>
            © 2021 - 2024 Backstage Smart Technologies, All rights reserved{" "}
            <span style={{ marginLeft: 10, marginRight: 10 }}>&#183;</span>{" "}
            <a href="/privacy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            .
          </p>
          <p className={styles.footer__developed_text}>
            Designed and developed by Backstage Smart Technologies Ltd.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
