import { useAppContext } from "../context/AppContext";
import { useMemo } from "react";

const useConvertedFiat = (price, currency, region) => {
  const {
    rateEURvsUSD,
    rateGBPvsUSD,
    rateLIRAvsUSD,
    rateRUPEEvsUSD,
    discount,
  } = useAppContext();
  const convertedFiat = useMemo(() => {
    switch (region) {
      case "TR":
        if (currency === "USD") {
          return Number(price) / rateLIRAvsUSD;
        } else if (currency === "TRY") {
          return Number(price);
        } else if (currency === "INR") {
          return (Number(price) * rateRUPEEvsUSD) / rateLIRAvsUSD;
        } else {
          return (Number(price) * rateEURvsUSD) / rateLIRAvsUSD;
        }
      case "GB":
        if (currency === "USD") {
          return Number(price) / rateGBPvsUSD;
        } else if (currency === "INR") {
          return (Number(price) * rateRUPEEvsUSD) / rateGBPvsUSD;
        } else if (currency === "TRY") {
          return (Number(price) * rateLIRAvsUSD) / rateGBPvsUSD;
        } else {
          return (Number(price) * rateEURvsUSD) / rateGBPvsUSD;
        }
      case "US":
        if (currency === "USD") {
          return Number(price);
        } else if (currency === "INR") {
          return Number(price) * rateRUPEEvsUSD;
        } else if (currency === "TRY") {
          return Number(price) * rateLIRAvsUSD;
        } else {
          return Number(price) * rateEURvsUSD;
        }
      case "IN":
        if (currency === "INR") {
          return Number(price);
        } else if (currency === "USD") {
          return Number(price) / rateRUPEEvsUSD;
        } else if (currency === "TRY") {
          return (Number(price) * rateLIRAvsUSD) / rateRUPEEvsUSD;
        } else {
          return (Number(price) * rateEURvsUSD) / rateRUPEEvsUSD;
        }
      case "EU":
        if (currency === "USD") {
          return Number(price) / rateEURvsUSD;
        } else if (currency === "INR") {
          return (Number(price) * rateRUPEEvsUSD) / rateEURvsUSD;
        } else if (currency === "TRY") {
          return (Number(price) * rateLIRAvsUSD) / rateEURvsUSD;
        } else {
          return Number(price);
        }
      default:
        return Number(price);
    }
  }, [
    price,
    currency,
    region,
    rateEURvsUSD,
    rateGBPvsUSD,
    rateLIRAvsUSD,
    rateRUPEEvsUSD,
  ]);
  return (
    Number(convertedFiat.toFixed(2)) * (1 - discount)
  );
};

export default useConvertedFiat;
