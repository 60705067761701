import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { deleteEventCardById } from "../../../api/event";
import { IEventCard } from "../../../utils/interface";
import Tile from "../../../components/tile";
import CarouselButton from "../../../components/CarouselButton";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import styles from "./index.module.css";

const LastServices = () => {
  const isMobile = useMediaQuery("(max-width: 450px)");
  const isTablet = useMediaQuery("(max-width: 800px)");
  const [latestEvents, setLatestEvents] = useState<IEventCard[]>(
    [] as IEventCard[]
  );
  const { carouselItem, allItems } = useAppContext();
  const { userInfo } = useUserContext();
  const latestEventCarousel = useRef<typeof OwlCarousel>(null);

  const { t } = useTranslation();

  useEffect(() => {
    let temp: never[] = [];
    temp = allItems.sort(
      (a: IEventCard, b: IEventCard) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    setLatestEvents(
      temp.filter(
        (eventcard: IEventCard) =>
          eventcard.category === "Category3" &&
          (eventcard.id !== "c5758ba0-5ecf-479d-a027-d6288d58f06e" ||
            userInfo?.user?.user_type === "ADMIN")
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allItems]);

  const handleDeleteEventCard = (eventCardId: string) => {
    deleteEventCardById(eventCardId)
      .then((res) => {
        if (res) {
          const updatedEvents = [...latestEvents].filter(
            (item: IEventCard) => item.id !== eventCardId
          );
          setLatestEvents(updatedEvents);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const titleHeader = () => {
    return (
      <div className="col-12" style={{ padding: 0 }}>
        <div className={styles.main__sub__title}>{t("latest services")}</div>
      </div>
    );
  };

  const next = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.next(500);
  };

  const prev = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.prev(500);
  };

  return latestEvents.length > 0 ? (
    <section className="row row--grid" style={{ margin: 0, padding: 0 }}>
      {titleHeader()}

      <div className="col-12 p__zero">
        <div className="carousel-wrapper">
          <div
            className="left__arrow_btn"
            onClick={() => prev(latestEventCarousel)}
          >
            <img src="/img/chevrons-left.svg" alt="left" />
          </div>
          <div
            className="right__arrow_btn"
            onClick={() => next(latestEventCarousel)}
          >
            <img src="/img/chevrons-right.svg" alt="right" />
          </div>
          <CarouselButton
            prev={() => prev(latestEventCarousel)}
            next={() => next(latestEventCarousel)}
          />
          <OwlCarousel
            className="owl-theme"
            margin={0}
            items={carouselItem}
            lazyLoad={true}
            autoplay={true}
            autoplayHoverPause={true}
            dots={isTablet ? false : true}
            dotsEach={latestEvents.length / 4}
            loop={latestEvents.length < 4 ? (isMobile ? true : false) : true}
            ref={latestEventCarousel as any}
          >
            {latestEvents.map((eventcard: IEventCard, i) => (
              <div key={`explorer_event_${i}`}>
                <Tile
                  eventCard={eventcard}
                  i={i}
                  deleteEventCard={handleDeleteEventCard}
                />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default LastServices;
