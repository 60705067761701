import { useState } from "react";
import { useAppContext } from "../../../../context/AppContext";
import { isMobile } from "react-device-detect";
import styles from "./index.module.css";

// import FrontOrchestraCenter from "../front_orchestra/center";
// import FrontOrchestraLeftCenter from "../front_orchestra/left_center";
import FrontOrchestraLeft from "../front_orchestra/left";
// import FrontOrchestraRightCenter from "../front_orchestra/right_center";
import FrontOrchestraRight from "../front_orchestra/right";

// import LogeSection1 from "../loge/section_1";
// import LogeSection2 from "../loge/section_2";
// import LogeSection3 from "../loge/section_3";
// import LogeSection4 from "../loge/section_4";
// import LogeSection5 from "../loge/section_5";
// import LogeSection6 from "../loge/section_6";

// import BalconyCenter from "../balcony/center";
// import BalconyLeftCenter from "../balcony/left_center";
import BalconyLeftTop from "../balcony/left_top";
import BalconyLeftBottom from "../balcony/left_bottom";
// import BalconyRightCenter from "../balcony/right_center";
import BalconyRightTop from "../balcony/right_top";
import BalconyRightBottom from "../balcony/right_bottom";

// import OrchestraCenter from "../orchestra/center";
// import OrchestraLeftCenter from "../orchestra/left_center";
import OrchestraLeft from "../orchestra/left";
// import OrchestraRightCenter from "../orchestra/right_center";
import OrchestraRight from "../orchestra/right";

const Ritz7thSeatMap = ({ eventCard }) => {
  const { setModal } = useAppContext();
  const [maxWidth, setMaxWidth] = useState(isMobile ? 400 : 1000);
  const zoomOut = () => {
    if (maxWidth < 1000) {
      setMaxWidth(maxWidth + 30);
    }
  };
  const zoomIn = () => {
    if (maxWidth > 400) {
      setMaxWidth(maxWidth - 30);
    }
  };

  return (
    <div className="container">
      <div className={styles.zoom_out}>
        <div onClick={() => zoomOut()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_out.svg"
            alt="zoom_out"
          />
        </div>
      </div>
      <div className={styles.zoom_in}>
        <div onClick={() => zoomIn()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_in.svg"
            alt="zoom_in"
          />
        </div>
      </div>
      <p className="seating__area_txt" style={{ width: maxWidth }}>
        1. Please select the seating area first
      </p>
      <div className="ritz__map_container" style={{ width: maxWidth }}>
        <div className={styles.loge_line}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src={
                eventCard.id === "59daf5fe-91a2-48fa-9d70-bf9282966250"
                  ? "/img/map/Ritz/loge_line_active.svg"
                  : "/img/map/Ritz/loge_line.svg"
              }
              alt="loge_line"
            />
          </div>
        </div>
        <div className={styles.orchestra_line}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src={
                eventCard.id === "63a8cf13-449d-4042-bafa-8ca7e6cb9df2"
                  ? "/img/map/Ritz/orchestra_line_active.svg"
                  : "/img/map/Ritz/orchestra_line.svg"
              }
              alt="orchestra_line"
            />
          </div>
        </div>
        <div className={styles.balcony_line}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src={
                eventCard.id === "7ae91e96-0fe2-4c0b-8aef-67a5c7d27f79"
                  ? "/img/map/Ritz/balcony_line_active.svg"
                  : "/img/map/Ritz/balcony_line.svg"
              }
              alt="balcony_line"
            />
          </div>
        </div>
        <div className={styles.front_orchestra_line}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src={
                eventCard.id === "13e27583-56af-43b4-bd62-3b3f77b7a1e3"
                  ? "/img/map/Ritz/front_orchestra_line_active.svg"
                  : "/img/map/Ritz/front_orchestra_line.svg"
              }
              alt="front_orchestra_line"
            />
          </div>
        </div>
        <div className={styles.front_orchestra_center}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <FrontOrchestraCenter eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/front_orchestra_center.svg"
              alt="front_orchestra_center"
            />
          </div>
        </div>
        <div className={styles.orchestra_center}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <OrchestraCenter eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/orchestra_center.svg"
              alt="orchestra_center"
            />
          </div>
        </div>
        <div className={styles.front_orchestra_left}>
          <div
            onClick={() =>
              eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872" &&
              setModal({
                open: true,
                children: <FrontOrchestraLeft eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872"
                  ? "/img/map/Ritz/front_orchestra_left_active.svg"
                  : "/img/map/Ritz/front_orchestra_left.svg"
              }
              alt="front_orchestra_left"
            />
          </div>
        </div>
        <div className={styles.orchestra_left}>
          <div
            onClick={() =>
              (eventCard.id === "714064bb-a1cc-4e1a-9675-041e25446587" ||
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872") &&
              setModal({
                open: true,
                children: <OrchestraLeft eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "714064bb-a1cc-4e1a-9675-041e25446587" ||
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872"
                  ? "/img/map/Ritz/orchestra_left_active.svg"
                  : "/img/map/Ritz/orchestra_left.svg"
              }
              alt="orchestra_left"
            />
          </div>
        </div>
        <div className={styles.front_orchestra_right}>
          <div
            onClick={() =>
              eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872" &&
              setModal({
                open: true,
                children: <FrontOrchestraRight eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872"
                  ? "/img/map/Ritz/front_orchestra_right_active.svg"
                  : "/img/map/Ritz/front_orchestra_right.svg"
              }
              alt="front_orchestra_right"
            />
          </div>
        </div>
        <div className={styles.orchestra_right}>
          <div
            onClick={() =>
              (eventCard.id === "714064bb-a1cc-4e1a-9675-041e25446587" ||
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872") &&
              setModal({
                open: true,
                children: <OrchestraRight eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "714064bb-a1cc-4e1a-9675-041e25446587" ||
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872"
                  ? "/img/map/Ritz/orchestra_right_active.svg"
                  : "/img/map/Ritz/orchestra_right.svg"
              }
              alt="orchestra_right"
            />
          </div>
        </div>
        <div className={styles.front_orchestra_left_center}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <FrontOrchestraLeftCenter eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/front_orchestra_left_center.svg"
              alt="front_orchestra_left_center"
            />
          </div>
        </div>
        <div className={styles.orchestra_left_center}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <OrchestraLeftCenter eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/orchestra_left_center.svg"
              alt="orchestra_left_center"
            />
          </div>
        </div>
        <div className={styles.front_orchestra_right_center}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <FrontOrchestraRightCenter eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/front_orchestra_right_center.svg"
              alt="front_orchestra_right_center"
            />
          </div>
        </div>
        <div className={styles.orchestra_right_center}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <OrchestraRightCenter eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/orchestra_right_center.svg"
              alt="orchestra_right_center"
            />
          </div>
        </div>
        <div className={styles.loge_section_1}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <LogeSection1 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/loge_section_1.svg"
              alt="loge_section_1"
            />
          </div>
        </div>
        <div className={styles.loge_section_3}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <LogeSection3 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/loge_section_3.svg"
              alt="loge_section_3"
            />
          </div>
        </div>
        <div className={styles.loge_section_5}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <LogeSection5 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/loge_section_5.svg"
              alt="loge_section_5"
            />
          </div>
        </div>
        <div className={styles.loge_section_2}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <LogeSection2 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/loge_section_2.svg"
              alt="loge_section_2"
            />
          </div>
        </div>
        <div className={styles.loge_section_4}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <LogeSection4 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/loge_section_4.svg"
              alt="loge_section_4"
            />
          </div>
        </div>
        <div className={styles.loge_section_6}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <LogeSection6 eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/loge_section_6.svg"
              alt="loge_section_6"
            />
          </div>
        </div>
        <div className={styles.balcony_center}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <BalconyCenter eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/balcony_center.svg"
              alt="balcony_center"
            />
          </div>
        </div>
        <div className={styles.balcony_left_center}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <BalconyLeftCenter eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/balcony_left_center.svg"
              alt="balcony_left_center"
            />
          </div>
        </div>
        <div className={styles.balcony_right_center}>
          <div
            // onClick={() =>
            //   setModal({
            //     open: true,
            //     children: <BalconyRightCenter eventCard={eventCard} />,
            //   })
            // }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src="/img/map/Ritz/balcony_right_center.svg"
              alt="balcony_right_center"
            />
          </div>
        </div>
        <div className={styles.balcony_left_top}>
          <div
            onClick={() =>
              eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872" &&
              setModal({
                open: true,
                children: <BalconyLeftTop eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872"
                  ? "/img/map/Ritz/balcony_left_top_active.svg"
                  : "/img/map/Ritz/balcony_left_top.svg"
              }
              alt="balcony_left_top"
            />
          </div>
        </div>
        <div className={styles.balcony_right_top}>
          <div
            onClick={() =>
              eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872" &&
              setModal({
                open: true,
                children: <BalconyRightTop eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872"
                  ? "/img/map/Ritz/balcony_right_top_active.svg"
                  : "/img/map/Ritz/balcony_right_top.svg"
              }
              alt="balcony_right_top"
            />
          </div>
        </div>
        <div className={styles.balcony_left_bottom}>
          <div
            onClick={() =>
              (eventCard.id === "35c62641-143f-4ec1-906d-3a3dfa33a797" ||
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872") &&
              setModal({
                open: true,
                children: <BalconyLeftBottom eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "35c62641-143f-4ec1-906d-3a3dfa33a797" ||
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872"
                  ? "/img/map/Ritz/balcony_left_bottom_active.svg"
                  : "/img/map/Ritz/balcony_left_bottom.svg"
              }
              alt="balcony_left_bottom"
            />
          </div>
        </div>
        <div className={styles.balcony_right_bottom}>
          <div
            onClick={() =>
              (eventCard.id === "35c62641-143f-4ec1-906d-3a3dfa33a797" ||
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872") &&
              setModal({
                open: true,
                children: <BalconyRightBottom eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "35c62641-143f-4ec1-906d-3a3dfa33a797" ||
                eventCard.id === "59c83c7e-0725-4f1c-a21b-f81b0ebae872"
                  ? "/img/map/Ritz/balcony_right_bottom_active.svg"
                  : "/img/map/Ritz/balcony_right_bottom.svg"
              }
              alt="balcony_right_bottom"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ritz7thSeatMap;
