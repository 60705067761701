import { useMediaQuery } from "usehooks-ts";
import { useAppContext } from "../../context/AppContext";

const TermsConditionModal = ({ terms }) => {
  const { setModal } = useAppContext();
  const isMobile = useMediaQuery("(max-width: 576px)");
  return (
    <div style={isMobile ? style.containerMobile : style.container}>
      <span style={style.btn_close} onClick={() => setModal({ open: false })}>
        <svg
          style={{ marginTop: "-4px" }}
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="#ffffff"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </span>
      <p style={style.event_name}>Terms and Conditions</p>
      <div
        className="description__text"
        dangerouslySetInnerHTML={{
          __html: terms,
        }}
      />
    </div>
  );
};

const style = {
  container: {
    maxWidth: "80%",
    width: "1000px",
    overflow: "auto",
    backgroundColor: "rgb(0, 18, 0)",
    color: "white",
    padding: "20px 40px 25px",
    borderRadius: "12px",
    border: "1px solid rgb(31, 49, 39)",
    margin: "auto",
    position: "relative",
  },
  containerMobile: {
    maxWidth: "95%",
    // width: "500px",
    backgroundColor: "rgb(0, 18, 0)",
    color: "white",
    padding: "20px 20px 30px",
    borderRadius: "12px",
    border: "1px solid rgb(31, 49, 39)",
    margin: "auto",
    position: "relative",
  },
  btn_close: {
    width: "20px",
    height: "25px",
    display: "inline-block",
    paddingBottom: "2px",
    borderRadius: "3px",
    verticalAlign: "top",
    cursor: "pointer",
    position: "absolute",
    top: "20px",
    right: "30px",
  },
  event_name: {
    textAlign: "left",
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 25,
  },
};

export default TermsConditionModal;
