import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { useMediaQuery } from "usehooks-ts";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as queryString from "query-string";

import EmailVerifyModal from "../../../components/custom_modals/email_verify_modal";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { login, verifyEmail } from "../../../api/auth";
import { validateEmail } from "../../../utils";
import { movePendingItems } from "../../../api/ticket";

const PageSignin = () => {
  const [cookies, setCookie, removeCookies] = useCookies();
  const { userInfo, setUserInfo } = useUserContext();
  const navigate = useNavigate();
  const { setLoading, setModal, addToast } = useAppContext();
  const [passType, setPassType] = useState("password");
  const [emailVerify, setEmailVerify] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [validations, setValidations] = useState({
    email: "",
    password: "",
  });
  const isMobile = useMediaQuery("(max-width: 768px)");

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [captcha, setCaptcha] = useState<string | null>(null);

  const { t } = useTranslation();

  const onChangeCaptcha = (e: string | null) => {
    setCaptcha(e);
  };

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (params.email_verify && !emailVerify) {
      const code = params.email_verify;

      setLoading(true);
      setEmailVerify(false);

      verifyEmail({ code: code, isMobile: isMobile })
        .then((res) => {
          if (res.success) {
            setLoading(false);
            setModal({ open: true, children: <EmailVerifyModal /> });
            return;
            // navigate('/signin');
          } else {
            setLoading(false);
            addToast("verification failed", {
              appearance: "error",
              autoDismiss: true,
            });
            navigate("/signin");
          }
        })
        .catch((err) => {
          setLoading(false);
          addToast("verification failed", {
            appearance: "error",
            autoDismiss: true,
          });
          navigate("/signin");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerify]);

  //// already login then go to home page
  useEffect(() => {
    if (userInfo) {
      const user_redirect = cookies.userRedirect;
      if (Boolean(user_redirect)) {
        removeCookies("userRedirect");
        navigate(`${user_redirect}`);
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleChange = (prop: string, value: string) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const handleLogin = async () => {
    if (!checkvalidations()) return;

    setLoading(true);

    await login(values)
      .then( async (res) => {
        if (res.success) {
          setCookie("userInfo", res.data, { path: '/' });
          setUserInfo(res.data);
          const pendingTicket = await movePendingItems({email: res.data.user.email});
          if (pendingTicket.success) {
            pendingTicket.status.map((item: string) => {
              addToast(item, { appearance: "info", autoDismiss: true });
              return true;
            })
          }
          setLoading(false);
          navigate("/");
        } else {
          setLoading(false);
          addToast(res.message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("Login failed", { appearance: "error", autoDismiss: true });
      });
  };

  const checkvalidations = () => {
    if (values.email === "") {
      setValidations({ email: "has-empty", password: "" });
      return false;
    } else if (!validateEmail(values.email)) {
      setValidations({ email: "has-danger", password: "" });
      return false;
    } else if (values.password === "") {
      setValidations({ email: "", password: "has-empty" });
      return false;
    } else {
      setValidations({ email: "", password: "" });
    }

    return true;
  };

  const borderStyle = (str: string) => {
    return str === "has-empty"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  return (
    <div className="sign__container">
      <div className="sign">
        {isMobile && (
          <Link to="/" className="home__link">
            &times;
          </Link>
        )}
        <div
          className="sign__content"
          style={{ padding: isMobile ? 0 : "25px 0 0" }}
        >
          <form action="#" className="sign__form">
            <p
              className="sign__main-title"
              style={{ marginTop: isMobile ? "10px" : "50px" }}
            >
              {t("sign in")}
            </p>

            <div className="sign__group">
              <p className="sign__form-label">{t("email")}</p>
              <input
                type="text"
                className="sign__input"
                placeholder={t("email")}
                value={values.email}
                style={borderStyle(validations.email)}
                onChange={(e) => handleChange("email", e.target.value)}
              />
              {validateEmail(values.email) && (
                <img
                  src="/img/green-check-circle.png"
                  className="img_eye"
                  alt="email success"
                />
              )}
              {validations.email === "has-empty" && (
                <span className="text-error">{t("email required")}*</span>
              )}
              {validations.email === "has-danger" && (
                <span className="text-error">{t("input correct format")}*</span>
              )}
            </div>

            <div className="sign__group">
              <div className="password__container">
                <p className="sign__form-label">{t("password")}</p>
                {!isMobile && (
                  <span className="sign__text-forget">
                    <Link to="/forget">{t("forgot password?")}</Link>
                  </span>
                )}
              </div>

              <input
                type={passType}
                className="sign__input"
                placeholder={t("password")}
                style={borderStyle(validations.password)}
                value={values.password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              {passType === "password" && (
                <img
                  src="/img/eye.png"
                  onClick={() => setPassType("text")}
                  className="img_eye"
                  alt="eye"
                />
              )}
              {passType === "text" && (
                <img
                  src="/img/eye-off.png"
                  onClick={() => setPassType("password")}
                  className="img_eye"
                  alt="eye"
                />
              )}
              {validations.password === "has-empty" && (
                <span className="text-error">{t("password required")}*</span>
              )}
            </div>

            {!isMobile && (
              <div className="sign__group sign__group--checkbox">
                <input
                  id="remember"
                  name="remember"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="remember">{t("remember me")}</label>
              </div>
            )}
            <ReCAPTCHA
              className="sign__recaptcha"
              ref={recaptchaRef as any}
              sitekey={process.env.REACT_APP_CAPTCHA as string}
              onChange={onChangeCaptcha}
            />
            <button
              className={captcha ? "sign__btn" : "sign__btn-disable"}
              type="button"
              onClick={captcha ? handleLogin : () => {}}
              // onClick={handleLogin}
            >
              {t("sign in")}
            </button>
            {isMobile && (
              <span className="sign__text-forget">
                <Link to="/forget">{t("forgot password?")}</Link>
              </span>
            )}
            <div className="sign__separated_container">
              <div className="sign__separated-line"></div>
              <span className="sign__text">
                {t("don't have an account?")}&nbsp;
              </span>
              <div className="sign__separated-line"></div>
            </div>

            <Link
              to="/signup"
              className="sign__btn-second"
              style={{ marginBottom: isMobile ? "100px" : 0 }}
            >
              {t("sign up")}
            </Link>
          </form>
        </div>
      </div>
      <div className="sign_bottom"></div>
    </div>
  );
};

export default PageSignin;
