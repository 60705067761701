import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const Video = ({ path }) => {
  const videoRef = useRef();
  const { t } = useTranslation();
  useEffect(() => {
    videoRef.current?.load();
  }, [path]);

  return (
    <video
      autoPlay
      playsInline
      loop
      controls
      muted
      className={styles.event__video_item}
      id="video"
      ref={videoRef}
    >
      <source
        src={`${process.env.REACT_APP_ASSET_BASE_URL}/${path}`}
        type="video/mp4"
      />
      {t("Your browser does not support thte video tag.")}
    </video>
  );
};

const VideoPlayer = ({ path }) => {

  return (
    <div className={styles.event__video_contain}>
      <Video path={path} />
    </div>
  );
};

export default VideoPlayer;
