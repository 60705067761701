import { Link } from "react-router-dom";
import styles from "./index.module.css";

const BigHero = () => {

  return (
    <div className={styles.early__bird_home}>
      <Link
        to={`collection/e76cfeaa-d043-4c79-b7e1-d65c472fc993`}
        className={styles.big_hero}
      >
        <img
          className={styles.big__hero_mobile}
          alt="Hero"
          src="/img/hero/anatolia_mobile.jpg"
        />
        <img
          className={styles.big__hero_desk}
          alt="Hero"
          src="/img/hero/anatolia.jpg"
        />
        <div className={styles.early__bird_bottom}>
          <p className={styles.early__bird_text}>
            <span className={styles.early__bird_date}>BUY SMART TICKETS →</span>
          </p>
        </div>
      </Link>
    </div>
  );
};

export default BigHero;
