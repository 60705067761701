import { Link } from "react-router-dom";
import DeletePopper from "./DeletePopper";
import { useUserContext } from "../context/UserContext";

const Collection = ({ collection, handleDelete }) => {
  const { userInfo } = useUserContext();

  return (
    <div className="collection">
      {((userInfo && userInfo.user.user_type === "ADMIN") ||
        (userInfo &&
          userInfo.user.user_type === "TURKISH ADMIN" &&
          collection?.creator?.id === userInfo.user.id)) && (
        <DeletePopper
          onClickDelete={() => {
            handleDelete(collection.id);
          }}
        />
      )}
      <Link
        to={`/collection/${collection?.id}`}
        className="collection__cover"
        style={{
          background: `url(${process.env.REACT_APP_ASSET_BASE_URL}/${collection?.picture_background})`,
          backgroundSize: "cover",
          backgroundColor: "rgba(255, 255, 255, 0.07)",
        }}
      />
      <Link to={`/collection/${collection?.id}`}>
        <div className="collection__meta">
          <div className="collection__avatar">
            <img
              src={`${process.env.REACT_APP_ASSET_BASE_URL}/${collection?.picture_small}`}
              alt="collection small"
            />
          </div>
          <div className="collection__name">
            {collection?.name}
          </div>
          <span className="collection__number">{collection?.category}</span>
        </div>
      </Link>
    </div>
  );
};

export default Collection;
