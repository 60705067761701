import { useState, useMemo } from "react";
// import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import Search from "../../../components/Search";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { ITicket } from "../../../utils/interface";
import { sendOnlyMail, downloadQRcode } from "../../../api/message";
import DigitalItem from "../../../components/digital_item";
import styles from "./index.module.css";

const DigitalItems = () => {
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("all");
  // const { t } = useTranslation();
  const { userInfo } = useUserContext();
  const { setLoading, addToast, digitalItems } = useAppContext();
  const categoriedItems = useMemo(
    () =>
      digitalItems.filter((item: ITicket) =>
        tag === "all"
          ? item
          : tag === "smart tickets" && item.eventcard.category === "Category1"
          ? item
          : tag === "digital arts" && item.eventcard.category === "Category2"
          ? item
          : tag === "services" && item.eventcard.category === "Category3"
          ? item
          : null
      ),
    [digitalItems, tag]
  );
  const searchedItems = useMemo(
    () =>
      categoriedItems.filter((item: ITicket) =>
        search === ""
          ? item
          : item.eventcard.name.toLowerCase().includes(search.toLowerCase())
      ),
    [search, categoriedItems]
  );
  const isMobile = useMediaQuery("(max-width: 992px)");
  const wallet = useMemo(() => {
    return userInfo?.user?.wallet_address;
  }, [userInfo]);

  const ticketBuyTime = (ticket: ITicket) => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    const buyDate = new Date(ticket.createdAt);
    buyDate.setHours(0);
    buyDate.setMinutes(0);
    buyDate.setSeconds(0);
    if (today.toDateString() === buyDate.toDateString()) return "Today";
    const period = Math.floor(
      (today.getTime() - buyDate.getTime()) / 1000 / 3600 / 24
    );
    if (period === 1) return " A day ago ";
    else if (period < 7) return `${period} days ago`;
    else if (period === 7) return "A week ago";
    else if (period < 29) return `${Math.ceil(period / 7)} weeks ago`;
    else if (period < 32) return "A month ago";
    else return buyDate.toDateString();
  };

  const sendMail = async (
    ticket: ITicket,
    totalCurrencyPrice: string,
    ticketCurrencyPrice: string
  ) => {
    setLoading(true);
    let url = new URL(ticket.tokenURL);
    let params = new URLSearchParams(url.search);
    let token_id = params.get("a");
    const emailData = {
      mobile: isMobile,
      email: userInfo.user.email,
      user_name: userInfo.user.name,
      ticket_number: ticket.count,
      totalPrice: totalCurrencyPrice,
      ticketPrice: ticketCurrencyPrice,
      ticket_type: ticket.collection.category,
      collection_name: ticket.collection.name,
      scan: ticket.eventcard.scan,
      item: ticket.eventcard,
      addons: JSON.parse(ticket.eventcard.addons),
      start_now:
        ticket.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
      date: ticket.service_date
        ? new Date(ticket.service_date).toString().substring(0, 21)
        : new Date(ticket.eventcard.date).toString().substring(0, 21),
      end_date: new Date(ticket.eventcard.end_date).toString().substring(0, 21),
      seats: ticket.seats !== "" ? JSON.parse(ticket.seats) : null,
      wallet,
      nft_address: ticket.nft_address,
      token_id,
    };
    await sendOnlyMail({ emailData, ticketId: ticket.id })
      .then((res) => {
        addToast("Sent Email", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
    setLoading(false);
  };

  const downloadQR = async (
    ticket: ITicket,
    totalCurrencyPrice: string,
    ticketCurrencyPrice: string
  ) => {
    let url = new URL(ticket.tokenURL);
    let params = new URLSearchParams(url.search);
    let token_id = params.get("a");
    const emailData = {
      mobile: isMobile,
      email: userInfo.user.email,
      user_name: userInfo.user.name,
      ticket_number: ticket.count,
      totalPrice: totalCurrencyPrice,
      ticketPrice: ticketCurrencyPrice,
      ticket_type: ticket.collection.category,
      collection_name: ticket.collection.name,
      scan: ticket.eventcard.scan,
      item: ticket.eventcard,
      addons: JSON.parse(ticket.eventcard.addons),
      start_now:
        ticket.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
      date: ticket.service_date
        ? new Date(ticket.service_date).toString().substring(0, 21)
        : new Date(ticket.eventcard.date).toString().substring(0, 21),
      end_date: new Date(ticket.eventcard.end_date).toString().substring(0, 21),
      seats: ticket.seats !== "" ? JSON.parse(ticket.seats) : null,
      wallet,
      nft_address: ticket.nft_address,
      token_id,
    };
    setLoading(true);
    await downloadQRcode({ emailData, ticketId: ticket.id })
      .then((res) => {
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          downloadFromUrl(
            process.env.REACT_APP_API_BASE_URL +
              "/api/upload/get_file?path=assets/uploads/pdfs/" +
              element,
            element
          );
        }
      })
      .catch((err) => {
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
    setLoading(false);
  };

  const downloadFromUrl = (url: string, fileName: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  };
  return (
    <div className={styles.wallet_content}>
      <div className={styles.wallet__title_part}>
        <img src="/img/wallet/hexagon.svg" alt="currencies" />
        <div>
          <p className={styles.wallet__content_title}>Digital Items</p>
          <p className={styles.assets_number}>{digitalItems.length} assets</p>
        </div>
      </div>
      <div className={styles.currencies__top_part}>
        <div className="category__select_btns">
          <button
            className={
              tag === "all"
                ? "category__active--tag"
                : "category__inactive--tag"
            }
            onClick={() => setTag("all")}
          >
            All
          </button>
          <button
            className={
              tag === "smart tickets"
                ? "category__active--tag"
                : "category__inactive--tag"
            }
            onClick={() => setTag("smart tickets")}
          >
            Smart Tickets
          </button>
          <button
            className={
              tag === "digital arts"
                ? "category__active--tag"
                : "category__inactive--tag"
            }
            onClick={() => setTag("digital arts")}
          >
            Digital Assets
          </button>
          <button
            className={
              tag === "services"
                ? "category__active--tag"
                : "category__inactive--tag"
            }
            onClick={() => setTag("services")}
          >
            Services
          </button>
        </div>
        <div className={styles.digital__item_search}>
          <div>
            <Search onChange={setSearch} placeholder={"Search Digital Item"} />
          </div>
          {/* <button className={styles.add__asset_btn} style={{ height: 44 }}>
            + Add Asset
          </button> */}
        </div>
      </div>
      {searchedItems.map((ticket: ITicket, index: number) => (
        <DigitalItem
          ticket={ticket}
          key={index}
          ticketBuyTime={ticketBuyTime}
          sendMail={sendMail}
          downloadQRcode={downloadQR}
        />
      ))}
    </div>
  );
};

export default DigitalItems;
