import { useState } from "react";
import { useAppContext } from "../../../../context/AppContext";
import { isMobile } from "react-device-detect";
// import BALCC from "../balcony/center";
// import BALLC from "../balcony/leftcenter";
// import BALRC from "../balcony/rightcenter";
// import BALCR from "../balcony/right";
// import BALCL from "../balcony/left";
import BALCLB from "../balcony/leftbottom";
import BALCRB from "../balcony/rightbottom";
import BALLCB from "../balcony/leftcenterbottom";
import BALRCB from "../balcony/rightcenterbottom";
// import ClubLevel from "../mezzanine/clublevel";
import MEZZLC from "../mezzanine/leftcenter";
import MEZZRC from "../mezzanine/rightcenter";
import MEZZL from "../mezzanine/left";
import MEZZR from "../mezzanine/right";
// import LowerMezz from "../mezzanine/lower";
import ORCHC from "../orchestra/center";
import ORCHL from "../orchestra/left";
import ORCHR from "../orchestra/right";
import ORCHRC from "../orchestra/rightcenter";
import ORCHLC from "../orchestra/leftcenter";
import ORCHLT from "../orchestra/lefttop";
import ORCHRT from "../orchestra/righttop";

import styles from "./index.module.css";

const AureneSeatMap = ({ eventCard }) => {
  const { setModal } = useAppContext();
  const [maxWidth, setMaxWidth] = useState(isMobile ? 400 : 700);
  const zoomOut = () => {
    if (maxWidth < 1000) {
      setMaxWidth(maxWidth + 30);
    }
  };
  const zoomIn = () => {
    if (maxWidth > 400) {
      setMaxWidth(maxWidth - 30);
    }
  };

  return (
    <div className="container">
      <div className={styles.zoom_out}>
        <div onClick={() => zoomOut()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_out.svg"
            alt="zoom_out"
          />
        </div>
      </div>
      <div className={styles.zoom_in}>
        <div onClick={() => zoomIn()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_in.svg"
            alt="zoom_in"
          />
        </div>
      </div>
      <p className="seating__area_txt" style={{ width: maxWidth }}>
        1. Please select the seating area first
      </p>
      <div className="ahoy__map_container" style={{ width: maxWidth }}>
        <div className={styles.stage}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src="/img/map/Aurene/stage.svg"
              alt="stage"
            />
          </div>
        </div>

        <div className={styles.au_96}>
          <div className={styles.sit_contain}>
            {/* <div onClick={() => setModal({ open: true, children: <BALCC eventCard={eventCard} /> })} className={styles.sit_contain}> */}
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/96.svg"
              alt="BALCC"
            />
          </div>
        </div>
        <div className={styles.au_109}>
          <div className={styles.sit_contain}>
            {/* <div onClick={() => setModal({ open: true, children: <BALRC eventCard={eventCard} /> })} className={styles.sit_contain}> */}
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/109.svg"
              alt="109"
            />
          </div>
        </div>
        <div className={styles.au_38}>
          <div className={styles.sit_contain}>
            {/* <div onClick={() => setModal({ open: true, children: <BALCR eventCard={eventCard} /> })} className={styles.sit_contain}> */}
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/38.svg"
              alt="38"
            />
          </div>
        </div>
        <div className={styles.au_109_2}>
          <div className={styles.sit_contain}>
            {/* <div onClick={() => setModal({ open: true, children: <BALLC eventCard={eventCard} /> })} className={styles.sit_contain}> */}
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/109_2.svg"
              alt="109_2"
            />
          </div>
        </div>
        <div className={styles.au_38_2}>
          <div className={styles.sit_contain}>
            {/* <div onClick={() => setModal({ open: true, children: <BALCL eventCard={eventCard} /> })} className={styles.sit_contain}> */}
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/38_2.svg"
              alt="38_2"
            />
          </div>
        </div>
        <div className={styles.au_56_1}>
          <div
            onClick={() =>
              eventCard.id === "42549e16-cd49-49a1-b265-2a2f4ea5da82" &&
              setModal({
                open: true,
                children: <BALLCB eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "42549e16-cd49-49a1-b265-2a2f4ea5da82"
                  ? "/img/map/Aurene/56_1_active.svg"
                  : "/img/map/Aurene/56_1.svg"
              }
              alt="56_1"
            />
          </div>
        </div>
        <div className={styles.au_56_3}>
          <div
            onClick={() =>
              eventCard.id === "42549e16-cd49-49a1-b265-2a2f4ea5da82" &&
              setModal({
                open: true,
                children: <BALCLB eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "42549e16-cd49-49a1-b265-2a2f4ea5da82"
                  ? "/img/map/Aurene/56_3_active.svg"
                  : "/img/map/Aurene/56_3.svg"
              }
              alt="56_3"
            />
          </div>
        </div>
        <div className={styles.au_56_2}>
          <div
            onClick={() =>
              eventCard.id === "42549e16-cd49-49a1-b265-2a2f4ea5da82" &&
              setModal({
                open: true,
                children: <BALRCB eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "42549e16-cd49-49a1-b265-2a2f4ea5da82"
                  ? "/img/map/Aurene/56_2_active.svg"
                  : "/img/map/Aurene/56_2.svg"
              }
              alt="56_2"
            />
          </div>
        </div>
        <div className={styles.au_56_4}>
          <div
            onClick={() =>
              eventCard.id === "42549e16-cd49-49a1-b265-2a2f4ea5da82" &&
              setModal({
                open: true,
                children: <BALCRB eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "42549e16-cd49-49a1-b265-2a2f4ea5da82"
                  ? "/img/map/Aurene/56_4_active.svg"
                  : "/img/map/Aurene/56_4.svg"
              }
              alt="56_4"
            />
          </div>
        </div>
        <div className={styles.au_1}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/1.svg"
              alt="1"
            />
          </div>
        </div>
        <div className={styles.au_2}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/1.svg"
              alt="2"
            />
          </div>
        </div>
        <div className={styles.au_71}>
          <div className={styles.sit_contain}>
            {/* <div onClick={() => setModal({ open: true, children: <ClubLevel eventCard={eventCard} /> })} className={styles.sit_contain}> */}
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/71.svg"
              alt="71"
            />
          </div>
        </div>
        <div className={styles.au_53}>
          <div
            onClick={() =>
              eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6" &&
              setModal({
                open: true,
                children: <MEZZRC eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6"
                  ? "/img/map/Aurene/53_active.svg"
                  : "/img/map/Aurene/53.svg"
              }
              alt="53"
            />
          </div>
        </div>
        <div className={styles.au_53_2}>
          <div
            onClick={() =>
              eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6" &&
              setModal({
                open: true,
                children: <MEZZLC eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6"
                  ? "/img/map/Aurene/53_2_active.svg"
                  : "/img/map/Aurene/53_2.svg"
              }
              alt="53_2"
            />
          </div>
        </div>
        <div className={styles.au_108}>
          <div
            onClick={() =>
              eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6" &&
              setModal({
                open: true,
                children: <MEZZR eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6"
                  ? "/img/map/Aurene/108_active.svg"
                  : "/img/map/Aurene/108.svg"
              }
              alt="108"
            />
          </div>
        </div>
        <div className={styles.au_108_2}>
          <div
            onClick={() =>
              eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6" &&
              setModal({
                open: true,
                children: <MEZZL eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6"
                  ? "/img/map/Aurene/108_2_active.svg"
                  : "/img/map/Aurene/108_2.svg"
              }
              alt="108_2"
            />
          </div>
        </div>
        <div className={styles.au_89}>
          <div className={styles.sit_contain}>
            {/* <div onClick={() => setModal({ open: true, children: <LowerMezz eventCard={eventCard} /> })} className={styles.sit_contain}> */}
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/89.svg"
              alt="89"
            />
          </div>
        </div>
        <div className={styles.au_3}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/1.svg"
              alt="3"
            />
          </div>
        </div>
        <div className={styles.au_4}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/1.svg"
              alt="4"
            />
          </div>
        </div>
        <div className={styles.au_176}>
          <div
            onClick={() =>
              eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a" &&
              setModal({
                open: true,
                children: <ORCHLC eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a"
                  ? "/img/map/Aurene/176_active.svg"
                  : "/img/map/Aurene/176.svg"
              }
              alt="176"
            />
          </div>
        </div>
        <div className={styles.au_176_2}>
          <div
            onClick={() =>
              eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a" &&
              setModal({
                open: true,
                children: <ORCHRC eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a"
                  ? "/img/map/Aurene/176_2_active.svg"
                  : "/img/map/Aurene/176_2.svg"
              }
              alt="176_2"
            />
          </div>
        </div>
        <div className={styles.au_161}>
          <div
            onClick={() =>
              eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6" &&
              setModal({
                open: true,
                children: <ORCHRT eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6"
                  ? "/img/map/Aurene/161_active.svg"
                  : "/img/map/Aurene/161.svg"
              }
              alt="161"
            />
          </div>
        </div>
        <div className={styles.au_161_2}>
          <div
            onClick={() =>
              eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6" &&
              setModal({
                open: true,
                children: <ORCHLT eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "28b8f43f-1b0d-49e6-97c1-063591221da6"
                  ? "/img/map/Aurene/161_2_active.svg"
                  : "/img/map/Aurene/161_2.svg"
              }
              alt="161_2"
            />
          </div>
        </div>
        <div className={styles.au_304}>
          <div
            onClick={() =>
              eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a" &&
              setModal({
                open: true,
                children: <ORCHC eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a"
                  ? "/img/map/Aurene/304_active.svg"
                  : "/img/map/Aurene/304.svg"
              }
              alt="304"
            />
          </div>
        </div>
        <div className={styles.au_247}>
          <div
            onClick={() =>
              eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a" &&
              setModal({
                open: true,
                children: <ORCHL eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a"
                  ? "/img/map/Aurene/247_active.svg"
                  : "/img/map/Aurene/247.svg"
              }
              alt="247"
            />
          </div>
        </div>
        <div className={styles.au_247_2}>
          <div
            onClick={() =>
              eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a" &&
              setModal({
                open: true,
                children: <ORCHR eventCard={eventCard} />,
              })
            }
            className={styles.sit_contain}
          >
            <img
              className={styles.pieceMap}
              src={
                eventCard.id === "1e111356-6853-46c7-98a8-dbb28b79501a"
                  ? "/img/map/Aurene/247_2_active.svg"
                  : "/img/map/Aurene/247_2.svg"
              }
              alt="247_2"
            />
          </div>
        </div>
        <div className={styles.au_46}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/46.svg"
              alt="46"
            />
          </div>
        </div>
        <div className={styles.au_46_2}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/46_2.svg"
              alt="46_2"
            />
          </div>
        </div>
        <div className={styles.au_5}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/5.svg"
              alt="5"
            />
          </div>
        </div>
        <div className={styles.au_6}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/5.svg"
              alt="6"
            />
          </div>
        </div>
        <div className={styles.au_7}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/7.svg"
              alt="7"
            />
          </div>
        </div>
        <div className={styles.au_8}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/7.svg"
              alt="8"
            />
          </div>
        </div>
        <div className={styles.au_9}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/9.svg"
              alt="9"
            />
          </div>
        </div>
        <div className={styles.au_10}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/10.svg"
              alt="10"
            />
          </div>
        </div>
        <div className={styles.au_11}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/7.svg"
              alt="11"
            />
          </div>
        </div>
        <div className={styles.au_12}>
          <div className={styles.sit_contain}>
            <img
              className={styles.pieceMap}
              src="/img/map/Aurene/7.svg"
              alt="12"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AureneSeatMap;
