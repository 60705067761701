import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";

const FilterCategory = ({
  currentBtn,
  currentTag,
  setCurrentBtn,
  setCurrentTag,
}) => {
  const isMobile = useMediaQuery("(max-width: 450px)");
  const { t } = useTranslation();
  return (
    <div className="explorer__top-buttons">
      <div className="explorer__top-btn--group">
        <button
          className={
            currentBtn === "filter"
              ? "explorer__top-active--btn"
              : "explorer__top-inactive--btn"
          }
          onClick={() =>
            currentBtn === "" ? setCurrentBtn("filter") : setCurrentBtn("")
          }
        >
          <img src="/img/icons/filter.svg" alt="filter" />
          <p className="explorer__btn-text">{t("filter")}</p>
          {!isMobile && <img src="/img/icons/dropdown.svg" alt="dropdown" />}
        </button>
        {currentBtn === "filter" && (
          <div
            className={
              isMobile ? "explorer__top-btn-opts-mb" : "explorer__top-btn-opts"
            }
          >
            <div className="explorer__top-checkbox">
              <input
                id="all"
                name="all"
                type="checkbox"
                checked={currentTag === "all"}
                onChange={() => {
                  setCurrentTag("all");
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="all">{t("all")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="event"
                name="event"
                type="checkbox"
                checked={currentTag === "CreditCard"}
                onChange={() => {
                  setCurrentTag("CreditCard");
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="event">{t("credit card sale")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="digital"
                name="digital"
                type="checkbox"
                checked={currentTag === "Cash"}
                onChange={() => {
                  setCurrentTag("Cash");
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="digital">{t("cash sale")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="service"
                name="service"
                type="checkbox"
                checked={currentTag === "Crypto"}
                onChange={() => {
                  setCurrentTag("Crypto");
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="service">{t("crypto sale")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="free"
                name="free"
                type="checkbox"
                checked={currentTag === "Free"}
                onChange={() => {
                  setCurrentTag("Free");
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="free">{t("free influencer")}</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterCategory;
