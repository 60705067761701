/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import {
  deleteEventCardById,
  getCollectionById,
  getEventCardInCollection,
  updateEventLike,
} from "../../../api/event";
import { ICollection, IEventCard } from "../../../utils/interface";
import Tile from "../../../components/tile";
import Pagination from "../../../components/pagination";
import Search from "../../../components/Search";
import CollectionFilterSort from "../../../components/CollectionFilterSort";

const PageCollection = () => {
  const { id } = useParams();
  const { referral } = useParams();
  const location = useLocation();
  const {
    setLoading,
    cPageNumber,
    setCPageNumber,
  } = useAppContext();
  const [eventCards, setEventCards] = useState<IEventCard[]>(
    [] as IEventCard[]
  );
  const [allEvents, setAllEvents] = useState<IEventCard[]>([] as IEventCard[]);
  const [collection, setCollection] = useState<ICollection>({} as ICollection);
  const [pageIndex, setPageIndex] = useState(0);
  const [itemIndex, setItemIndex] = useState(cPageNumber);
  const [ownerCnt, setOwnerCnt] = useState(0);
  const { userInfo } = useUserContext();
  const [currentTag, setCurrentTag] = useState("all");
  const [currentBtn, setCurrentBtn] = useState("");
  const [sort, setSort] = useState(4);
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState(8);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 628px)");
  const isMiddle = useMediaQuery("(max-width: 1256px)");
  const isSmall = useMediaQuery("(max-width: 992px)");
  const { t } = useTranslation();

  useEffect(() => {
    // setItemIndex(0);
    if (isMobile) {
      setPageCount(4);
    } else {
      setPageCount(8);
    }
  }, [isMiddle, isSmall, isMobile]);

  const leftClick = () => {
    if (itemIndex > 0) {
      let _itemIndex = itemIndex;
      _itemIndex--;
      setItemIndex(_itemIndex);
    }
  };

  const rightClick = () => {
    if ((itemIndex + 1) * pageCount < eventCards.length) {
      let _itemIndex = itemIndex;
      _itemIndex++;
      setItemIndex(_itemIndex);
    }
  };

  useEffect(() => {
    setCPageNumber(itemIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIndex]);

  useEffect(() => {
    setLoading(true);
    getCollectionById(id).then((res) => {
      if (res.success) {
        if (res.collection.deleted === 1) {
          navigate("/");
        } else {
          setCollection(res.collection);
          if (res.collection.referral !== null) {
            let links = JSON.parse(res.collection.referral);
            if (links.find((item: any) => item.code === referral)) {
              localStorage.setItem(
                "referral",
                JSON.stringify({
                  id: res.collection.id,
                  referral: referral,
                })
              );
            }
          }
        }
      }
    });
    getEventCardInCollection(id).then((res) => {
      if (res.success) {
        let temp: never[] = [];
        temp = res.eventcards.sort(
          (a: IEventCard, b: IEventCard) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setAllEvents([...temp]);
        sortEvents([...temp]);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, referral]);

  useEffect(() => {
    const creators: string[] = [];
    eventCards.map((eventcard: IEventCard) => {
      if (!creators.includes(eventcard.creator.id))
        creators.push(eventcard.creator.id);
      return 0;
    });
    setOwnerCnt(creators.length);
  }, [eventCards]);

  const onClickLike = (index: number) => {
    if (!userInfo) navigate("/signin");
    else {
      let likes: string[] = [];
      try {
        likes = JSON.parse((eventCards[index] as IEventCard).likes_number);
      } catch (err) {
        likes = [];
        console.log(err);
      }
      if (typeof likes !== "object" || likes === null) likes = [];
      const userId = userInfo.user.id;
      if (likes.includes(userId)) {
        const index = likes.indexOf(userId);
        likes.splice(index, 1);
      } else {
        likes.push(userId);
      }
      updateEventLike({
        id: (eventCards[index] as IEventCard).id,
        likes_number: JSON.stringify(likes),
      }).then((res) => {
        if (res.success) {
          const _eventCards = [...eventCards];
          (_eventCards[index] as IEventCard).likes_number = JSON.stringify(
            likes
          );
          setEventCards(_eventCards);
        }
      });
    }
  };

  const handleDeleteEventCard = (eventCardId: string) => {
    deleteEventCardById(eventCardId)
      .then((res) => {
        const updatedEvents = [...eventCards].filter(
          (eventcard: IEventCard) => eventcard.id !== eventCardId
        );
        setEventCards(updatedEvents);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLikesNumber: (eventCard: any) => number = (eventCard: any) => {
    let likes = [];
    try {
      likes = JSON.parse(eventCard.likes_number);
    } catch (err) {
      likes = [];
    }
    if (typeof likes !== "object" || likes === null) likes = [];
    if (likes) return likes.length;
    else return 0;
  };

  const sortEvents = (events: IEventCard[]) => {
    let _temp: any[] = [];
    if (currentTag === "all") {
      _temp = [...events];
    } else {
      _temp = events.filter((event: any) => event.category === currentTag);
    }
    let temp: any[] = [];
    if (sort === 0) {
      temp = _temp.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    } else if (sort === 1) {
      temp = _temp.sort((a: any, b: any) => Number(a.price) - Number(b.price));
    } else if (sort === 2) {
      temp = _temp.sort((a: any, b: any) => Number(b.price) - Number(a.price));
    } else if (sort === 3) {
      temp = _temp.sort(
        (a: any, b: any) => getLikesNumber(b) - getLikesNumber(a)
      );
    } else if (sort === 4) {
      temp = _temp.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }
    let _res = _temp.filter((event) =>
      search === ""
        ? event
        : event.name.toLowerCase().includes(search.toLowerCase())
    );
    // setEventCards([..._res]);
    const d = new Date();
    setEventCards(
      _res.filter(
        (eventcard: IEventCard) =>
          eventcard.collection.name !==
            "Ha-Ash Tour USA 2023 VIP Meet & Greet" ||
          new Date(eventcard.date).getTime() - d.getTime() > 0
      )
    );
  };

  useEffect(() => {
    sortEvents(allEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, currentTag, sort]);

  return (
    <div className="collection__contain_block">
      {/* banner image */}
      <div
        className="main__author collection__banner"
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_ASSET_BASE_URL
          }/${
            isMobile
              ? collection?.picture_mobile_large
                ? collection?.picture_mobile_large
                : collection.picture_background
              : collection?.picture_large
          })`,
        }}
      >
        &nbsp;
      </div>
      <div
        className="container"
        style={{
          padding: isMiddle ? "0px 16px" : 0,
          maxWidth: 1224,
        }}
      >
        <div
          className={isMobile ? " " : "row row--grid"}
          style={{ width: "100%", marginLeft: 0 }}
        >
          <div className="col-12 col-xl-12" style={{ padding: 0 }}>
            <div className="collection__author-meta">
              <div className="collection__avatar-container">
                {collection?.picture_small && (
                  <Link to="#" className="collection__author-avatar">
                    <div>
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_ASSET_BASE_URL}/${collection?.picture_small}`}
                      />
                    </div>
                  </Link>
                )}
                <div className="collection__avatar-name">
                  {collection.name && (
                    <h1 className="collection__author-name">
                      <Link to="#">{collection?.name}</Link>
                      <div
                        className="sellers-list__author--verified"
                        style={{ marginLeft: 4 }}
                      />
                    </h1>
                  )}
                  {collection?.creator?.name && (
                    <h2 className="collection__author-nickname">
                      <span>by&nbsp;</span>
                      <Link to="#">@{collection?.creator?.name}</Link>
                    </h2>
                  )}
                </div>
              </div>

              {id === "d6db3b0e-bf6b-4fd1-8778-7ff65dfd27ab" && (
                <p className="collection__author-description">
                  "Urgent{" "}
                  <span style={{ color: "#6a4dfd" }}>Customer Support</span>{" "}
                  during ticket purchase contact with What's Upp only line{" "}
                  <span style={{ color: "#6a4dfd" }}>+44 7500 206648</span> or{" "}
                  <span style={{ color: "#6a4dfd" }}>
                    {" "}
                    jr@bksbackstage.io"{" "}
                  </span>
                  Please do not forget when you are purchasing ticket you can
                  pay by Credit card or USDT as well and you can also claim your
                  Nft other than your ticket barcode . Able to cliam your nft
                  you have to have Bnb wallet connected .
                </p>
              )}
              <p className="collection__author-description">
                {collection?.description}
              </p>
            </div>
            {userInfo &&
              !isMobile &&
              (userInfo.user.user_type === "ADMIN" ||
                (userInfo.user.user_type === "TURKISH ADMIN" &&
                  userInfo.user.id === collection?.creator?.id)) && (
                <div className="collection__btn_container">
                  <Link to={`/event/eventcard/create/${collection?.id}`}>
                    <button type="button" className="sign__btn collection__btn">
                      {t("create item")}
                    </button>
                  </Link>
                  <Link to={`/collection/${collection?.id}/referral_link`}>
                    <button type="button" className="sign__btn collection__btn">
                      {t("Referral links")}
                    </button>
                  </Link>
                </div>
              )}
          </div>
          <div
            className="collection__filter_block"
            style={{
              marginTop: userInfo?.user?.user_type === "ADMIN" ? 20 : 44,
            }}
          >
            <Search onChange={setSearch} placeholder={t("search the item")} />
            <CollectionFilterSort
              currentBtn={currentBtn}
              currentTag={currentTag}
              sort={sort}
              setCurrentBtn={setCurrentBtn}
              setCurrentTag={setCurrentTag}
              setSort={setSort}
            />
          </div>

          <div style={{ width: "100%" }}>
            <div
              className="coll_grid-container"
              style={{ margin: isMiddle ? "auto" : 0 }}
            >
              {eventCards.map(
                (eventCard: IEventCard, i) =>
                  i >= (pageIndex + itemIndex) * pageCount &&
                  i < (pageIndex + itemIndex + 1) * pageCount && (
                    <div key={`explorer_event_${i}`}>
                      {eventCard.id !==
                        "c5758ba0-5ecf-479d-a027-d6288d58f06e" && (
                        <Tile
                          eventCard={eventCard}
                          i={i}
                          carousel={true}
                          deleteEventCard={handleDeleteEventCard}
                        />
                      )}
                    </div>
                  )
              )}
            </div>
            <Pagination
              items={eventCards}
              setItemIndex={setItemIndex}
              pageIndex={pageIndex}
              itemIndex={itemIndex}
              leftClick={leftClick}
              rightClick={rightClick}
              count={pageCount}
            />
          </div>
        </div>
        <div className="bottom_block"></div>
      </div>
    </div>
  );
};

export default PageCollection;
