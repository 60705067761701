import { Link } from "react-router-dom";

const TermsAndConditions = () => {

  return (
    <div className="container terms__container">
      <div className="row row--grid">
        <div className="col-12">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">
              Terms and Conditions
            </li>
          </ul>
        </div>

        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>Terms and Conditions</h1>
            <p>Last updated: July [●], 2022</p>
            <ol>
              <li>
                <h4>General purpose and scope of the Terms and Conditions</h4>
                <ol>
                  <li>
                  Bksbackstage OÜ, Tartu mnt 67/1-13b, 10115 Tallinn, Estonia (“Backstage”, “we”, “us”, “our”), incorporated and registered in Estonia with registry code 16263612, is a platform, which provides services based on internet and blockchain technologies, which are offered via the website <a href="https://www.bksbackstage.io/">https://www.bksbackstage.io</a>, pursuant to the rules set out in these terms and conditions (“terms”). Our intent is to solve one of the biggest problems of the event industry, financing activities and companies by establishing a trusted ecosystem.
                  </li>
                  <li>
                  These terms – together with the onboarding form [add link], which needs to be agreed to before using our services – govern the relationship between Backstage and users of theservices provided by Backstage, who use the platform as merchants. They contain the entirety of the terms applicable in respect of our services. Terms departing from these terms shall not be applicable, unless expressly confirmed by us.
                  </li>
                  <li>
                  By creating an account on the website of Backstage <a href="https://www.bksbackstage.io/">(https://www.bksbackstage.io)</a> or the Mobile Application and using the services provided by Backstage you enter into a legally binding agreement with us. You will be asked to accept the following terms for the services. By selecting “accept”, you are indicating that you have read and understood the terms. The terms will then constitute as a legally binding agreement between you and Backstage.
                  </li>
                  <li>
                  These terms were originally constructed in English. The English version prevails any translated version conflicting with the English version of these terms
                  </li>
                  <li>
                  If you have any questions regarding these terms or any other service by Backstage, please do not hesitate to contact us at stage@bksbackstage.io.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Definitions and terminology</h4>
                <ol>
                  <p>The following definitions determine how these specific words are to be understood in the context of these terms and conditions and our services</p>
                  <li>
                  “Backstage”, “us”, “we”, “our”: Bksbackstage OÜ, Tartu mnt 67/1-13b, 10115 Tallinn, Estonia, incorporated and registered in Estonia with registry code 16263612, contact email address:stage@bksbackstage.io
                  </li>
                  <li>
                  “User”, “you” or “your”: a natural or legal person using the website, mobile application and services provided by Backstage.
                  </li>
                  <li>
                  “Account”: the virtual account created by and for you upon registration on our website or mobile application as further described under section [●] of these terms, as well as underlying virtual accounts, including master accounts and sub accounts, which are opened for you
                  </li>
                  <li>
                  “Party”: each of you and Backstage and “Parties” means you and Backstage collectively
                  </li>
                  <li>
                  “terms”: these Terms and Conditions
                  </li>
                  <li>
                  “Mobile Application (App)”: [●]
                  </li>
                  <li>
                  “Backstaker(s)”: a commission-based financial promoter. Backstakers are non-exclusive partners of Backstage and can operate either as an individual or as a company to promote products and services of Backstage
                  </li>
                  <li>
                  “Backstager(s)”: the community of users supporting the Events Ecosystems; by sponsoring, promoting, financing and taking part into different opportunities generated within the Events Ecosystem, Backstagers are the trusted pillar of the Community
                  </li>
                  <li>
                  “Blockchain”: a growing list of records, called blocks, that are linked together using cryptography. Each block contains a cryptographic hash of the previous block, a timestamp, and transaction data (generally represented as a Merkle tree). The timestamp proves that the transaction data existed when the block was published to get into its hash. As blocks eachcontain information about the block before it, they form a chain, with each additional block reinforcing the ones before it. Therefore, blockchains are resistant to modification of their data because once recorded, the data in any given block cannot be altered retro actively without altering all subsequent blocks.
                  </li>
                  <li>
                  “BKS Token(s)”: a utility cryptographic decentralized token issued by us on the Binance Smart Chain being BKS the token which can be used to finance the Event Industry as payment unit and system. BKS tokens can be changed into $USDC
                  </li>
                  <li>
                  “Binance Smart Chain (BSC)”: Binance Blockchain
                  </li>
                  <li>
                  “Cryptocurrency exchange”: transactions in which digital currency is exchanged for matching goods, works, services, other cryptocurrencies or cash
                  </li>
                  <li>
                  “Digital Currencies”: encrypted or digital tokens or cryptocurrencies with a certain value that are based on blockchain and cryptography technologies and are issued and managed in a decentralized form
                  </li>
                  <li>
                  “Digital assets”: digital currencies, their derivatives or other types of digital assets with a certain value
                  </li>
                  <li>
                  “Ecosystem”: an economic community supported by a foundation of interacting organisations and individuals. The economic Community produces goods and services of value to customers, who are themselves members of the Ecosystem. The member organisms also include suppliers, lead producers, competitors and other stakeholders.
                  </li>
                  <li>
                  “Event(s)”: the public gathering of individuals engaged in some common interest at adetermined time and place
                  </li>
                  <li>
                  “Login”: a unique identifier of the user, used to designate and identify the user on the website
                  </li>
                  <li>
                  “Metaverse”: a concept based on a persistent, online 3D universe combining various and different virtual spaces. It can be seen as a future evolution of the internet. This environment will allow users to work, meet, play and socialize together in these 3Dspaces.
                  </li>
                  <li>
                  “NFT”: a non-fungible token; a unique assignment of a specific number and further data, such as an art-work or a link thereto, to an owner (or their public key) in the blockchain.It is immutably in incorruptibly stored on the blockchain.
                  </li>
                  <li>
                  “Services”: the services provided by us via our website or mobile applicationas furthered defined under section 3.
                  </li>
                  <li>
                  “Smart contract”: a computer program or a transaction protocol, which is intended to automatically execute, control or document legally relevant events and actions according to the terms of a contract or an agreement, in order to reduce the need in trusted intermediaries, arbitrations and enforcement costs, fraud losses as well as the reducing malicious and accidental exceptions
                  </li>
                  <li>
                  “Wallet (Crypto)”: a computing tool, whose tasks are to securely keep the owner’ssecret key, to authenticate the owner and to let the owner securely sign transactions. A “hardware wallet” for cryptocurrencies is a single purpose computer which complies to those tasks in an even safer manner.
                    <ol>
                      <li>
                      The Wallet is designed solely for concluding transactions on our Website, but is not a banking or investment tool. You are therefore not allowed to store more money/Digital Assets/Tokens in the Wallet than are necessary for the conclusion of a contract on our Website. Backstage does not assume any liability for the loss of money occurring from a violation of this obligation by you
                      </li>
                      <li>
                      The Wallet is a centralised wallet. You have access to your wallet via a login by entering a password, but you do not own the wallet key.
                      </li>
                    </ol>
                  </li>
                  <li>
                  “Website”: the website accessible at https://www.bksbackstage.io/, including its subdomains, programming code, related technologies, know-how, databases, design and content there in.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Services provided by BKS Backstage</h4>
                <ol>
                  <li>
                  Our services consist, inter alia, of:
                    <ol>
                      <li>
                      .“Marketplace”:
                      </li>
                      <ol>
                        <li>
                        On the Marketplace you can sell and exchange NFTs via BKS. The Marketplacelists ticket sales, NFT drops, advertising opportunities and more
                        </li>
                        <li>
                        You can sell the NFT via the Marketplace to other users. Before being able to list an NFT on the Marketplace, you must first register on our website (s. section4).
                        </li>
                        <li>
                        You agree that, you shall only list an NFT which you own or which you are otherwise entitled to list and sell on the Marketplace.
                        </li>
                        <li>
                        When listing the NFT, you can choose a price for the NFT.
                        </li>
                        <li>
                        On the Marketplace you can sell NFTs for tickets, services and digital goods.
                          <ol>
                            <li>
                            NFTs for tickets and services offered by third-party providers, suppliers or vendors (e.g. for a stay in a hotel including dinner, spa, expeditions etc. or a concert ticket) shall be put together by you as the merchant into one NFT
                            </li>
                            <li>
                            The NFT provides the user a way to prove and verify to the service provider that he or she eligible to the services and benefits which the NFT package contains.
                            </li>
                            <li>
                            After buying the NFT, the user must redeem the NFT with the service provider in order to claim and receive the including benefits. You shall inform the user, who bought the NFT, via email in a clear, explicit and easily understandable manner of how to redeem and use the services and benefits.
                            </li>
                            <li>
                            Digital goods (e.g. artworks) sold as NFTs must be made available to the user who bought the NFT right away. It is not necessary to redeem the purchase as it is for tickets and services (see the sections above).
                            </li>
                          </ol>
                        </li>
                        <li>
                        From the moment an NFT is sold – i.e. the payment is concluded – we will distribute the funds to the user of the NFT, who thereby acquires the ownershipof the NFT. You will no longer be able to withdraw the offered NFT from the Marketplace
                        </li>
                        <li>
                        Backstage only offers a marketplace for transactions between users and merchants. We do not ourselves offer and sell NFTs or other products via our website. The a forementioned transactions therefore conclude a binding contract only between the respective user and merchant of the transaction. Backstage does not become a party of a contract concluded via the Marketplace. We, therefore, are not responsible for the conclusion, the performance or any breachof such contract. We will not be able to you or any third party for any claims or damages arising from such contract.
                        </li>
                      </ol>
                    </ol>
                    <li>
                      .“NFT Ticketing”:
                        <ol>
                          <li>
                          NFT Ticketing acts as a sales channel for artists and venues by using uniqueand beautiful NFTs
                          </li>
                          <li>
                          BKS Main NFTs &anp; Secret NFTs: #
                          </li>
                          <li>
                          BKS Main NFTs offer: #
                          </li>
                          <li>
                          BKS Special NFTs: #
                          </li>
                          <li>
                          Loyalty NFTs: #
                          </li>
                          <li>
                          Content Immortalisation: Content Immortalisation is a feature, which helps content creator (artists, designers, musicians, writers, composers, producers, DJsetc.) to render their content “immortal” through NFT minting, and also to trading purposes in the public area of the platform. Users can create NFTs and a marketplace for their own collections
                          </li>
                        </ol>
                      </li>
                  </li>
                </ol>
              </li>
              <li>
                <h4>Your account</h4>
                <ol>
                  <li>
                  In order to use our services, you must use a computer or mobile phone to access our website through a web browser or our mobile application. You also need a working internet connection. These are not provided by Backstage and are the sole responsibility of the user
                  </li>
                  <li>
                  In order to use or receive our services, you must register an account as a user on our websiteor mobile application and be admitted by us. You, however, have no claim of right to admission or use of our services.
                  </li>
                  <li>
                  You must complete the on-boarding form to create an account. You understand that it is your legal obligation to provide true information in the on-boarding form
                  </li>
                  <li>
                  In order to open an account, you must be at least of legal age under the laws of your respective country of residence or nationality and capable of entering into legally binding agreements under the applicable law. If you are younger and the conclusion of the contract requires the consent of a parent or legal guardian, you must first obtain the necessary and verifiable consent before using our Services. By using our Services you represent and want that you meet the aforementioned requirements. Backstage reserves the right to ask for proof of age or proof of the obtained consent from you. If you open an account without fulfilling the necessary requirements, we have the right to terminate and close the account asa legally binding contract has not been concluded. If you cannot enter into a legally binding agreement with us, you are prohibited from using our Services.
                  </li>
                  <li>
                  Any use of our services must be carried out by you exclusively through the account opened for you.
                  </li>
                  <li>
                  You are not allowed to open more than one account for the same person and your account cannot be transferred to third parties.
                  </li>
                  <li>
                  When opening an account, you shall specify the following information:
                    <ol>
                      <li>
                      first and last name
                      </li>
                      <li>
                      email address
                      </li>
                      <li>
                      country of location
                      </li>
                      <li>
                      wallet address
                      </li>
                      <li>
                      phone number
                      </li>
                      <li>
                      official name of the company
                      </li>
                      <li>
                      company registration number
                      </li>
                      <li>
                      VAT number
                      </li>
                      <li>
                      registry code
                      </li>
                      <li>
                      address
                      </li>
                      <li>
                      name and contact details of legal representative or contact person
                      </li>
                      <li>
                      business description
                      </li>
                      <li>
                      bank account details
                      </li>
                    </ol>
                  </li>
                  <li>
                  You warrant that the information furnished by you to us at any time, in particular when opening an account, is true and complete. You undertake that you notify us of all future changes to the information given by you. We my, from time to time, also request your information to be updated and/or supplemented, in which case you are obliged to provide us with the updated and/or supplemented information immediately. If you are not able or willing to update such information, we have the right to close you account and/or restrict you from accessing our services.
                  </li>
                  <li>
                  After entering the necessary information and clicking on “Register”, your account registration must be approved by us.
                  </li>
                  <li>
                  The acceptance of your application for opening an account is made by us via email. Bynotice of admission, the legally binding contractual agreement governed by these terms between you and us is formed.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Login and account security</h4>
                <ol>
                  <li>
                  After opening an account, you can use your login data to login on our website or mobile application to access our services. All logins are individualised and may only be used by you. You must keep you login details secret and protect them against unauthorized access by third parties. You solely are responsible for implementing all reasonable and appropriate measures to secure your account and other information necessary to use our services, such as passwords, private key or credentials to access our services. If you lose this information, you may lose access to our services, for which we will not be responsible.
                  </li>
                  <li>
                  Should we learn of an unauthorized use, we have the right to block access to our services by the unauthorized third party. In such a case, we also reserve the right to change your login information, of which we will inform you promptly.
                  </li>
                  <li>
                  By creating an account you also agree that
                    <ol>
                      <li>
                      you will notify us immediately if you become aware of any unauthorized use of you account and password or any other breach of security;
                      </li>
                      <li>
                      you will comply with ale the Backstage mechanisms and procedures relating to security, authentication, buying-trading, charging and withdrawals;
                      </li>
                      <li>
                      you will take appropriate steps to properly log out of your account at the end of eachvisit.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h4>Prices and payment</h4>
                <ol>
                  <li>
                  All prices and fees on our services are indicated in Euro.
                  </li>
                  <li>
                  Payments can be made using credit card, PayPal or third-party crypto service providers.
                  </li>
                  <li>
                  We do not process payments from users made to you on your behalf or as your agent.
                  </li>
                  <li>
                  Buyers can only pay the purchase price for NFTs using the BKS coin
                  </li>
                  <li>
                  In addition to the price for the NFT set by you, a buyer must pay a gas fee for maintenance of the network computers.
                  </li>
                  <li>
                  You agree that, every time you conclude a contract with a user on the Marketplace, you will authorise us to collect a commission fee of ● % of the transaction value.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Prohibited use of the services provided by Backstage</h4>
                <ol>
                  <li>
                  Our services may be used and accessed for lawful purposes only. You agree to abide by all applicable local, state, national and foreign laws and regulations in connection with your use of our services.
                  </li>
                  <li>
                  Prohibited behaviour
                    <ol>
                      <li>
                      You are prohibited from placing any content onto our website, which violates provisionsof law, regulatory requirements, public policy or third-party rights
                      </li>
                      <li>
                      You are prohibited from uploading any content, which infringes on third-party rights, such as copyrights or trademark rights.
                      </li>
                      <li>
                      You also under take that you shall refrain from any acts which would put the functionality of our services at risk or disrupt it, and that you shall not access any data, which is not authorised for you to access. In particular, you agree not to upload, post, email orotherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, damage, destroy or limit the functionality of any software or hardware or other equipment associated directly or indirectly withour services, website and mobile application.
                      </li>
                      <li>
                      You are not allowed to rent, sublicense, translate, merge, adapt or modify our mobileapplication or any related documentation.
                      </li>
                      <li>
                      You agree not to permit any third party to copy, adapt, reverse engineer or create derivative works from all or any part of the Backstage mobile application and website, orattempt to do any such thing.
                      </li>
                      <li>
                      You agree not to impersonate or attempt to impersonate the Backstage, Backstageservice, Backstage employee, another user or any other person or entity (including but not limited to the use of email addresses or aliases associated with any of the foregoing). You agree not to transmit or cause to be sent any promotional or marketing material without our prior written consent, including any "junk mail", "chain letters", "spam" orany other similar requests.
                      </li>
                    </ol>
                  </li>
                  <li>
                  You hereby undertake to compensate Backstage for all losses arising out of any non- compliance with your contractual duties, in so far as you are responsible, and to holdusharmless against all claims of third parties, including the costs of legal counsel and court costs,incurred by us due to your non-compliance.
                  </li>
                </ol>
              </li>
              <li>
                <h4>User-generated content</h4>
                <ol>
                  <li>
                  We do not adopt any third-party content as our own content at any circumstances. You hereby guarantee that content provided by you does not infringe on any third-party rights, such as copyrights, patents, trademarks and other intellectual property rights or trade secrets.
                  </li>
                  <li>
                  We reserve the right to block content, which we deem an offense under applicable law
                  </li>
                  <li>
                  You hereby undertake to indemnify and hold Backstage harmless against all claims made by third parties against us based on infringements of their rights or based on violations of lawarising due to content uploaded by you or any of you behaviour in so far as you are responsible for it. You, in this case, shall also assume assume the costs of our necessary legal defence in this respect, which includes all court fees and lawyers’ fees.
                  </li>
                </ol>
              </li>
              <li>
                <h4>
                Availability and maintenance of our services
                </h4>
                <ol>
                  <li>
                  Our services are provided on an “as is” and “as available” basis
                  </li>
                  <li>
                  We, however, do our best to provide our services and make them available without any interruption. We will try to resolve any occurring interruption within a time span of no longer than 48 hours
                  </li>
                  <li>
                  The administrator of our services is located in the EU. We make no representations that ourservices are available or appropriate outside the EU. Accessing our services may be illegal forcertain individuals or in certain countries. Accessing our services will be on your initiativeandyou solely are responsible for complying with local laws
                  </li>
                  <li>
                  We will not provide our services to the residents of the following countries:
                  </li>
                </ol>
              </li>
              <li>
                <h4>Limitation of liability</h4>
                <ol>
                  <li>
                  Backstage assumes no liability for any fault or disruptions within the services for whichwe are not responsible or other damages caused by third parties or circumstances out ourcontrol.
                  </li>
                  <li>
                  We shall only bear liability for losses of data in line with the sections above where such a loss would have been unavoidable even if appropriate backup measures had been taken by you
                  </li>
                  <li>
                  Our liability does not extend to any impairments of the use of our services which result from a user’s improper or incorrect utilization of our services.
                  </li>
                  <li>
                  Should you be redirected to services by third parties, such as databases or websites, because of the inclusion of links or hyperlinks, we shall not be liable for the contents, existence, accessibility or security of these services
                  </li>
                  <li>
                  The above-mentioned limitation of liability shall also apply mutatis mutandis infavourof our vicarious agents.
                  </li>
                  <li>
                  As a merchant, you understand that it is your responsibility to provide correct andeasily understandable instructions to the user about redeeming the benefits for the NFTs. You understand that this is not the responsibility of Backstage.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Term of contract and termination</h4>
                <ol>
                  <li>
                  These terms remain in full force and effect as long as and while you are using ourservices. The contractual agreement based on these terms is made for an unlimited term. It shall begin from the moment of opening an account for our services as further described under section [●] of these terms.
                  </li>
                  <li>
                  The contract may be terminated by either party upon three months’ notice to the end of any month. From there on you will no longer be able to and no longer be entitled to use any of our services
                  </li>
                  <li>
                  Each party shall also have the right to terminate the contract for good cause without the need to adhere to a notice period. A good cause for a termination by us shall, in particular and not exclusively, include the following:
                    <ol>
                      <li>
                      you violate any provision of these terms and fail to cure the violation evenafter adeadline has been set
                      </li>
                      <li>
                      you engage in prohibited behaviour as further specified under section [●] of these terms
                      </li>
                      <li>
                      you have not used and accessed our services for a duration of 18 consecutive months
                      </li>
                      <li>
                      you engage in another tortious act or attempt such act, for example fraud,
                      </li>
                      <li>
                      you default on your payment obligations as further set out under section [●] of these terms
                      </li>
                      <li>
                      it is required by a competent authority
                      </li>
                      <li>
                      it is required by applicable law
                      </li>
                      <li>
                      ongoing operational disruptions due to force majeure lying outside our control, such as natural catastrophes, fire, breakdown of networks etc.
                      </li>
                      <li>
                      Should we decide to terminate the agreement, we will notify you in advance via the email used for registration for our services to give you the opportunity to remedy or resolvethe matter with us.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h4>Links to third-party websites</h4>
                <ol>
                  <li>
                  Our services may include links to third party websites and services. This includes links contained in advertisements, including banner ads and sponsored links. Should you use any of these links to reach other websites and services not provided by us. Should you use any of these available links, you will leave our services. We do not control third-party websites and services and are therefore not responsible for the respective content. These websites and services are governed by the terms and conditions of the respective third party
                  </li>
                  <li>
                  We shall not be responsible or liable, either directly or indirectly, for any damage or loss caused or alleged to be caused in connection with your use or reliance on any such content, information, statements, representations, advertising, products, services or other materials available on or through third-party sites. We provide these links to you only as a convenience and the inclusion of any link does not imply recommendation, approval orendorsement by us of third-party sites.
                  </li>
                </ol>
              </li>
              <li>
                <h4>
                Taxes
                </h4>
                <ol>
                  <li>
                  It is your responsibility to determine whether any taxes apply to a transaction conducted by using our services and to with hold, collect, report and remit the correct amounts of taxes to the relevant tax authorities. The fees collected by us do not include any taxes.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Intellectual property</h4>
                <ol>
                  <li>
                  Unless otherwise stated, all copyright and other intellectual property rights present inour services are owned by or licensed to Backstage. This also applies to software, data, trademarks and other objects on our website or mobile application.
                  </li>
                  <li>
                  The above stated intellectual property is subject to and protected by legislation,international treaties and conventions.
                  </li>
                  <li>
                  Subject to your compliance with these terms, we give you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to access and use our services for your own personal or business use. This license is for the sole purpose of enabling you to use and enjoy the benefits of our services in the manner permitted by these terms.
                  </li>
                  <li>
                  Any other use of our services requires our prior written consent.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Choice of law, dispute resolution, jurisdiction and waiver</h4>
                <ol>
                  <li>
                  These terms and the concluded agreement have been drawn up in accordance with the laws of the Republic of Estonia and shall be governed by and construed in accordancewith the laws of the Republic of Estonia
                  </li>
                  <li>
                  The United Nations Convention on Contracts for the International Sale of Goods is not applicable to these terms and the concluded agreement.
                  </li>
                  <li>
                  Any disputes arising regarding our services, these terms, the concluded agreement orthe execution thereof shall initially be tried to be settled friendly through negotiations. If the involved parties fail to resolve the dispute through negotiation, the dispute shall be settled by Harju Maakohus (Harju County Court), pursuant to the procedure provided by the law of the Republic of Estonia.
                  </li>
                  <li>
                  Additionally, we shall be entitled to file suit at the user’s place of general jurisdiction.
                  </li>
                  <li>
                  No delay or omission in exercising any right or remedy under these terms and the underlying contract or law shall operate as a waiver. Any waiver shall take effect only if it is inwriting and signed by the authorised representatives.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Data protection and Privacy Policy</h4>
                <ol>
                  <li>
                  Our servers, which we use to provide our services, are secured in line with the current technological state of the art. You should, however, be aware that there is a risk, which cannot fully be excluded, that transmitted data and information through public networks maybe intercepted. We can therefore not warrant that transmitted data and information will not be intercepted.
                  </li>
                  <li>
                  Backstage may process, use or share information or personal data collected by us orprovided by you when using our services in accordance with the General Data Protection Regulation (EU) 2016/679 and its possible modifications. You hereby consent to the processing, usage and collection of this data and information.
                  </li>
                  <li>
                  The protection, usage and processing of this information and data is governed by our Privacy Policy in detail.
                  </li>
                </ol>
              </li>
              <li>
                <h4>Miscellaneous</h4>
                <ol>
                  <li>
                  These terms contain the entire agreement between the parties concerning its subject matter and supersedes any other agreements or statements pertaining to the same subject matter, except those agreements or statements expressly referenced in the contract as included.
                  </li>
                  <li>
                  There may be several reasons, which make it necessary for us to change or modify these terms, such as changes to the applicable law or improvements and developments of our services. We therefore reserve the right to change or modify these terms when we deem appropriate. The update version of the terms will be posted on our website.
                  </li>
                  <li>
                  In case of a change or modification of these terms we will notify you in advance via the email used for registration for our services. The changes will only come into effect prospectively after four weeks starting from the respective date of the change or modification of these terms, if you do not object to such changes. By continuing to use and access our services, you agree to the change or modification of these terms and that our contractual agreement will there after be governed by the new terms. If you do not accept possible updates to these terms or our Privacy Policy, we reserve the right to terminate the contractual agreement.
                  </li>
                  <li>
                  If any provision or part of a provision of these terms should be or become invalid, should be in conflict with requirements of law or is held unlawful, void or unenforceable, such provision will be separable form these terms and will not affect the validity and enforce ability of the remaining parts of the provision and these terms. The parties shall by mutual agreement replace the respective provision or part by a new valid, legal and enforceable provision, which most closely approximates the intent and economic effect of the invalid provision. This provision shall apply mut at is mut and is in the case of any contractual gaps.
                  </li>
                  <li>
                  No amendment of these terms and the contractual agreement based on these terms shall be valid unless it is in writing and signed by the authorized representatives of the parties, and performance prior to such execution will not constitute a waiver of this requirement.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="partners owl-carousel owl-loaded">
            <div className="owl-stage-outer">
              <div className="owl-stage"></div>
            </div>
            <div className="owl-nav disabled">
              <button type="button" role="presentation" className="owl-prev">
                <span aria-label="Previous">&gt;</span>
              </button>
              <button type="button" role="presentation" className="owl-next">
                <span aria-label="Next">&gt;</span>
              </button>
            </div>
            <div className="owl-dots disabled"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
