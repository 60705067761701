import { useState, useMemo } from "react";
import { useAppContext } from "../../../context/AppContext";
import TokenItem from "../../../components/token_item";
import Search from "../../../components/Search";
import TokenAddModal from "../../../components/custom_modals/token_add_modal";
import styles from "./index.module.css";

const Currencies = () => {
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("all");
  const { setModal, tokens } = useAppContext();
  const memoizedTokens = useMemo(
    () =>
      tokens.filter((item: any) =>
        search === ""
          ? item
          : item.symbol.toLowerCase().includes(search.trim().toLowerCase())
      ),
    [tokens, search]
  );

  return (
    <div className={styles.wallet_content}>
      <div className={styles.wallet__title_part}>
        <img src="/img/wallet/currencies_1.svg" alt="currencies" />
        <div>
          <p className={styles.wallet__content_title}>Currencies</p>
          <p className={styles.assets_number}>{tokens.length} assets</p>
        </div>
      </div>
      <div className={styles.currencies__top_part}>
        <div className="category__select_btns">
          <button
            className={
              tag === "all"
                ? "category__active--tag"
                : "category__inactive--tag"
            }
            onClick={() => setTag("all")}
          >
            All
          </button>
          {/* <button
            className={
              tag === "crypto"
                ? "category__active--tag"
                : "category__inactive--tag"
            }
            onClick={() => setTag("crypto")}
          >
            Crypto
          </button>
          <button
            className={
              tag === "cash"
                ? "category__active--tag"
                : "category__inactive--tag"
            }
            onClick={() => setTag("cash")}
          >
            Cash
          </button> */}
        </div>
        <div className={styles.currencies__search_part}>
          <div className={styles.currencies__search_block}>
            <Search onChange={setSearch} placeholder={"Search Currencies"} />
          </div>
          <button
            className={styles.add__asset_btn}
            onClick={() =>
              setModal({
                open: true,
                children: <TokenAddModal />,
              })
            }
            style={{ height: 44 }}
          >
            + Add Asset
          </button>
        </div>
      </div>
      {memoizedTokens.map((token: any, index: number) => (
        <TokenItem
          imgSrc={token.logo}
          coinName={token.symbol}
          balance={token.balance}
          price={`$${(token.price * token.balance).toFixed(2)} USD`}
          key={index}
          token_address={token.token_address}
          token_price={token.price}
          decimals={token.decimals}
        />
      ))}
    </div>
  );
};

export default Currencies;
