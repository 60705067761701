import { Link } from "react-router-dom";

const PagePrivacy = () => {
  return (
    <div className="container terms__container">
      <div className="row row--grid">
        <div className="col-12">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">
              Privacy policy
            </li>
          </ul>
        </div>

        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>Privacy policy</h1>
            <ol>
              <li>
                <h4>Introduction</h4>
                <p>
                Protection of personal data is very important for BKSbackstage and we take it very seriously. This Privacy Policy, the principles of which BKSbackstage follows, provides detailedinformation on the personal data collected by BKSbackstage about you, the purposes thisdata will be used for and who has access to the data. Please take the time to read this PrivacyPolicy and feel free to contact BKSbackstage directly with any questions or suggestionsconcerning the Policy.
                </p>
                <p>
                Like most of companies, BKSbackstage collects data when you visit our website(https://bksbackstage.io/). It is possible to visit our site without leaving any identifyingpersonal data for us. However, please be aware that we do use cookies on our websites thatprovide us statistical information. It helps us to analyse the behavioural patterns of thevisitors of our websites and to improve user experience. If you want to buy our servicefromour websites or to get more information about it, then sharing your personal data withusisnecessary
                </p>
                <p>
                We process personal data in compliance with applicable personal data protectionlaws,including the Regulation of the European Parliament and the Council (EU) 2016/679 (“GDPR”) as well as the Personal Data Protection Act of Estonia.
                </p>
                <p>
                BKSbackstage respects your right to control your privacy. It is important for us that youwouldbe able to exercise your rights. Below you will find details on how to do this
                </p>
                <p>
                Please be aware that we can update our Privacy Policy and in that case we will notifyyouabout the changes. You can always find the current version of the Privacy Policy fromthepage [●].
                </p>
              </li>
              <li>
                <h4>Terms and definitions</h4>
                <p>
                The Privacy Policy of BKSbackstage (legal name BKSbackstage OÜ) is based on GDPR. ThePrivacy Policy we apply must be clear and understandable to the visitors of our websitesaswell as to our contractual customers and to our partners. In order to ensure that all theparties understand their rights in the same way, we shall explain the most important termshere.
                </p>
                <p>
                “Data subject”: Identified or identifiable natural person whose data is processed;
                </p>
                <p>
                “Merchant”: legal person who will be using BKSbackstage’s platform as merchants for theselling of NFT tickets;
                </p>
                <p>
                “Personal Data”: Any information concerning an identified or identifiable natural person(“data subject”); identifiable natural person means a person who can be identified directlyorindirectly, in particular on the basis of identifying attribute, including name, personal identification code, location information, network identifier, or on the basis of one or morephysical, physiological, genetic, mental, economic, cultural or social characteristics of thenatural person;
                </p>
                <p>
                “Processing of Personal Data”: Automated or non-automated operation or set of operations,including collection, documentation, organizing, structuring, storing, customizingand modifying, querying, reading, using, transferring, distributing, or making otherwise available,joining or combining, restricting, deleting or destructing of personal data or set of them;
                </p>
                <p>
                “Profile analysis”: Any automated processing of personal data involving the use of personal data for the assessment of certain personal aspects of the natural person. In particular, foranalyzing or forecasting aspects related to the performance, financial situation, health, personal preferences, interests, reliability, behaviour, location or movement of thenatural person concerned;
                </p>
                <p>
                “Controller”: Natural or legal person, public entity, agency or other body which aloneor incooperation with others determines the objectives and means of the processing of personal data; if the objectives and means of such processing are determined in the lawof theUnionor the Member State, the specific criteria for the Responsible Processor or for assigningtheone may be established in the law of the Union or the Member State;
                </p>
                <p>
                “Processor”: Natural or legal person, public entity, agency or other body processing personal data on behalf of the controller;
                </p>
                <p>
                “Recipient”: Natural or legal person, public entity, agency or other body to whompersonal data is disclosed, whether or not it is a third party. The public entities which canobtainpersonal data as a result of specific inquiry in accordance with the law of the Unionor theMember State, are not deemed to be recipients; these public entities process suchdatainaccordance with applicable data protection standards in the basis of the processing purposes;
                </p>
                <p>
                “Third Party”: Natural or legal person, public entity, agency or body, excluding theDataSubject, Controller, Processor and persons who can process personal data under thedirectresponsibility of the Controller or Processor;
                </p>
                <p>
                “User”: a natural or legal person using BKSbackstage’s platform and the services providedonthe platform;
                </p>
                <p>
                “Consent” of the Data Subject: Voluntary, specific, conscious and unambiguous statement bywhich the Data Subject in the form of an application or by explicit consent, agrees withtheprocessing of his/her personal data.
                </p>
              </li>
              <li>
                <h4>Data controller</h4>
                <p>
                Company name: BKSbackstage OÜ
                </p>
                <p>
                Address: Tartu mnt 67/1-13b, 10115 Tallinn, Harju county, Estonia
                </p>
                <p>
                Website: https://bksbackstage.io/
                </p>
                <p>
                We can be contacted by e-mail at stage@bksbackstage.io
                </p>
              </li>
              <li>
                <h4>Purposes of processing and types of personal data</h4>
                <p>Queries</p>
                <p>
                If you want to use the services of BKSbackstage or get more information about them, thensharing your personal data with us is necessary. Queries can be submitted by fillingtheappropriate contact form or by sending us an email.
                The legal basis for such processing of the personal data is either Article 6(1)(b) in the GDPRorArticle 6(1)(f) in the GDPR (the legitimate interest of BKSbackstage to ensureswiftcommunication with potential clients).
                </p>
                <p>Setting up an account</p>
                <p>
                To be able to use the services offered by BKSbackstage, you must set up an account. 
                </p>
                <p>
                When setting up an account as a Merchant, BKSbackstage will ask you to provide the following personal data:
                </p>
                <p>
                The legal basis for the processing of personal data in this case if Article 6(1)(f) in theGDPR(the legitimate interest of BKSbackstage to manage its business relationships).
                </p>
                <p>
                When you are setting up an account as a User, BKSbackstage will ask you to providethefollowing personal data:
                </p>
                <p>The legal basis for the processing of personal data in this case is Article 6(1)(b) in the GDPR.</p>
                <h4>Providing services to the customers</h4>
                <p>
                When you are using our services, BKSbackstage will process the personal data associatedwith your account. In addition, to ensure the on-going provision of the services, BKSbackstage may need to process any relevant personal information provided by you (for example, bycontacting BKSbackstage to inquire about the service).
                </p>
                <p>
                The legal basis for the processing of personal data in this case is Article(6)(1)(b) in theGDPR.
                </p>
                <h4>AML procedures</h4>
                <p>
                BKSbackstage needs to comply with certain requirements, based on applicable anti moneylaundering and terrorist financing prevention related legal acts. To fulfil these obligations, BKSbackstage needs to process certain personal data about its customers. These personal data include your name as a natural person, identity code, date of birth, place of residenceorregistered office, as well as other types of personal data which can be used for identificationaccording to the applicable AML related legal act.
                </p>
                <p>
                The legal basis for the processing of personal data in this case is Article 6(1)(c) in the GDPR.
                </p>
              </li>
              <li>
                <h4>Recipients of personal data</h4>
                <p>
                BKSbackstage discloses data subjects’ personal data to its employees, contractors, management board members and other members of the organisation, on a need-to-knowbasis. BKSbackstage discloses personal data to the aforementioned persons only whenaccessto the personal data is necessary for the fulfilment of the person’s assignments and/orstatutory or employment related obligations.
                </p>
                <p>
                In certain cases, it may be necessary for BKSbackstage to disclose personal data topublicauthorities, when this is required by the law.
                </p>
              </li>
              <li>
                <h4>
                Storage of personal data
                </h4>
                <p>
                If you stop using the services by deleting your account on the website and/or application, your personal data will also be automatically deleted (unless we are obliged to storesuchdata to comply with statutory obligations).
                </p>
              </li>
              <li>
                <h4>Safety of personal data</h4>
                <p>
                BKSbackstage has implemented appropriate technical and organizational measures pursuantto Article 32 in the GDPR to ensure the safety of the personal data. Among other measures, BKSBackstage has ensured that all of its employees, contractors as well as other members of the organization, who may have access to personal data, are legally boundwithaconfidentiality obligation. In addition, BKSbackstage has implemented appropriateinformation security measures to ensure the resilience of the information systems whichareused for the processing of the personal data. BKSbackstage carries out regular auditsinrespect of the information systems.
                </p>
              </li>
              <li>
                <h4>Your rights as a data subject</h4>
                <p>
                By contacting BKSbackstage by email, you can exercise the following rights:
                </p>
                <p>
                In certain cases, you have the right to demand restriction for processing of your personal data and the right to object processing of your personal data.
                </p>
                <p>
                You can exercise your rights in accordance with the terms and conditions based on GDPRandother local regulations.
                </p>
                <p>
                If you believe that your privacy has been compromised, please contact us by the email address below. You also have the right to file a complaint to the data protection supervisoryauthority of the country of your residence. In Estonia, this is the Estonian Data ProtectionInspectorate.
                </p>
                <p>
                With any questions, please feel free to contact us at stage@bksbackstage.io
                </p>
              </li>
            </ol>
            
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="partners owl-carousel owl-loaded">
            <div className="owl-stage-outer">
              <div className="owl-stage"></div>
            </div>
            <div className="owl-nav disabled">
              <button type="button" role="presentation" className="owl-prev">
                <span aria-label="Previous">‹</span>
              </button>
              <button type="button" role="presentation" className="owl-next">
                <span aria-label="Next">›</span>
              </button>
            </div>
            <div className="owl-dots disabled"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePrivacy;
