import api from "./api";

const changeAvatar = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user/change_avatar", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const verifyEmailByAdmin = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user/verify_user", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getWallet = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/user/get_wallet", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const changePassword = (data) => {
  return new Promise((resolve, reject) => {
    api
      .put("/api/user/change_password", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const changeWallet = (data) => {
  return new Promise((resolve, reject) => {
    api
      .put("/api/user/change_wallet", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const changeSocial = (data) => {
  return new Promise((resolve, reject) => {
    api
      .put("/api/user/change_social", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const allUsers = (data) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user/all")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getNotVerifiedUsers = (data) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user/not_verified_users")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getAllUserAvatars = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user/all_useravatars")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getAllUserBackgrounds = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user/all_userbackgrounds")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export {
  changeAvatar,
  changePassword,
  changeSocial,
  changeWallet,
  allUsers,
  getAllUserAvatars,
  getAllUserBackgrounds,
  getNotVerifiedUsers,
  getWallet,
  verifyEmailByAdmin
};
