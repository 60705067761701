import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAppContext } from "../../context/AppContext";
import FiatSymbol from "../FiatSymbol";
import ItemSelectModal from "../custom_modals/item_select_modal";

const ItemAddons = ({
  values,
  addons,
  remove_Addon,
  add_Addons,
  addonIcons,
}) => {
  const { t } = useTranslation();
  const { setModal, addToast } = useAppContext();
  const [addonName, setAddonName] = useState("");
  const [addonDescription, setAddonDescription] = useState("");
  const [addonPrice, setAddonPrice] = useState("");
  const [addonIcon, setAddonIcon] = useState("");

  const set_Icon = (icon_src) => {
    setAddonIcon(icon_src);
  };

  const select_Icon = () => {
    setModal({
      open: true,
      children: (
        <ItemSelectModal
          title="Select Item Icon"
          handleEnd={set_Icon}
          icons={addonIcons}
        />
      ),
    });
  };

  const add = () => {
    if (
        addonName === "" ||
        addonDescription === "" ||
        addonIcon === "" ||
        addonPrice === ""
      ) {
        addToast("Please input all traits for the Addon", {
          appearance: "error",
          autoDismiss: true,
        });
        return;
      }
      add_Addons(addonName, addonPrice, addonDescription, addonIcon);
      setAddonName("");
      setAddonDescription("");
      setAddonPrice("");
      setAddonIcon("");
  }

  return (
    <div className="cr__evcard-subItem">
      <h4 className="cr__evcard--subtitle">
        <span></span>
        {t("add addons")}
      </h4>
      <div className="addon-container">
        {addons.map((addon, index) => (
          <div className="addon-block" key={index}>
            <p
              style={{
                color: "#fff",
                width: 30,
                marginBottom: 0,
              }}
            >
              {index + 1}
            </p>
            <p className="text__addon-name">{addon.name}</p>
            <p className="text__addon-description">{addon.description}</p>
            <p className="text__addon-price">
              {addon.price} <FiatSymbol currency={values.currency} />
            </p>
            <LazyLoadImage
              src={addon.icon}
              alt="addon-icon"
              className="img__addon-icon"
            />
            <div
              className="sign__btn-second"
              style={{
                width: 40,
                border: 0,
              }}
              onClick={() => remove_Addon(index)}
            >
              <LazyLoadImage
                src="/img/trash.png"
                alt="trush"
                className="img__trush"
              />
            </div>
          </div>
        ))}
        <div
          className="cr__evcard-rowParent"
          style={{ gap: 20, paddingLeft: 0 }}
        >
          <div className="sign__group" style={{ flex: 1, minWidth: 120 }}>
            <p className="cr__evcard-labelTitle">{t("add-on name")}</p>
            <input
              type="text"
              className="sign__input"
              value={addonName}
              onChange={(e) => setAddonName(e.target.value)}
            />
          </div>
          <div
            className="sign__group"
            style={{ position: "relative", width: 120 }}
          >
            <p className="cr__evcard-labelTitle">{t("price")}</p>
            <input
              type="number"
              className="sign__input"
              placeholder="0"
              value={addonPrice}
              onChange={(e) => setAddonPrice(e.target.value)}
            />
            <div className="cr__evcard-price-symbol">
              <FiatSymbol currency={values.currency} />
            </div>
          </div>
          <div
            className="sign__group"
            style={{ alignItems: "flex-start", width: 44 }}
          >
            <p className="cr__evcard-labelTitle">{t("icon")}</p>
            <div className="cr__evcard-uploadImg">
              <div
                className="input__upload input__upload-addon"
                onClick={select_Icon}
              >
                {addonIcon ? (
                  <>
                    <img
                      src={addonIcon}
                      alt="AddOnIcon"
                      style={{ marginRight: 0 }}
                    />
                  </>
                ) : (
                  <>
                    <img
                      src="/img/icons/select-icon.svg"
                      alt="select"
                      style={{ marginRight: 0 }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="cr__evcard-rowParent" style={{ paddingLeft: 0 }}>
          <div
            className="sign__group cr__evcard-100"
            style={{ paddingLeft: 0 }}
          >
            <p className="cr__evcard-labelTitle">{t("description")}</p>
            <textarea
              className="sign__input"
              style={{
                resize: "none",
                padding: "10px 20px",
                marginBottom: 10,
              }}
              value={addonDescription}
              onChange={(e) => setAddonDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div
        className="sign__group cr__evcard-100"
        style={{ padding: "0 30px 0 53px" }}
      >
        <div className="sign__btn-second" onClick={add}>
          {t("new add on")}
        </div>
      </div>
    </div>
  );
};

export default ItemAddons;
